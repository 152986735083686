import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import ControlContext from "../Store/control-context";
import ProductQTable from "../Components/ProductQTable";
import ProductVTable from "../Components/ProductVTable";
import TopCustomerTable from "../Components/TopCustomerTable";
import CustomerMailingTable from "../Components/CustomerMailingTable";
import CustomerMailTemplates from "../Components/CustomerMailTemplates";

const CustomerController = () => {
  const controlObject = useContext(ControlContext);
  const [allCustomers, setAllCustomers] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  const [topProductsByQty, setTopProductsByQty] = useState([]);
  const [topProductsByValue, setTopProductsByValue] = useState([]);
  const [viewToggler, setViewToggler] = useState("stats");

  useEffect(() => {
    getTopCustomers();
    getAllCustomers();
    getTopProducts("quantity");
    getTopProducts("value");
  }, [controlObject.webshop]);

  let getAllCustomers = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/customers/all/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setAllCustomers(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getTopCustomers = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/customers/top/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      console.log("customerdata received");
      setTopCustomers(data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getTopProducts = async (sorter) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/products/top/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          sorter: sorter,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      if (sorter === "value") {
        setTopProductsByValue(data);
      }
      if (sorter === "quantity") {
        setTopProductsByQty(data);
      }
    } else {
      alert("something went wrong checking your details");
    }
  };

  return (
    <div className="ccpage">
      <Helmet>
        <title>Ubicopia®-Customer Controller</title>
        <meta name="description" content="Control your customers" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <div className="cctitle">Insights & Customer relations</div>
          </div>
          <div className="col-3">
            <div className="row">
              <button
                className="btn btn-secondary btn-sm w-75"
                onClick={(e) => {
                  setViewToggler("stats");
                }}
              >
                Product Performance
              </button>
            </div>
            <div className="row mt-2">
              <button
                className="btn btn-secondary btn-sm w-75"
                onClick={(e) => {
                  setViewToggler("topcustomers");
                }}
              >
                Customer Performance
              </button>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <button
                className="btn btn-primary btn-sm w-75"
                onClick={(e) => {
                  setViewToggler("maillist");
                }}
              >
                Customer Mailing
              </button>
            </div>
          </div>
        </div>
      </div>

      {controlObject.webshop.ref === "" && (
        <>You need to select a webshop from the list</>
      )}
      {controlObject.webshop.ref !== "" && (
        <>
          {viewToggler === "stats" && (
            <>
              {topProductsByValue && (
                <ProductVTable productList={topProductsByValue} />
              )}
              {topProductsByQty && (
                <ProductQTable productList={topProductsByQty} />
              )}
            </>
          )}
          {viewToggler === "topcustomers" && topCustomers && (
            <TopCustomerTable cusList={topCustomers} />
          )}

          {viewToggler === "maillist" && allCustomers && (
            <>
              <CustomerMailingTable cusList={allCustomers} />
              <CustomerMailTemplates />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerController;
