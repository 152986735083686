import React, { useState, useEffect, useContext, Fragment } from "react";
import ShopContext from "../Store/shop-context";

import AddSubCat from "../Components/AddSubCat";
import EditSubCat from "../Components/EditSubCat";
import DeleteSubCat from "../Components/DeleteSubCat";
import ShowSubCat from "../Components/ShowSubCat";

const SubCategoryList = ({ cat }) => {
  const shopObject = useContext(ShopContext);

  const [clickedAdd, setClickedAdd] = useState(null);
  const [clickedEdit, setClickedEdit] = useState(null);
  const [clickedDelete, setClickedDelete] = useState(null);

  const clickEdit = (subCat) => {
    setClickedEdit(subCat.id);
  };

  const clickDelete = (subCat) => {
    setClickedDelete(subCat.id);
  };

  const clickAdd = () => {
    setClickedAdd(true);
  };

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-2 fw-bold">List of subcategories</div>
        <div className="col-3">
          <button
            className="btn btn-success btn-sm col"
            onClick={(e) => clickAdd()}
          >
            Add SubCategory
          </button>
        </div>
      </div>
      {clickedAdd && (
        <>
          <AddSubCat
            cat={cat}
            toggler={clickedAdd}
            setToggler={setClickedAdd}
          />
        </>
      )}
      {shopObject.subcategories
        .filter((sc) => sc.category === cat.id)
        .map((subCat, index) => {
          return (
            <Fragment key={index}>
              {clickedEdit === subCat.id && (
                <EditSubCat
                  subCat={subCat}
                  cat={cat}
                  toggler={clickedEdit}
                  setToggler={setClickedEdit}
                />
              )}
              {clickedDelete === subCat.id && (
                <DeleteSubCat
                  subCat={subCat}
                  cat={cat}
                  toggler={clickedDelete}
                  setToggler={setClickedDelete}
                />
              )}
              {clickedEdit != subCat.id && clickedDelete != subCat.id && (
                <ShowSubCat
                  subCat={subCat}
                  clickEdit={clickEdit}
                  clickDelete={clickDelete}
                />
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

export default SubCategoryList;
