import React from "react";
import GetTitle from "../Components/GetTitle";
import { CSVLink, CSVDownload } from "react-csv";

const TopCustomerTable = ({ cusList }) => {
  const csvData = cusList;
  return (
    <>
      <div className="ccsubtitle">
        Top 10 Customers{" "}
        <small>
          <CSVLink
            data={csvData}
            filename={"topcustomers.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Download CSV table
          </CSVLink>
        </small>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Value</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {cusList.map((cus, index) => (
            <tr key={index}>
              <th>{cus.totalvalue}</th>
              <td>
                <GetTitle code={cus.title} /> {" " + cus.firstname + " "}
                {cus.name}
              </td>
              <td>{cus.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TopCustomerTable;
