import React from "react";

const ShowCat = ({ cat, clickEdit, clickDelete }) => {
  return (
    <div className="scline">
      <div className="sccatname">{cat.name}</div>
      <div className="sccatbuttons">
        <button
          className="btn btn-success btn-sm"
          onClick={(e) => clickEdit(cat)}
        >
          edit
        </button>{" "}
        <button
          className="btn btn-success btn-sm"
          onClick={(e) => clickDelete(cat)}
        >
          delete
        </button>
      </div>
    </div>
  );
};

export default ShowCat;
