import React, { useState, useContext } from "react";
import { titles } from "../Data/data";
import AuthContext from "../Store/auth-context";

const EditUser = ({ user, settoggler }) => {
  const authObject = useContext(AuthContext);
  const [userData, setUserData] = useState(user);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    authObject.updateuserdetails(userData);
    settoggler(false);
  };

  return (
    <div className="userblock bg-light rounded-2 p-3">
      <div className="row">
        <div className="usersubtitle col-10">Your user details</div>
      </div>
      <form onSubmit={(e) => onSubmit(e)}>
        <label className="sulabel">
          Email - cannot be updated - part of credentials - please contact admin
          on info@ubicopia.com
        </label>
        <div className="form-group">{userData.email}</div>
        <label className="sulabel">
          Telefoon (beginnende met +32 voor België)
        </label>
        <div className="form-group">
          <input
            className="form-control"
            type="phone"
            placeholder="Tel"
            name="phone"
            value={userData.phone}
            onInput={(e) => onChange(e)}
            pattern="[\+]\d{2}[0-9]\d{7,9}"
          />
        </div>
        <div className="form-group mt-3">
          <select
            placeholder="Title"
            type="text"
            required
            id="title"
            name="title"
            value={userData.title}
            onInput={(e) => onChange(e)}
          >
            <option name="title" value="">
              - titel -
            </option>
            {titles &&
              titles.map((tit, index) => {
                return (
                  <option name="title" value={tit.code} key={index}>
                    {tit.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="form-group mt-3">
          <label className="sulabel">Voornaam</label>
          <input
            className="form-control"
            type="text"
            placeholder="First Name*"
            name="firstname"
            value={userData.firstname}
            default="Dominique"
            onChange={(e) => onChange(e)}
            pattern="[a-zA-Z][a-zA-Zéèçà-/s]{1,255}"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="sulabel">Achternaam</label>
          <input
            className="form-control"
            type="text"
            placeholder="Name*"
            name="name"
            value={userData.name}
            onChange={(e) => onChange(e)}
            pattern="[a-zA-Z][a-zA-Zéèçà-/s]{1,255}"
            maxLength="255"
            required
          />
        </div>
        <div className="form-group mt-3">
          <button className="btn btn-primary" type="submit">
            Save
          </button>
          <button
            className="btn btn-secondary ms-2"
            onClick={(e) => settoggler(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;
