import React, { useState } from "react";
import DayTimeEdit from "./DayTimeEdit";

const EditProductSetting = ({
  productSetting,
  toggler,
  setToggler,
  reload,
  setReload,
}) => {
  const [prodSet, setProdSet] = useState(productSetting);

  let updateProductSettings = async (prodset) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/settings/",
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(prodset),
      }
    );
    if (resp.status === 200) {
      setReload(!reload);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setProdSet({ ...prodSet, [name]: value });
  }

  const handleUpdate = (e) => {
    if (prodSet["leadtime"] === "") {
      prodSet["leadtime"] = null;
    }
    updateProductSettings(prodSet);
    setToggler(null);
  };

  const handleCancel = (e) => {
    setToggler(null);
  };

  return (
    <tr>
      <td>
        <input
          required
          className="form-control"
          placeholder="name"
          type="text"
          id="name"
          name="name"
          value={prodSet.name}
          onChange={handleChange}
        />
      </td>
      <td>
        <input
          className="form-control"
          placeholder="leadtime"
          type="integer"
          id="leadtime"
          name="leadtime"
          value={prodSet.leadtime}
          onChange={handleChange}
        ></input>
      </td>
      <td>
        {!prodSet.leadtime && (
          <>
            <DayTimeEdit
              data={prodSet.orderdeadline}
              name="orderdeadline"
              prodSet={prodSet}
              setProdSet={setProdSet}
            />
            {/* <input
              className="form-control"
              placeholder="Deadline to order"
              type="datetime-local"
              id="orderdeadline"
              name="orderdeadline"
              value={prodSet.orderdeadline}
              onChange={handleChange}
            /> */}
          </>
        )}
      </td>
      <td>
        {!prodSet.leadtime && (
          <>
            <DayTimeEdit
              data={prodSet.orderdelivery}
              name="orderdelivery"
              prodSet={prodSet}
              setProdSet={setProdSet}
            />
            {/* <input
              className="form-control"
              placeholder="Earliest pickup"
              type="datetime-local"
              id="orderdeadline"
              name="orderdelivery"
              value={prodSet.orderdelivery}
              onChange={handleChange}
            /> */}
          </>
        )}
      </td>
      <td>
        <button
          className="btn btn-success btn-sm"
          onClick={() => {
            handleUpdate();
          }}
        >
          Update
        </button>
        <button
          className="btn btn-secondary btn-sm ms-2"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default EditProductSetting;
