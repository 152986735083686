import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";

const DeleteSubCat = ({ subCat, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);

  const handleDelete = (e) => {
    e.preventDefault();
    shopObject.deletesubcategory(subCat);
    setToggler(false);
    shopObject.setreload(!shopObject.reload);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  return (
    <div className="row mt-2">
      <div className="col-1"></div>
      <div className="col-2">
        <div className="">{subCat.name}</div>
      </div>
      <div className="col-3">
        <button
          className="border border-0 bg-transparent col-1 ms-1"
          onClick={handleDelete}
        >
          <i className="bi bi-trash"></i>
        </button>
        <button
          className="border border-0 bg-transparent col-1 ms-1"
          onClick={handleCancel}
        >
          <i className="bi bi-x"></i>
        </button>
      </div>
    </div>
  );
};

export default DeleteSubCat;
