import React, { useState, useContext, Fragment } from "react";
import { useEffect } from "react";
import ShopContext from "../Store/shop-context";

const SubCatButtons = () => {
  const shopObject = useContext(ShopContext);
  const [subCatList, setSubCatList] = useState();

  useEffect(() => {
    setSubCatList(
      shopObject.subcategories.filter(
        (subcat) => subcat.category === shopObject.category.id
      )
    );
  }, [shopObject.subcategories, shopObject.category]);

  useEffect(() => {
    if (shopObject.subcategories) {
      setSubCatList(
        shopObject.subcategories.filter(
          (subcat) => subcat.category === shopObject.category.id
        )
      );
    }
  }, []);

  const clickedLeft = (pos) => {
    if (pos > 0) {
      let subCat = subCatList[pos];
      let leftSubCat = subCatList[pos - 1];
      subCat.position = subCat.position - 1;
      leftSubCat.position = leftSubCat.position + 1;
      shopObject.updatesubcategory(subCat);
      shopObject.updatesubcategory(leftSubCat);
    }
  };

  const clickedRight = (pos) => {
    if (pos + 1 < subCatList.length) {
      let cat = subCatList[pos];
      let rightCat = subCatList[pos + 1];
      cat.position = cat.position + 1;
      rightCat.position = rightCat.position - 1;
      shopObject.updatesubcategory(cat);
      shopObject.updatesubcategory(rightCat);
    }
  };

  const clickHandler = (subCat) => {
    shopObject.setsubcategory(subCat);
  };
  return (
    <>
      {subCatList && (
        <div className="subcatgroup">
          {subCatList.map((subCat, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && (
                  <i
                    className="bi bi-caret-left mt-3"
                    onClick={(e) => clickedLeft(subCat.position)}
                  ></i>
                )}
                {shopObject.subcategory === null && (
                  <button
                    className="catbutton"
                    onClick={(e) => clickHandler(subCat)}
                  >
                    {subCat.name}
                    {" - "} {subCat.position}
                  </button>
                )}

                {shopObject.subcategory !== null &&
                  subCat.id !== shopObject.subcategory.id && (
                    <button
                      className="catbutton"
                      onClick={(e) => clickHandler(subCat)}
                    >
                      {subCat.name}
                      {" - "} {subCat.position}
                    </button>
                  )}

                {shopObject.subcategory !== null &&
                  subCat.id === shopObject.subcategory.id && (
                    <button
                      className="catbutton"
                      onClick={(e) => clickHandler(subCat)}
                    >
                      <strong>{subCat.name}</strong>
                      {" - "} {subCat.position}
                    </button>
                  )}
                {index < subCatList.length - 1 && (
                  <i
                    className="bi bi-caret-right mt-3"
                    onClick={(e) => clickedRight(subCat.position)}
                  ></i>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SubCatButtons;
