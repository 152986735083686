import React, { useState, useContext, useEffect, Fragment } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";
import Select from "react-select";
import { blankProduct } from "../Data/data";
import { productvariants } from "../Data/data";

const AddProduct = ({ toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);
  const controlObject = useContext(ControlContext);
  var [prod, setProd] = useState(blankProduct);
  const [cats, setCats] = useState(shopObject.categories);
  const [subCats, setSubCats] = useState(
    shopObject.subcategories.filter(
      (sc) => sc.category === shopObject.category.id
    )
  );
  var [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    options = [];
    shopObject.getcategorieslist();
    shopObject.getsubcategorieslist();
    if (shopObject.category != null) {
      setSubCats(
        shopObject.subcategories.filter(
          (sc) => sc.category === parseInt(prod.category)
        )
      );
    }
    setProd(blankProduct);
    setProd({ ...prod, ["category"]: shopObject.category.id });

    // ------ make options list from productsettings for select-input ------
    if (shopObject.productsettings && shopObject.productsettings.length > 0) {
      shopObject.productsettings.map((psetting) => {
        let value = psetting.id.toString();
        let label = psetting.name;
        options.push({ ["value"]: value, ["label"]: label });
      });
      setOptions(options);
      console.log("options created:", options);
    }
  }, []);

  useEffect(() => {
    setSubCats(
      shopObject.subcategories.filter(
        (sc) => sc.category === parseInt(prod.category)
      )
    );
  }, [prod.category]);

  useEffect(() => {
    if (shopObject.category != null) {
      setProd({ ...prod, ["category"]: shopObject.category.id });
    }
    if (shopObject.subcategory != null) {
      setProd({ ...prod, ["subcategory"]: shopObject.subcategory.id });
    }
  }, [shopObject.category, shopObject.subcategory]);

  function handler(e) {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "promotion") {
      value = !prod.promotion;
    }
    setProd({ ...prod, [name]: value });
  }

  function settingshandler(data) {
    setSelectedOptions(data);
  }

  const imageHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.files[0];
    setProd({ ...prod, [name]: value });
  };

  const handleCreate = (e) => {
    e.preventDefault();
    const newProduct = new FormData();
    newProduct.append("name", prod.name);
    newProduct.append("active", prod.active);
    newProduct.append("varianttype", prod.varianttype);
    newProduct.append("startquantity", prod.startquantity);
    newProduct.append("incrementalquantity", prod.incrementalquantity);
    newProduct.append("sku", prod.sku);
    if (prod.picture) {
      newProduct.append("picture", prod.picture);
    }
    if (prod.picturelarge1) {
      newProduct.append("picturelarge1", prod.picturelarge1);
    }
    if (prod.picturelarge2) {
      newProduct.append("picturelarge1", prod.picturelarge2);
    }
    if (prod.picturelarge3) {
      newProduct.append("picturelarge1", prod.picturelarge3);
    }
    newProduct.append("description", prod.description);
    newProduct.append("fulldescription", prod.fulldescription);
    newProduct.append("price", prod.price);
    if (prod.varianttype === "cuttoweight") {
      newProduct.append("per", "/kg");
    } else {
      newProduct.append("per", prod.per);
    }
    newProduct.append("vat", prod.vat);
    newProduct.append("stock", prod.stock);
    newProduct.append("promotion", prod.promotion);
    if (prod.category === "") {
      newProduct.append("category", shopObject.categories[0].id);
      prod.category = shopObject.categories[0].id;
    } else {
      newProduct.append("category", parseInt(prod.category));
    }
    if (prod.subcategory === "") {
      newProduct.append("subcategory", "");
    } else {
      newProduct.append("subcategory", parseInt(prod.subcategory));
    }
    let length = shopObject.products.filter(
      (pr) => pr.category === parseFloat(prod.category)
    ).length;
    newProduct.append("position", length);
    newProduct.append("webshop", controlObject.webshop.id);
    if (prod.settings === null) {
      prod.settings = "";
    }
    // ------ composing productsettings from selectedoptions ------
    console.log("selectedoptions :", selectedOptions);
    prod.settings = [];
    if (selectedOptions.length > 0) {
      selectedOptions.map((seloption) => {
        newProduct.append("settings", seloption.value);
      });
    } else {
      // newProduct.append("settings", prod.settings);
    }
    shopObject.createproductwimage(newProduct);
    setProd(blankProduct);
    setToggler(null);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };
  return (
    <div>
      <div className="card-input">
        <div className="cardimagebox"></div>
        <div className="card-body">
          <input
            className="card-file-input"
            placeholder="product picture"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            id="picture"
            name="picture"
            onChange={imageHandler}
          />
          {prod.promotion && (
            <div className="mt-3 me-3">
              Promotion
              <input
                type="checkbox"
                id="checkpromo"
                name="promotion"
                value={prod.promotion}
                onChange={handler}
                checked
              />
            </div>
          )}
          {!prod.promotion && (
            <div className="mt-3 mb-3">
              Promotion
              <input
                className="ms-2"
                type="checkbox"
                id="checkpromo"
                name="promotion"
                value={prod.promotion}
                onChange={handler}
              />
            </div>
          )}
          <input
            className="card-title-input mb-3"
            placeholder="product name"
            type="text"
            id="name"
            name="name"
            value={prod.name}
            onChange={handler}
          />
          <textarea
            className="card-description-input mb-3"
            placeholder="product description"
            type="text"
            id="description"
            name="description"
            value={prod.description}
            onChange={handler}
          />
          <div className="row mt-3">
            <div className="col-4">SKU</div>
            <input
              className="card-sku-input col-7"
              placeholder="product SKU"
              type="text"
              id="sku"
              name="sku"
              value={prod.sku}
              onChange={handler}
            ></input>
          </div>
          <div className="row mt-1">
            <div className="col-4">
              <small>Category</small>
            </div>
            <select
              className="card-cat-input col-7"
              placeholder="category"
              type="text"
              required
              id="category"
              name="category"
              value={prod.category}
              onChange={handler}
            >
              {shopObject.categories &&
                shopObject.categories.map((cat, index) => {
                  return (
                    <option name="category" value={cat.id} key={index}>
                      {cat.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="row mt-1">
            <div className="col-4">
              <small>Subcategory</small>
            </div>
            <select
              className="card-cat-input col-7"
              placeholder="subcategory"
              type="text"
              required
              id="subcategory"
              name="subcategory"
              value={prod.subcategory}
              onChange={handler}
            >
              <option name="subcategory" value="">
                ------------
              </option>
              {subCats &&
                subCats.map((subCat, index) => {
                  return (
                    <option name="subcategory" value={subCat.id} key={index}>
                      {subCat.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="card-settings d-flex flex-row align-items-center mt-1">
            <div className="me-2">
              <small>Settings</small>
            </div>
            <Select
              className="card-settings-input"
              options={options}
              noOptionsMessage="geen"
              placeholder="settings"
              value={selectedOptions}
              onChange={settingshandler}
              isMulti
            />

            {/* <select
              className="card-cat-input col-7"
              placeholder="settings"
              type="text"
              id="settings"
              name="settings"
              value={prod.settings}
              onChange={settingshandler}
              multiple
            >
              <option name="settings" value="">
                ------------
              </option>
              {shopObject.productsettings &&
                shopObject.productsettings.map((prodset, index) => {
                  return (
                    <option name="settings" value={prodset.id} key={index}>
                      {prodset.name}
                    </option>
                  );
                })}
            </select> */}
          </div>
          <div className="row mt-1">
            <div className="col-4">
              <small>Variant</small>
            </div>
            <select
              className="card-cat-input col-7"
              type="text"
              required
              id="varianttype"
              name="varianttype"
              value={prod.varianttype}
              onChange={handler}
            >
              {productvariants &&
                productvariants.map((productvariant, index) => {
                  return (
                    <option
                      name="varianttype"
                      value={productvariant.code}
                      key={index}
                    >
                      {productvariant.name}
                    </option>
                  );
                })}
            </select>
          </div>
          {prod.varianttype === "standard" && (
            <>
              <div className="text-center fw-bold border-top my-3 border-bottom ">
                standaard prijsingave
              </div>
              <div className="row">
                <div className="col-6 ms-3">
                  Price{" "}
                  <strong>{(prod.price * (1 + prod.vat)).toFixed(2)}</strong>{" "}
                </div>
                <input
                  className="card-per-input col-4"
                  placeholder="per"
                  type="text"
                  id="per"
                  name="per"
                  value={prod.per}
                  onChange={handler}
                ></input>
              </div>
              <div className="row ms-3 mt-3">
                <div className="col-4">Price</div>
                <div className="col-2"> x</div> <div className="col-4">VAT</div>
              </div>
              <div className="row ms-3">
                <input
                  className="col-4"
                  placeholder="product price"
                  type="text"
                  id="price"
                  name="price"
                  value={prod.price}
                  onChange={handler}
                />
                <div className="col-2">x</div>
                <input
                  className="col-4"
                  placeholder="product vat"
                  type="text"
                  id="vat"
                  name="vat"
                  value={prod.vat}
                  onChange={handler}
                />
              </div>
            </>
          )}
          {prod.varianttype === "cuttoweight" && (
            <>
              <div className="text-center fw-bold border-top my-3 border-bottom ">
                Prijsingave voor versneden product
              </div>
              <div className="row">
                <div className="col-6 ms-3">
                  Price{" "}
                  <strong>{(prod.price * (1 + prod.vat)).toFixed(2)}</strong>{" "}
                </div>
                <input
                  className="card-per-input col-4"
                  placeholder="/kg"
                  type="text"
                  id="per"
                  name="per"
                  value="/kg"
                  onChange={handler}
                ></input>
              </div>
              <div className="row ms-3 mt-3">
                <div className="col-4">Price/kg</div>
                <div className="col-2"> x</div> <div className="col-4">VAT</div>
              </div>
              <div className="row ms-3">
                <input
                  className="col-4"
                  placeholder="product price"
                  type="text"
                  id="price"
                  name="price"
                  value={prod.price}
                  onChange={handler}
                />
                <div className="col-2">x</div>
                <input
                  className="col-4"
                  placeholder="product vat"
                  type="text"
                  id="vat"
                  name="vat"
                  value={prod.vat}
                  onChange={handler}
                />
              </div>
              <div className="row ms-3 mt-3">
                <div className="col-4">
                  <div>Start- hoeveelheid</div>
                  <div>(gram)</div>
                </div>
                <div className="col-2">+/-</div>{" "}
                <div className="col-4">increment</div>
              </div>
              <div className="row ms-3">
                <input
                  className="col-4"
                  placeholder="starthoeveelheid"
                  type="text"
                  id="startquantity"
                  name="startquantity"
                  value={prod.startquantity}
                  onChange={handler}
                />
                <div className="col-2">+/-</div>
                <input
                  className="col-4"
                  placeholder="hoeveelheid stap"
                  type="text"
                  id="incrementalquantity"
                  name="incrementalquantity"
                  value={prod.incrementalquantity}
                  onChange={handler}
                />
              </div>
            </>
          )}

          <div className="scbuttongroup mt-3">
            <button className="btn btn-secondary btn-sm" onClick={handleCancel}>
              cancel
            </button>
            <button className="btn btn-success btn-sm" onClick={handleCreate}>
              save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
