import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";

function LoginPage() {
  let { loginuser } = useContext(AuthContext);

  const [formData, setFormData] = useState({ email: "", password: "" });
  const { email, password } = formData;
  const [passwordType, setPasswordType] = useState("password");

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    loginuser(email, password);
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="loginpage container mt-5">
      <Helmet>
        <title>Ubicopia® - User Login</title>
        <meta name="description" content="Login for registered users only." />
        <meta name="robots" content="index,nofollow" />
      </Helmet>
      <h1 className="lititle">Log in</h1>
      <p>Log in op je account</p>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <label className="lilabel">Email</label>
          <input
            className="form-control"
            type="email"
            placeholder="email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="lilabel">Paswoord</label>
          <div className="form-control p-0 d-flex justify-content-between">
            <input
              className="form-control"
              type={passwordType}
              placeholder="password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              minLength="8"
              required
            />
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={() => pwToggler()}
            >
              {passwordType === "password" ? (
                <i className="bi bi-eye fs-5"></i>
              ) : (
                <i className="bi bi-eye-slash fs-5"></i>
              )}
            </button>
          </div>
        </div>
        <div className="form-group mt-3">
          <button className="btn btn-primary" type="submit">
            Login
          </button>
        </div>
      </form>
      <p className="mt-3">
        Paswoord vergeten ? <Link to="/reset-password/">Wijzig paswoord</Link>
      </p>
    </div>
  );
}

export default LoginPage;
