import React, { Fragment, useState } from "react";

function fillZero(num, digital) {
  num = `${num}`;
  while (digital - num.length > 0) {
    num = `0${num}`;
  }
  return num;
}

function calculateMinute(time) {
  return fillZero(Math.floor(time / 60), 2);
}

function calculateSecond(time) {
  return fillZero(time % 60, 2);
}

function CountDown({ second }) {
  return (
    <Fragment>
      <span>{calculateMinute(second)}</span>
      <span>:</span>
      <span>{calculateSecond(second)}</span>
    </Fragment>
  );
}

export default CountDown;
