import React from "react";

const Disclaimer = () => {
  return (
    <div>
      <p>
        <div>De disclaimer is enkel beschikbaar in de engelse taal.</div>
        <div>Disclaimer</div>
        <p>
          This disclaimer ("Disclaimer") sets forth the general guidelines,
          disclosures, and terms of your use of the obtanium-consulting.com
          website ("Website" or "Service") and any of its related products and
          services (collectively, "Services"). This Disclaimer is a legally
          binding agreement between you ("User", "you" or "your") and this
          Website operator ("Operator", "we", "us" or "our"). By accessing and
          using the Website and Services, you acknowledge that you have read,
          understood, and agree to be bound by the terms of this Disclaimer. If
          you are entering into this Disclaimer on behalf of a business or other
          legal entity, you represent that you have the authority to bind such
          entity to this Disclaimer, in which case the terms "User", "you" or
          "your" shall refer to such entity. If you do not have such authority,
          or if you do not agree with the terms of this Disclaimer, you must not
          accept this Disclaimer and may not access and use the Website and
          Services. You acknowledge that this Disclaimer is a contract between
          you and the Operator, even though it is electronic and is not
          physically signed by you, and it governs your use of the Website and
          Services.
        </p>
        <div>Representation</div>
        <p>
          Any views or opinions represented on the Website are personal and
          belong solely to the Operator and do not represent those of people,
          institutions or organizations that the owner may or may not be
          associated with in professional or personal capacity unless explicitly
          stated. Any views or opinions are not intended to malign any religion,
          ethnic group, club, organization, company, or individual.
        </p>
        <div>Content and postings</div>
        <p>
          You may not modify, print or copy any part of the Website and
          Services. Inclusion of any part of the Website and Services in another
          work, whether in printed or electronic or another form or inclusion of
          any part of the Website and Services on another resource by embedding,
          framing or otherwise without the express permission of the Operator is
          prohibited. Compensation and sponsorship
        </p>
        <p>
          Some of the links on the Website may be "affiliate links". This means
          if you click on the link and purchase an item, the Operator will
          receive an affiliate commission.Reviews and testimonials
        </p>
        <p>
          Testimonials are received in various forms through a variety of
          submission methods. The testimonials are not necessarily
          representative of all of those who will use the Services, and the
          Operator is not responsible for the opinions or comments posted on the
          Website, and does not necessarily share them. All opinions expressed
          are strictly the views of the poster or reviewer.
        </p>
        <p>
          The testimonials displayed are given verbatim except for grammatical
          or typing error corrections. Some testimonials may have been edited
          for clarity, or shortened in cases where the original testimonial
          included extraneous information of no relevance to the general public.
          Testimonials may be reviewed for authenticity before they are posted
          for public viewing.Indemnification and warranties
        </p>
        <p>
          While we have made every attempt to ensure that the information
          contained on the Website is correct, the Operator is not responsible
          for any errors or omissions, or for the results obtained from the use
          of this information. All information on the Website is provided "as
          is", with no guarantee of completeness, accuracy, timeliness or of the
          results obtained from the use of this information, and without
          warranty of any kind, express or implied. In no event will the
          Operator be liable to you or anyone else for any decision made or
          action taken in reliance on the information on the Website, or for any
          consequential, special or similar damages, even if advised of the
          possibility of such damages. Information on the Website is for general
          information purposes only and is not intended to provide any type of
          professional advice. Please seek professional assistance should you
          require it. Furthermore, information contained on the Website and any
          pages linked to and from it are subject to change at any time and
          without warning.
        </p>
        <div>Changes and amendments</div>
        <p>
          We reserve the right to modify this Disclaimer or its terms relating
          to the Website and Services at any time, effective upon posting of an
          updated version of this Disclaimer on the Website. When we do, we will
          revise the updated date at the bottom of this page. Continued use of
          the Website and Services after any such changes shall constitute your
          consent to such changes. Policy was created with
          https://www.WebsitePolicies.com
        </p>
        <div>Acceptance of this disclaimer</div>
        <p>
          You acknowledge that you have read this Disclaimer and agree to all
          its terms and conditions. By accessing and using the Website and
          Services you agree to be bound by this Disclaimer. If you do not agree
          to abide by the terms of this Disclaimer, you are not authorized to
          access or use the Website and Services.
        </p>
        <div>Contacting us</div>
        <p>
          If you would like to contact us to understand more about this
          Disclaimer or wish to contact us concerning any matter relating to it,
          you may send an email to info@ubicopia.com
        </p>
        This document was last updated on August 31, 2020
      </p>
    </div>
  );
};

export default Disclaimer;
