import React, { useState, useEffect } from "react";
import VWBox from "./VWBox";
import { blankVW } from "../Data/data";

const ShowVoedingsWaarden = ({ product, webshopid, update, setUpdate }) => {
  const [vw, setVw] = useState(blankVW);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    getVW(product);
  }, [product]);

  let getVW = async (product) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/vw/get/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          product: product,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setVw(data);
      console.log("data :", data);
      console.log("voedingswaarden loaded");
    } else {
      alert("something went wrong");
    }
  };

  let updateVw = async (product, vw) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/product/vw/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        product: product,
        voedingswaarden: vw,
      }),
    });
    if (resp.status === 200) {
      console.log("voedginswaarden updated");
      setUpdate(!update);
      setChanged(false);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let deleteVw = async (product, vw) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/product/vw/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        product: product,
        voedingswaarden: vw,
      }),
    });
    if (resp.status === 200) {
      console.log("voedingswaarden deleted");
      setUpdate(!update);
    } else {
      alert("something went wrong checking your details");
    }
  };

  function handler(e) {
    setChanged(true);
    const name = e.target.name;
    const value = e.target.value;
    setVw({ ...vw, [name]: value });
  }

  function upVW(e) {
    e.preventDefault();
    console.log("Updating:", vw);
    updateVw(product, vw);
  }

  function delVW(e) {
    e.preventDefault();
    deleteVw(product, vw);
  }

  return (
    <form onSubmit={(e) => upVW(e)}>
      <div className="mt-3">
        <div className="fw-bold my-4">Voedingswaarden per 100 g/100 ml:</div>
        <div>
          <div className="row w-75 mx-auto">
            <div className="col-5">
              <label className="form-label">Energie (kJ/kcal)</label>
            </div>
            <div className="col-5">
              <input
                className="form-control"
                type="text"
                name="energie"
                value={vw.energie}
                onChange={handler}
              />
            </div>
          </div>
          <VWBox
            vw="Vetten (g)"
            name="vetten"
            value={vw.vetten}
            handler={handler}
          />
          <div className="ms-2">
            <VWBox
              vw="waarvan verzadigde vetzuren (g)"
              name="vetten_verzadigd"
              value={vw.vetten_verzadigd}
              handler={handler}
            />
            <div className="ms-2">
              <VWBox
                vw="waarvan enkelvoudig onverzadigde vetzuren (g)"
                name="vetten_verz_enkelv"
                value={vw.vetten_verz_enkelv}
                handler={handler}
              />
              <VWBox
                vw=" waarvan meervoudig onverzadigde vetzuren (g)"
                name="vetten_verz_meerv"
                value={vw.vetten_verz_meerv}
                handler={handler}
              />
            </div>
          </div>
          <VWBox
            vw="Koolhydraten (g)"
            name="koolhydraten"
            value={vw.koolhydraten}
            handler={handler}
          />
          <div className="ms-2">
            <VWBox
              vw="waarvan suikers (g)"
              name="kool_suikers"
              value={vw.kool_suikers}
              handler={handler}
            />
            <VWBox
              vw="waarvan polyolen (g)"
              name="kool_polyolen"
              value={vw.kool_polyolen}
              handler={handler}
            />
            <VWBox
              vw="waarvan zetmeel (g)"
              name="kool_zetmeel"
              value={vw.kool_zetmeel}
              handler={handler}
            />
          </div>
          <VWBox
            vw="Vezels (g)"
            name="vezels"
            value={vw.vezels}
            handler={handler}
          />
          <VWBox
            vw="Eiwitten (g)"
            name="eiwitten"
            value={vw.eiwitten}
            handler={handler}
          />
          <VWBox vw="zout (g)" name="zout" value={vw.zout} handler={handler} />
          <VWBox
            vw="Vitaminen en mineralen"
            name="vitamins"
            value={vw.vitamins}
            handler={handler}
          />
          <div className="row w-75 mx-auto">
            <div className="col-5">
              <label className="form-label">Opmerkingen :</label>
            </div>
            <div className="col-5">
              <input
                className="form-control"
                type="text"
                name="remarks"
                value={vw.remarks}
                onChange={handler}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col"></div>
        <div className="col d-flex justify-content-end">
          {changed === true && (
            <>
              <button className="btn btn-success btn-sm" type="submit">
                update
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-danger btn-sm ms-2"
            onClick={(e) => delVW(e)}
          >
            delete
          </button>
        </div>
      </div>
    </form>
  );
};

export default ShowVoedingsWaarden;
