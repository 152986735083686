import React, { useState } from "react";

const EditNewsletter = ({ setToggler, newsLetter }) => {
  const [newsData, setNewsData] = useState(newsLetter);

  let editNews = async (news) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/newsletter/",
      {
        method: "PATCH",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: news,
      }
    );
    if (resp.status === 200) {
      console.log("edit succeeded");
      setToggler(null);
    } else {
      alert("something went wrong editing your newsletter");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setNewsData({ ...newsData, [fieldname]: value });
  };

  const imageHandler = (e) => {
    e.preventDefault();
    const fieldname = e.target.name;
    const value = e.target.files[0];
    setNewsData({ ...newsData, [fieldname]: value });
  };

  const newsSubmit = (e) => {
    e.preventDefault();
    const news = new FormData();
    news.append("id", newsData.id);
    news.append("name", newsData.name);
    news.append("organisation", newsData.organisation);
    news.append("header", newsData.header);
    news.append("body", newsData.body);
    if (newsData.picture1 !== newsLetter.picture1) {
      news.append("picture1", newsData.picture1);
    }
    if (newsData.picture2 !== newsLetter.picture2) {
      news.append("picture2", newsData.picture2);
    }
    news.append("url", newsData.url);
    editNews(news);
  };

  return (
    <div className="w-50">
      <form onSubmit={(e) => newsSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">Name of Newsletter (for filing only)</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={newsData.name}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Title</label>
          <input
            className="form-control"
            type="text"
            name="header"
            value={newsData.header}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,200}$"
            required
          />
        </div>
        <div className="form-group mt-3 w-50">
          <label className="w-100">Title picture</label>
          {newsData.picture1 !== newsLetter.picture1 && (
            <img
              className="img-thumbnail"
              src={URL.createObjectURL(newsData.picture1)}
            ></img>
          )}
          {newsData.picture1 === newsLetter.picture1 && (
            <img className="img-thumbnail" src={newsData.picture1}></img>
          )}
          {!newsData.picture1 && <>None</>}

          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Picture 1"
            name="picture1"
            id="picture1"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Body/Message</label>
          <textarea
            className="form-control"
            type="text"
            name="body"
            value={newsData.body}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,1000}$"
            required
          />
        </div>
        <div className="form-group mt-3 w-50">
          <label className="w-100">Subject picture</label>
          {newsData.picture2 !== newsLetter.picture2 && (
            <img
              className="img-thumbnail"
              src={URL.createObjectURL(newsData.picture2)}
            ></img>
          )}
          {newsData.picture2 === newsLetter.picture2 && (
            <img className="img-thumbnail" src={newsData.picture2}></img>
          )}
          {!newsData.picture2 && <>None</>}
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Picture 2"
            name="picture2"
            id="picture2"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Link</label>
          <input
            className="form-control"
            type="url"
            placeholder="Link"
            name="url"
            value={newsData.url}
            onChange={handler}
          />
        </div>
        <button
          className="btn btn-secondary mt-3"
          type="button"
          onClick={() => setToggler(null)}
        >
          Cancel
        </button>
        <button className="btn btn-success mt-3 ms-5" type="submit">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditNewsletter;
