import React from "react";
import NormDate from "./NormDate";

const Bookingline = ({ booking }) => {
  return (
    <div>
      {booking && (
        <div className="ocitembookingline">
          <strong>
            <NormDate olddate={booking.date} /> {booking.slotfrom.slice(0, 5)}{" "}
          </strong>
        </div>
      )}
    </div>
  );
};

export default Bookingline;
