import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const Unsubscribe = () => {
  const { webid } = useParams();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    webshopid: webid,
  });

  const [unToggler, setUnToggler] = useState(false);

  const unsubscribe = async (email, password, webshopid) => {
    var url = process.env.REACT_APP_API_URL + "capi/users/unsubscribe/";
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        webshopid: webshopid,
      }),
    });
    if (resp.status === 200) {
      setUnToggler(true);
    } else {
      console.log("foutje");
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    unsubscribe(formData.email, formData.password, formData.webshopid);
  };
  return (
    <div className="container mt-5 mb-5 signuppage">
      <Helmet>
        <title>Ubicopia® - Unsubscribe</title>
        <meta name="description" content="Unsubscribe from newsletter" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <h1 className="sutitle">Uitschrijven</h1>
      <p>U wenst de nieuwsbrief niet meer te ontvangen.</p>
      {unToggler && (
        <>
          <p>Het uitschrijven is gelukt. U ontvangt geen nieuwsbrieven meer.</p>
        </>
      )}
      {!unToggler && (
        <>
          <form onSubmit={(e) => onSubmit(e)}>
            <p>
              Gelieve uw email en paswoord op te geven om u te identificeren.
            </p>
            <label className="sulabel">Email</label>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email*"
                name="email"
                value={formData.email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group mt-3">
              <label className="sulabel">Paswoord</label>
              <input
                className="form-control"
                type="password"
                placeholder="Paswoord*"
                name="password"
                value={formData.password}
                default="Obtanium_007"
                onChange={(e) => onChange(e)}
                pattern="(?=^.{8,255}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                required
              />
            </div>
            <div className="form-group mt-3">
              <button className="btn btn-primary" type="submit">
                Uitschrijven
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Unsubscribe;
