import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";
import { isocountries } from "../Data/data";

const EditLoc = ({ location, setToggler }) => {
  const shopObject = useContext(ShopContext);
  const [loc, setLoc] = useState(location);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setLoc({ ...loc, [name]: value });
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    shopObject.updatelocs(loc);
  };

  const handleDelete = (e) => {
    shopObject.deletelocs(location);
    setToggler(false);
  };

  const handleCancel = (e) => {
    setToggler(false);
  };

  return (
    <>
      {loc && (
        <form onSubmit={handleUpdate}>
          <div className="poslocline input-group row">
            <input
              className="form-control poslocname col-2"
              placeholder="name"
              type="text"
              required
              id="name"
              name="name"
              default="test"
              value={loc.name}
              onChange={handleChange}
            />
            <input
              className="form-control col-2"
              placeholder="street"
              type="text"
              required
              id="name"
              name="street"
              value={loc.street}
              onChange={handleChange}
            />
            <div className="col-2 d-flex flex-row g-0">
              <input
                className="form-control"
                placeholder="number"
                type="text"
                size="2"
                required
                id="hnr"
                name="hnr"
                value={loc.hnr}
                onChange={handleChange}
              />
              <input
                className="form-control"
                placeholder="ext"
                type="text"
                size="2"
                id="hnrext"
                name="hnrext"
                value={loc.hnrext}
                onChange={handleChange}
              />
              <input
                className="form-control"
                placeholder="zip"
                type="text"
                required
                id="zip"
                name="zip"
                value={loc.zip}
                onChange={handleChange}
              />
            </div>
            <input
              className="form-control col-1"
              placeholder="city"
              type="text"
              required
              id="city"
              name="city"
              value={loc.city}
              onChange={handleChange}
            ></input>
            <select
              className="form-control col-1"
              placeholder="country"
              type="text"
              required
              id="country"
              name="country"
              value={loc.country}
              onChange={handleChange}
            >
              <option name="country" value="">
                --select country--
              </option>
              {isocountries &&
                isocountries.map((ic, index) => {
                  return (
                    <option name="country" value={ic.code} key={index}>
                      {ic.name}
                    </option>
                  );
                })}
            </select>
            <div className="col-3">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => handleCancel()}
              >
                cancel
              </button>

              <button type="submit" className="btn btn-primary">
                Update
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => handleDelete()}
              >
                Delete
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default EditLoc;
