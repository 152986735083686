import { titles } from "../Data/data";

const getTitle = (code) => {
  if (titles.filter((tit) => tit.code === code)) {
    let titleName = titles.filter((tit) => tit.code === code)[0].name;
    return titleName;
  }
};

export default getTitle;
