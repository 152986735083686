import React, { Fragment } from "react";

const ItemLine = ({ item, optionList }) => {
  return (
    <>
      <tr className="positemline">
        <td>{item.name}</td>
        <td>
          {item.description.length > 30 ? (
            <>
              {item.description.slice(0, 15)}...{item.description.slice(-15)}
            </>
          ) : (
            <>{item.description}</>
          )}
        </td>
        <td>{item.sku}</td>
        <td>{item.quantity}</td>
        <td>
          {item.varianttype === "cuttoweight" ? <>{item.weight}g</> : <>NVT</>}
        </td>
      </tr>
      {item.options.length > 0 && (
        <>
          <tr>
            <td colSpan="2">
              <table className="table-sm w-75 mx-auto">
                <tr className="posoptionline">
                  <td className="ms-3">Keuze-opties :</td>
                  <td>
                    {item.options.map((option, index) => {
                      return (
                        <Fragment key={index}>
                          {optionList &&
                            optionList.filter((op) => op.id === option)[0] && (
                              <>
                                {index > 0 && <>{", "}</>}
                                {
                                  optionList.filter((op) => op.id === option)[0]
                                    .description
                                }
                              </>
                            )}
                        </Fragment>
                      );
                    })}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default ItemLine;
