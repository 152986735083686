import React from "react";

const ProductQTable = ({ productList }) => {
  return (
    <>
      <div className="ccsubtitle">Top 10 Products by Quantity</div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Quantity</th>
            <th scope="col">Name</th>
            <th scope="col">Sku</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          {productList.map((prod, index) => (
            <tr key={index}>
              <th>{prod.totalqty}</th>
              <td>{prod.name}</td>
              <td>{prod.sku}</td>
              <td>{prod.totalvalue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ProductQTable;
