import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import ControlContext from "../Store/control-context";
import ShopContext from "../Store/shop-context";
import ProductList from "../Segments/ProductList";
import CategoryList from "../Segments/CategoryList";
import PosView from "../Segments/PosView";
import ShopOptions from "../Segments/ShopOptions";
import ProductSettings from "../Segments/ProductSettings";
import Catbuttons from "../Components/Catbuttons";
import SubCatButtons from "../Components/SubCatButtons";
import ShopLayout from "../Segments/ShopLayout";

const ShopController = () => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);
  const [selector, setSelector] = useState();

  useEffect(() => {
    shopObject.getcategorieslist();
    shopObject.getsubcategorieslist();
    shopObject.getproductsettings();
  }, [controlObject.webshop]);

  return (
    <div className="scpage">
      <Helmet>
        <title>Ubicopia®-Shop Controller</title>
        <meta name="description" content="Control your webshop" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <div className="sctitle">ShopController</div>
          </div>
          <div className="col-2">
            <div className="row">
              <button
                className="btn btn-primary btn-sm w-100"
                onClick={(e) => {
                  setSelector("products");
                }}
              >
                Products and Categories
              </button>
            </div>
            <div className="row mt-2">
              <button
                className="btn btn-primary btn-sm w-100"
                onClick={(e) => {
                  setSelector("groups");
                }}
              >
                Product Groups & Settings
              </button>
            </div>
          </div>
          <div className="col-2">
            <button
              className="btn btn-secondary btn-sm w-100"
              onClick={(e) => {
                setSelector("pickupoptions");
              }}
            >
              Pickup options
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-secondary btn-sm w-100"
              onClick={(e) => {
                setSelector("mailoptions");
              }}
            >
              Shop options
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-secondary btn-sm w-100"
              onClick={(e) => {
                setSelector("layoutoptions");
              }}
            >
              Layoutoptions
            </button>
          </div>
        </div>
      </div>
      <div className="scline">
        <div className=""></div>
      </div>
      {controlObject.webshop.ref === "" && (
        <>You need to select a webshop from the list</>
      )}
      {controlObject.webshop.ref !== "" && (
        <>
          {selector === "products" && (
            <>
              {shopObject.categories && <CategoryList />}
              {shopObject.categories.length > 0 && <Catbuttons />}
              {shopObject.category &&
                shopObject.subcategories &&
                shopObject.subcategories.length > 0 && <SubCatButtons />}
              {shopObject.products && <ProductList />}
            </>
          )}
          {selector === "groups" && (
            <>
              <ProductSettings />
            </>
          )}
          {selector === "pickupoptions" && (
            <>
              {shopObject.locs && shopObject.availas && shopObject.slots && (
                <PosView />
              )}
            </>
          )}
          {selector === "mailoptions" && (
            <>
              <ShopOptions />
            </>
          )}
          {selector === "layoutoptions" && (
            <>
              <ShopLayout />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ShopController;
