import React, { useState, useContext } from "react";
import AuthContext from "../Store/auth-context";
import { isocountries, titles } from "../Data/data";
import GetCountry from "./GetCountry";
import GetTitle from "./GetTitle";

const CreateInvCorrAddress = ({ user, organisation }) => {
  const [org, setOrg] = useState(organisation);
  const [addData, setAddData] = useState({
    street: "",
    zip: "",
    city: "",
    hnr: "",
    hnrext: "",
    country: "",
    defaultshipping: false,
    defaultbilling: false,
  });
  const authObject = useContext(AuthContext);

  const {
    street,
    zip,
    city,
    hnr,
    hnrext,
    country,
    defaultshipping,
    defaultbilling,
  } = addData;

  let createInvCorrAddress = async (add) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/address/createinvcorr/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(add),
      }
    );
    if (resp.status === 200) {
      console.log("Address has been created");
      authObject.getuserdetails(user.id);
    } else {
      alert("something went wrong checking your details");
    }
  };

  const addChange = (e) =>
    setAddData({ ...addData, [e.target.name]: e.target.value });

  const invAddSubmit = (e) => {
    e.preventDefault();
    createInvCorrAddress(addData);
  };

  return (
    <>
      {!org.invoice_address && (
        <>
          <div className="w-50">
            <div className="fs-3">Please enter your invoice address</div>
            <p>This will be required before we can continue</p>
            <p>This address will also be used as correspondence address</p>
            <p>
              A separate correspondence address can be created later under
              userdetails menu
            </p>
            <form onSubmit={(e) => invAddSubmit(e)}>
              <div className="form-group mt-3">
                <label className="">Street</label>
                <input
                  className="form-control"
                  placeholder="street"
                  type="text"
                  required
                  id="street"
                  name="street"
                  value={street}
                  onInput={(e) => addChange(e)}
                  pattern="[a-zA-Z0-9éèçà()\s\-^<>]{1,200}$"
                ></input>
                <div className="form-group mt-3">
                  <label className="">hnr</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="hnr*"
                    name="hnr"
                    size="3"
                    value={hnr}
                    onChange={(e) => addChange(e)}
                    pattern="[0-9a-zA-Z^<>]{1,10}$"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="">Hnr ext</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="hnrext"
                    name="hnrext"
                    value={hnrext}
                    onChange={(e) => addChange(e)}
                    pattern="[0-9a-zA-Z^<>]{1,10}$"
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="">Zip</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="zip"
                    name="zip"
                    value={zip}
                    onChange={(e) => addChange(e)}
                    pattern="[0-9^<>]{4,20}$"
                    required
                  />
                  <label className="">City</label>
                  <input
                    className="form-control"
                    placeholder="city"
                    type="text"
                    required
                    id="city"
                    name="city"
                    value={city}
                    onInput={(e) => addChange(e)}
                    pattern="[a-zA-Zéèçà()\s\-^<>]{1,100}$"
                  ></input>
                </div>
                <div className="form-group mt-3">
                  <div className="addressbox_line_left">Country</div>
                  <select
                    className="form-control"
                    placeholder="country"
                    type="text"
                    required
                    id="country"
                    name="country"
                    value={country}
                    onInput={(e) => addChange(e)}
                  >
                    <option name="country" value="">
                      -- select a country --
                    </option>
                    {isocountries &&
                      isocountries.map((ic, index) => {
                        return (
                          <option name="country" value={ic.code} key={index}>
                            {ic.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <button className="btn btn-primary" type="submit">
                  Bewaar
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CreateInvCorrAddress;
