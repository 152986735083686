import React, { useState } from "react";

const AddNewsletter = ({ setToggler, organisation, webshop }) => {
  const [newsData, setNewsData] = useState({
    name: "",
    organisation: organisation.id,
    header: "",
    body: "",
    picture1: "",
    picture2: "",
    url: "",
    webshop: webshop.id,
  });

  let createNews = async (news) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/newsletter/",
      {
        method: "POST",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: news,
      }
    );
    if (resp.status === 200) {
      console.log("news created");
      setToggler(false);
    } else {
      alert("something went wrong creating your newsletter");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setNewsData({ ...newsData, [fieldname]: value });
  };

  const imageHandler = (e) => {
    e.preventDefault();
    const fieldname = e.target.name;
    const value = e.target.files[0];
    setNewsData({ ...newsData, [fieldname]: value });
  };

  const newsSubmit = (e) => {
    e.preventDefault();
    console.log("created news :", newsData);
    const newsLetter = new FormData();
    newsLetter.append("name", newsData.name);
    newsLetter.append("organisation", newsData.organisation);
    newsLetter.append("header", newsData.header);
    newsLetter.append("body", newsData.body);
    newsLetter.append("picture1", newsData.picture1);
    newsLetter.append("picture2", newsData.picture2);
    newsLetter.append("url", newsData.url);
    newsLetter.append("webshop", newsData.webshop);
    createNews(newsLetter);
  };

  return (
    <div className="w-50">
      <form onSubmit={(e) => newsSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">Name of Newsletter (for filing only)</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={newsData.name}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Title</label>
          <input
            className="form-control"
            type="text"
            name="header"
            value={newsData.header}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,200}$"
            required
          />
        </div>
        <div className="form-group mt-3 w-50">
          <label className="w-100">Title picture</label>
          {newsData.picture1 && (
            <img
              className="img-thumbnail"
              src={URL.createObjectURL(newsData.picture1)}
            ></img>
          )}
          {!newsData.picture1 && <>None</>}
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Picture 1"
            name="picture1"
            id="picture1"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Body/Message</label>
          <textarea
            className="form-control"
            type="text"
            name="body"
            value={newsData.body}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<>]{1,1000}$"
            required
          />
        </div>
        <div className="form-group mt-3 w-50">
          <label className="w-100">Subject Picture</label>
          {newsData.picture2 && (
            <img
              className="img-thumbnail"
              src={URL.createObjectURL(newsData.picture2)}
            ></img>
          )}
          {!newsData.picture2 && <>None</>}
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Picture 2"
            name="picture2"
            id="picture2"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Link</label>
          <input
            className="form-control"
            type="url"
            placeholder="Link"
            name="url"
            value={newsData.url}
            onChange={handler}
          />
        </div>
        <button
          className="btn btn-secondary mt-3"
          onClick={() => setToggler(false)}
          type="button"
        >
          Cancel
        </button>
        <button className="btn btn-success mt-3 ms-5" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default AddNewsletter;
