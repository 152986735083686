import React from "react";
import rgba2Alfa from "../Functions/rgba2Alfa";
import rgba2Hex from "../Functions/rgba2Hex";

const ColorBox = ({ col, index1, index2, handleChange }) => {
  return (
    <div className="row" key={index2}>
      <div className="col-4                                                                                                                                                                                                                     ">
        <div className="row">
          <label
            className="form-label col d-flex align-items-center"
            htmlFor="favcolor"
          >
            {col.name.slice(0, 4)}
          </label>
          <input
            type="color"
            className="form-control col"
            style={{ width: "30px", height: "40px" }}
            id="favcolor"
            name="hex"
            value={rgba2Hex(col.rgba)}
            onChange={(e) => handleChange(e, index1, index2)}
          />
        </div>
      </div>
      <div className="col-6 d-flex align-items-center">
        <div>
          {/* <label className="form-label" htmlFor="opacity">
            Opacity
          </label> */}
          <input
            id="opacity"
            type="range"
            className="form-control"
            name="opacity"
            value={rgba2Alfa(col.rgba)}
            onChange={(e) => handleChange(e, index1, index2)}
            min="0"
            max="1"
            step="0.1"
          />
        </div>
      </div>
    </div>
  );
};

export default ColorBox;
