import React, { useState } from "react";

const CreateShop = ({ organisation, getshoplist, settoggler }) => {
  const [shopData, setShopData] = useState({
    brand: "",
    logosmall: "",
    logolarge: "",
    currency: "EUR",
  });

  let makeShop = async (shop) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/webshop/", {
      method: "POST",
      headers: {
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: shop,
    });
    if (resp.status === 200) {
      getshoplist(organisation.id);
      settoggler(null);
    } else {
      alert("something went wrong updating the organisation");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setShopData({ ...shopData, [fieldname]: value });
  };

  const imageHandler = (e) => {
    e.preventDefault();
    const fieldname = e.target.name;
    const value = e.target.files[0];
    setShopData({ ...shopData, [fieldname]: value });
  };

  const shopSubmit = (e) => {
    e.preventDefault();
    const newShop = new FormData();
    newShop.append("brand", shopData.brand);
    newShop.append("currency", shopData.currency);
    newShop.append("logosmall", shopData.logosmall);
    newShop.append("logolarge", shopData.logolarge);
    makeShop(newShop);
  };

  return (
    <div>
      <form onSubmit={(e) => shopSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">
            Brand name of webshop (for emails, letters, ... to customers) - min
            length 6 digits
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Brand Name*"
            name="brand"
            value={shopData.brand}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{6,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">currency code EUR, USD, ...</label>
          <input
            className="form-control"
            type="text"
            placeholder="Currency*"
            name="currency"
            value={shopData.currency}
            onChange={handler}
            pattern="[A-Z]{3,3}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Logo - small - 100px x 100px (jpeg, png or gif)
          </label>
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Logo Small"
            name="logosmall"
            id="logosmall"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Logo - wide - 400px x 100px</label>
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Logo large"
            name="logolarge"
            id="logolarge"
            onChange={imageHandler}
          />
        </div>
        <button className="btn btn-primary mt-3" type="submit">
          Save
        </button>
        <button
          className="btn btn-secondary mt-3 ms-3"
          onClick={(e) => settoggler(false)}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default CreateShop;
