import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";

//Routing
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

//Context
import { AuthProvider } from "./Store/auth-context";
import { ControlProvider } from "./Store/control-context";
import { ShopProvider } from "./Store/shop-context";

//Segments
import Header from "./Segments/Header";
import Footer from "./Segments/Footer";

//Pages

import WelcomePage from "./Pages/WelcomePage";
import OrderController from "./Pages/OrderController";
import ShopController from "./Pages/ShopController";
import UserController from "./Pages/UserController";
import CustomerController from "./Pages/CustomerController";
import AccountingPage from "./Pages/AccountingPage";
import Unsubscribe from "./Pages/Unsubscribe";
import LoginPage from "./Pages/LoginPage";
import Signup from "./Pages/Signup";
import ResetPassword from "./Pages/ResetPassword";
import ResetPasswordConfirm from "./Pages/ResetPasswordConfirm";

// Styles
// import { GlobalStyle } from "./GlobalStyle";

const App = () => (
  <Router>
    <Helmet>
      <meta
        name="twitter:title"
        content="Ubicopia® - e-commerce voor iedereen"
      />
      <meta
        name="description"
        content="Ubicopia® is een veelzijdige e-commerce tool voor gebruik als webshop, besteltool, afhaaltool, als webshop voor kleinhandel met één of meerdere locaties, en ook geschikt voor foodtrucks of afhaal met verse gerechten."
      />
      <meta
        name="twitter:description"
        content="Ubicopia® is een veelzijdige e-commerce tool voor gebruik als webshop, besteltool, afhaaltool, als webshop voor kleinhandel met één of meerdere locaties, en ook geschikt voor foodtrucks of afhaal met verse gerechten."
      />
      <meta
        name="twitter:image"
        content="/assets/apple-touch-icon-120x120.png"
      />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content="Ubicopia® - e-commerce voor iedereen"
      />
      <meta
        property="og:description"
        content="Ubicopia® is een veelzijdige e-commerce tool voor gebruik als webshop, besteltool, afhaaltool, als webshop voor kleinhandel met één of meerdere locaties, en ook geschikt voor foodtrucks of afhaal met verse gerechten."
      />
      <meta
        property="og:image"
        content="/assets/apple-touch-icon-120x120.png"
      />
    </Helmet>
    <AuthProvider>
      <ControlProvider>
        <ShopProvider>
          <Suspense fallback={<div>...loading</div>}>
            <Header />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login/" element={<LoginPage />} />
              <Route path="/signup/" element={<Signup />} />
              <Route path="/reset-password/" element={<ResetPassword />} />
              <Route path="/welcome/" element={<WelcomePage />} />
              <Route path="/ordercontroller/" element={<OrderController />} />
              <Route path="/shopcontroller/" element={<ShopController />} />
              <Route
                path="/customercontroller/"
                element={<CustomerController />}
              />
              <Route path="/usercontroller/" element={<UserController />} />
              <Route path="/accountingpage/" element={<AccountingPage />} />
              <Route path="/unsubscribe/:webid/" element={<Unsubscribe />} />
            </Routes>
            <Footer />
          </Suspense>
        </ShopProvider>
      </ControlProvider>
    </AuthProvider>
  </Router>
);

export default App;
