import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Company from "../Data/Company";
import Unsubscribe from "../Data/Unsubscribe";
import Cookies from "../Data/Cookies";
import Privacy from "../Data/Privacy";
import Disclaimer from "../Data/Disclaimer";
import GTC from "../Data/GTC";

const Footer = () => {
  const [show, setShow] = useState(null);

  const [bypass, setBypass] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/returnsuccess/" ||
      location.pathname === "/returnabort/" ||
      location.pathname === "/returnfail/"
    ) {
      setBypass(true);
    }
  });
  return (
    <>
      {bypass && <></>}
      {!bypass && (
        <>
          <div className="footersection bg-dark pt-5 text-white">
            <footer>
              <div className="container">
                <div></div>
                <div className="row justify-content-center text-white">
                  <div className="col-sm-4 col-md-3 text-center item">
                    <h3 className="text-white">Over ons</h3>
                    <br></br>
                    <div>
                      <Company />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-3 text-center item">
                    <h3 className="text-white">Nieuwsbrief</h3>
                    <br></br>
                    <p className="" onClick={() => setShow("unsubscribe")}>
                      Unsubscribe
                    </p>

                    <h3 className="text-white">Social Media</h3>
                    <br></br>
                    <a href="https://www.facebook.com/profile.php?id=100088970538904">
                      <i className="bi bi-facebook text-primary bg-white fs-4"></i>
                    </a>
                    <a href="https://www.instagram.com/ubicopia/">
                      <i className="bi bi-instagram fs-4 ms-2"></i>
                    </a>
                  </div>
                  <div className="col-sm-4 col-md-3 text-center item">
                    <h3 className="text-white">Policies</h3>
                    <br></br>
                    {/* <p>
                      <a href="javascript:document.dispatchEvent(new Event('cookie_consent_show'))">
                        Show Cookie Settings
                      </a>
                    </p> */}

                    <p className="" onClick={() => setShow("privacy")}>
                      Privé
                    </p>

                    <p className="" onClick={() => setShow("disclaimer")}>
                      Disclaimer
                    </p>

                    <p className="" onClick={() => setShow("gtc")}>
                      Algemene voorwaarden
                    </p>
                  </div>
                  {show === "unsubscribe" && (
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setShow(null)}
                      ></button>
                      <Unsubscribe />
                    </div>
                  )}
                  {show === "cookies" && (
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setShow(null)}
                      ></button>
                      <Cookies />
                    </div>
                  )}
                  {show === "privacy" && (
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setShow(null)}
                      ></button>
                      <Privacy />
                    </div>
                  )}
                  {show === "disclaimer" && (
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setShow(null)}
                      ></button>
                      <Disclaimer />
                    </div>
                  )}
                  {show === "gtc" && (
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setShow(null)}
                      ></button>
                      <GTC />
                    </div>
                  )}

                  <p className="w-100 text-center">DRO Projects BV © 2023</p>
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
