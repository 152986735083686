import React, { useContext, useState } from "react";
import ShopContext from "../Store/shop-context";

const EditSlot = ({ oldslot, setToggler }) => {
  const shopObject = useContext(ShopContext);

  const [slot, setSlot] = useState(oldslot);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setSlot({ ...slot, [name]: value });
  }

  const handleUpdate = (e) => {
    if (slot["maxbookings"] === "") {
      slot["maxbookings"] = null;
    }
    shopObject.updateslots(slot);
    // setToggler(false);
  };

  const handleDelete = (e) => {
    shopObject.deleteslots(slot);
    setToggler(null);
  };

  const handleCancel = (e) => {
    setToggler(false);
  };

  return (
    <>
      {slot && (
        <div className="posslotline">
          <input
            required
            className=""
            placeholder="slotfrom"
            type="time"
            id="slotfrom"
            name="slotfrom"
            value={slot.slotfrom}
            onChange={handleChange}
          ></input>
          <input
            required
            className=""
            placeholder="slotfrom"
            type="time"
            id="slotto"
            name="slotto"
            value={slot.slotto}
            onChange={handleChange}
          ></input>
          <input
            required
            className=""
            placeholder="max bookings"
            type="integer"
            id="maxbookings"
            name="maxbookings"
            value={slot.maxbookings}
            onChange={handleChange}
          ></input>
          <button className="cancelbutton" onClick={(e) => handleCancel()}>
            cancel
          </button>

          <button className="editbutton" onClick={(e) => handleUpdate()}>
            Update
          </button>
          <button className="delbutton" onClick={(e) => handleDelete()}>
            Delete
          </button>
        </div>
      )}
    </>
  );
};

export default EditSlot;
