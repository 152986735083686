import React from "react";

const ShowSubCat = ({ subCat, clickEdit, clickDelete }) => {
  return (
    <div className="row mt-2">
      <div className="col-1"></div>
      <div className="col-2">{subCat.name}</div>
      <div className="col-3">
        <button
          className="border border-0 col-1 bg-transparent"
          onClick={(e) => clickEdit(subCat)}
        >
          <i className="bi bi-pen"></i>
        </button>{" "}
        <button
          className="border border-0 col-1 bg-transparent"
          onClick={(e) => clickDelete(subCat)}
        >
          <i className="bi bi-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default ShowSubCat;
