import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";

const AddCat = ({ toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);

  const blankCat = {
    name: "",
    position: "",
  };

  const [cat, setCat] = useState(blankCat);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setCat({ ...cat, [name]: value });
  }

  const handleCreate = (e) => {
    e.preventDefault();
    let pos = shopObject.categories.length;
    cat["position"] = pos;
    setCat({ ...cat, ["position"]: pos });
    shopObject.createcategory(cat);
    setToggler(false);
    shopObject.setreload(!shopObject.reload);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  return (
    <div className="scline">
      <input
        className="sccatname"
        placeholder="category name"
        type="text"
        size="20"
        required
        id="name"
        name="name"
        value={cat.name}
        onChange={handleChange}
      />
      <button className="btn btn-success btn-sm" onClick={handleCreate}>
        Create
      </button>
      <button className="btn btn-secondary btn-sm " onClick={handleCancel}>
        cancel
      </button>
    </div>
  );
};

export default AddCat;
