import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";

const DeleteCat = ({ category, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);

  const handleDelete = (e) => {
    e.preventDefault();
    shopObject.deletecategory(category);
    setToggler(false);
    shopObject.setreload(!shopObject.reload);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  return (
    <div className="scline">
      <div className="sccatname">{category.name}</div>
      <button className="btn btn-danger btn-sm " onClick={handleDelete}>
        Delete
      </button>
      <button className="btn btn-secondary btn-sm " onClick={handleCancel}>
        cancel
      </button>
    </div>
  );
};

export default DeleteCat;
