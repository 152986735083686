import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";

const AddSubCat = ({ cat, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);

  const blankSubCat = {
    category: cat.id,
    name: "",
    position: "",
  };

  const [subCat, setSubCat] = useState(blankSubCat);

  function handleChangeSubCategory(e) {
    const name = e.target.name;
    const value = e.target.value;
    setSubCat({ ...subCat, [name]: value });
  }

  const handleCreateSubCategory = (e) => {
    e.preventDefault();
    let pos = shopObject.subcategories.filter(
      (subcat) => subcat.category === cat.id
    ).length;
    subCat["position"] = pos;
    setSubCat({ ...subCat, ["position"]: pos });
    console.log("creating subcat:", subCat);
    shopObject.createsubcategory(subCat, cat);
    setToggler(false);
    shopObject.setreload(!shopObject.reload);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  return (
    <div className="row mt-2">
      <div className="col-1"></div>
      <div className="col-2">
        <input
          className=""
          placeholder="subcategory name"
          type="text"
          size="20"
          required
          id="name"
          name="name"
          value={subCat.name}
          onChange={handleChangeSubCategory}
        />
      </div>
      <div className="col-3">
        <button
          className="border border-0 bg-transparent col-1 fs-4"
          onClick={handleCreateSubCategory}
        >
          <i className="bi bi-check"></i>
        </button>
        <button
          className="border border-0 bg-transparent col-1 fs-4"
          onClick={handleCancel}
        >
          <i className="bi bi-x"></i>
        </button>
      </div>
    </div>
  );
};

export default AddSubCat;
