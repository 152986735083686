import React, { useState, useEffect, useContext } from "react";
import CountDown from "./CountDown";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

const CookCounter = ({ counterOrder, reload, setReload }) => {
  const shopObject = useContext(ShopContext);
  const controlObject = useContext(ControlContext);
  // const [order, setOrder] = useState();
  const [time, setTime] = useState();
  const [isStarted, setIsStarted] = useState(false);
  const [diff, setDiff] = useState(-1);
  const [clickStart, setClickStart] = useState(false);
  const [clickConfirm, setClickConfirm] = useState(false);

  function fillZero(num, digital) {
    num = `${num}`;
    while (digital - num.length > 0) {
      num = `0${num}`;
    }
    return num;
  }

  let updateOrderCookTimer = async (order, timer) => {
    var now = new Date();
    timer = timer / 60;
    var timerDate = now.setMinutes(now.getMinutes() + timer);
    timerDate = new Date(timerDate);

    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/timer/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: order.id,
          timer: timer,
          timerdate: timerDate,
        }),
      }
    );
    if (resp.status === 200) {
      controlObject.setorderupdate(!controlObject.orderupdate);
    } else {
      console.log("something went wrong");
      console.log("resp :", resp);
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      if (!isStarted || time <= 0) return;
      setTime(time - 1);
    }, 1000);
    return () => clearInterval(id);
  }, [time, isStarted]);

  useEffect(() => {
    if (counterOrder.cooktime) {
      let now = new Date();
      let timer = new Date(counterOrder.cooktime);
      let difference = timer.getTime() - now.getTime();
      setDiff(difference);
      if (difference > 0) {
        setTime((difference / 1000).toFixed(0));
        setIsStarted(true);
        setClickStart(true);
        setClickConfirm(true);
      } else {
        setTime(0);
        setIsStarted(false);
        setClickStart(true);
        setClickConfirm(true);
      }
    } else {
      if (shopObject.shopoptions.usecooktimer) {
        setTime(shopObject.shopoptions.cooktimer * 60);
        setIsStarted(false);
        setClickStart(false);
        setClickConfirm(false);
      }
    }
  }, [, counterOrder]);

  function clickedStart() {
    setClickStart(true);
  }

  function clickedConfirm() {
    setClickConfirm(true);
    setIsStarted(!isStarted);
    updateOrderCookTimer(counterOrder, time);
  }

  return (
    <div className="timerline">
      {time > 0 && !isStarted && (
        <div className="clockstatic">
          <CountDown second={time} />
        </div>
      )}

      {time > 0 && isStarted && (
        <div className="clockrunning">
          <CountDown second={time} />
        </div>
      )}

      {time === 0 && (
        <>
          <div className="clockzero">
            <CountDown second={time} />
          </div>
          <div className="confirmedtime">
            {new Date(counterOrder.cooktime).getHours()}:
            {fillZero(new Date(counterOrder.cooktime).getMinutes(), 2)}
          </div>
        </>
      )}

      {!clickStart && time > 0 && (
        <button className="timerplusbutton" onClick={() => setTime(time + 60)}>
          +1min
        </button>
      )}

      {!clickStart && time > 0 && (
        <button className="starttimerbutton" onClick={() => clickedStart()}>
          Start
        </button>
      )}
      {!clickConfirm && clickStart && (
        <button className="confirmtimerbutton" onClick={() => clickedConfirm()}>
          Confirm
        </button>
      )}
    </div>
  );
};

export default CookCounter;
