import React, { useState, useContext } from "react";
import AuthContext from "../Store/auth-context";

const EditOrg = ({ organisation, settoggler }) => {
  const authObject = useContext(AuthContext);

  const [orgData, setOrgData] = useState(organisation);
  const [showWL, setShowWL] = useState(false);
  const [showPNL, setShowPNL] = useState(false);

  let updateOrg = async (org) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/organisation/",
      {
        method: "PATCH",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: org,
      }
    );
    if (resp.status === 200) {
      authObject.getuserdetails(authObject.user.id);
      settoggler(false);
    } else {
      alert("something went wrong updating the organisation");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setOrgData({ ...orgData, [fieldname]: value });
  };

  const imageHandler = (e) => {
    e.preventDefault();
    const fieldname = e.target.name;
    const value = e.target.files[0];
    setOrgData({ ...orgData, [fieldname]: value });
  };

  const orgSubmit = (e) => {
    e.preventDefault();
    const newOrg = new FormData();
    newOrg.append("name", orgData.name);
    newOrg.append("brand", orgData.brand);
    newOrg.append("goodsdescription", orgData.goodsdescription);
    newOrg.append("generalconditions", orgData.generalconditions);
    if (orgData.logosmall) {
      if (orgData.logosmall !== organisation.logosmall) {
        newOrg.append("logosmall", orgData.logosmall);
      }
    }
    if (orgData.logolarge) {
      if (orgData.logolarge !== organisation.logolarge) {
        newOrg.append("logolarge", orgData.logolarge);
      }
    }
    newOrg.append("vat", orgData.vat);
    newOrg.append("bankiban", orgData.bankiban);
    newOrg.append("bankbic", orgData.bankbic);
    newOrg.append(
      "sandbox_saferpayterminalid",
      orgData.sandbox_saferpayterminalid
    );
    newOrg.append("sandbox_saferpayheader", orgData.sandbox_saferpayheader);
    newOrg.append("sandbox_saferpaycustid", orgData.sandbox_saferpaycustid);
    newOrg.append("saferpayterminalid", orgData.saferpayterminalid);
    newOrg.append("saferpayheader", orgData.saferpayheader);
    newOrg.append("saferpaycustid", orgData.saferpaycustid);
    newOrg.append("api_pnl_sandbox", orgData.api_pnl_sandbox);
    newOrg.append("api_pnl_real", orgData.api_pnl_real);
    newOrg.append("pnl_collectionlocation", orgData.pnl_collectionlocation);
    newOrg.append("pnl_customercode", orgData.pnl_customercode);
    newOrg.append("pnl_customernumber", orgData.pnl_customernumber);
    updateOrg(newOrg);
  };

  return (
    <div className="userblock bg-light p-3 rounded-2">
      <div className="row">
        <div className="usersubtitle col-10">Your organisation details</div>
      </div>
      <form onSubmit={(e) => orgSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">Official name of organisation</label>
          <input
            className="form-control"
            type="text"
            placeholder="Name*"
            name="name"
            value={orgData.name}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,100}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">VAT number - BEXXXXXXXXXX (no spaces)</label>
          <input
            className="form-control"
            type="text"
            placeholder="VAT*"
            name="vat"
            value={orgData.vat}
            onChange={handler}
            pattern="[A-Z]{2}[0-9]{1,20}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Brand name of organisation (for emails, letters, ... to customers) -
            min lengte 6 digits
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Brand Name*"
            name="brand"
            value={orgData.brand}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{6,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Description of goods traded via the Ubicopia channel (food,
            computers, clothing,....)
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Goods description*"
            name="goodsdescription"
            value={orgData.goodsdescription}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{2,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Your general conditions - to show in your online store (you can
            refer to your website)
          </label>
          <textarea
            className="form-control"
            type="text"
            placeholder="General conditions*"
            name="generalconditions"
            value={orgData.generalconditions}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{6,1000}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Logo - small - 100px x 100px (jpeg, png or gif)
          </label>
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Logo Small"
            name="logosmall"
            id="logosmall"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Logo - wide - 400px x 100px</label>
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Logo large"
            name="logolarge"
            id="logolarge"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Bank Iban - no spaces</label>
          <input
            className="form-control"
            type="text"
            placeholder="IBAN"
            name="bankiban"
            value={orgData.bankiban}
            onChange={handler}
            pattern="[A-Z]{2}\d{13,32}|(?=.{18,42}$)[A-Z]{2}\d{2}( )(\d{4}\1){2,7}\d{1,4}"
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Bank BIC (swift code)</label>
          <input
            className="form-control"
            type="text"
            placeholder="BIC"
            name="bankbic"
            value={orgData.bankbic}
            onChange={handler}
            pattern="[A-Z0-9]{7,9}"
          />
        </div>
        <div className="mt-3">
          <button
            type="button"
            className="fw-bold mt-3"
            onClick={() => setShowWL(!showWL)}
          >
            show/hide Worldline - Account details
          </button>
        </div>
        {showWL && (
          <>
            <div className="fw-bold mt-3">
              Worldline - Sandbox account details
            </div>
            <div className="ms-3">
              <div className="form-group mt-3">
                <label className="">
                  Sandbox - e-commerce - Customer ID (for online payments)
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="123456"
                  name="sandbox_saferpaycustid"
                  value={orgData.sandbox_saferpaycustid}
                  onChange={handler}
                  pattern="[0-9]{1,8}"
                />
              </div>
              <div className="form-group mt-3">
                <label className="">
                  Sandbox - WorldLine - e-commerce - Terminal ID (for online
                  payments)
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="12345678"
                  name="sandbox_saferpayterminalid"
                  value={orgData.sandbox_saferpayterminalid}
                  onChange={handler}
                  pattern="[0-9]{7,8}"
                />
              </div>
              <div className="form-group mt-3">
                <label className="">
                  Sandbox- WorldLine - e-commerce - BASE64 header (starts with
                  Basic xxxxxxxx)
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Basic xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  name="sandbox_saferpayheader"
                  value={orgData.sandbox_saferpayheader}
                  onChange={handler}
                />
              </div>
            </div>
            <div className="fw-bold mt-3">Worldline - real identification</div>
            <div className="ms-3">
              <div className="form-group mt-3">
                <label className="">
                  WorldLine - e-commerce - Customer ID (for online payments)
                </label>

                <input
                  className="form-control"
                  type="text"
                  placeholder="123456"
                  name="saferpaycustid"
                  value={orgData.saferpaycustid}
                  onChange={handler}
                  pattern="[0-9]{1,8}"
                />
              </div>
              <div className="form-group mt-3">
                <label className="">
                  WorldLine - e-commerce - Terminal ID (for online payments)
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="12345678"
                  name="saferpayterminalid"
                  value={orgData.saferpayterminalid}
                  onChange={handler}
                  pattern="[0-9]{7,8}"
                />
              </div>
              <div className="form-group mt-3">
                <label className="">
                  WorldLine - e-commerce - BASE64 header (starts with Basic
                  xxxxxxxx)
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Basic xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  name="saferpayheader"
                  value={orgData.saferpayheader}
                  onChange={handler}
                />
              </div>
            </div>
          </>
        )}
        <div className="mt-3">
          <button
            type="button"
            className="fw-bold mt-3"
            onClick={() => setShowPNL(!showPNL)}
          >
            show/hide PostNL - Account details
          </button>
        </div>
        {showPNL && (
          <>
            <div className="fw-bold mt-3">Post NL - identification</div>
            <div className="ms-3">
              <div className="form-group mt-3">
                <label className="">Post NL - sandbox API key</label>

                <input
                  className="form-control"
                  type="text"
                  name="api_pnl_sandbox"
                  value={orgData.api_pnl_sandbox}
                  onChange={handler}
                />
              </div>
              <div className="form-group mt-3">
                <label className="">Post NL - real world API key</label>
                <input
                  className="form-control"
                  type="text"
                  name="api_pnl_real"
                  value={orgData.api_pnl_real}
                  onChange={handler}
                />
              </div>
              <div className="form-group mt-3">
                <label className="">Post NL - Collection location code</label>
                <input
                  className="form-control"
                  type="text"
                  name="pnl_collectionlocation"
                  value={orgData.pnl_collectionlocation}
                  onChange={handler}
                  pattern="[a-zA-Z0-9]{0,20}"
                />
              </div>
              <div className="form-group mt-3">
                <label className="">Post NL - your customer code</label>
                <input
                  className="form-control"
                  type="text"
                  name="pnl_customercode"
                  value={orgData.pnl_customercode}
                  onChange={handler}
                  pattern="[a-zA-Z0-9]{0,20}"
                />
              </div>
              <div className="form-group mt-3">
                <label className="">Post NL - your customer number</label>
                <input
                  className="form-control"
                  type="text"
                  name="pnl_customernumber"
                  value={orgData.pnl_customernumber}
                  onChange={handler}
                  pattern="[a-zA-Z0-9]{0,20}"
                />
              </div>
            </div>
          </>
        )}

        <button className="btn btn-primary mt-5" type="submit">
          Save
        </button>
        <button
          className="btn btn-secondary mt-5 ms-2"
          onClick={(e) => settoggler(false)}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default EditOrg;
