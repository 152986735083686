import React, { Fragment, useContext, useState, useEffect } from "react";
import ControlContext from "../Store/control-context";
import DisplayDatefromPython from "./DisplayDatefromPython";

const AccountListPerDay = ({
  listOrders,
  listItems,
  loopDates,
  receivables,
  setReceivables,
  clickedRun,
}) => {
  const line = {
    date: null,
    description: "",
    total: null,
    total0: null,
    total6: null,
    total12: null,
    total21: null,
  };

  const controlObject = useContext(ControlContext);
  const [dates, setDates] = useState(loopDates);
  const [orderList, setOrderList] = useState([]);
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    console.log("running useEffect 1 accountlistperday");
    setReceivables([]);
    setOrderList(listOrders);
    setItemList(listItems);
    makeTable(loopDates);
  }, [clickedRun]);

  useEffect(() => {
    console.log("running useEffect 2 accountlistperday");
    makeTable(loopDates);
    setOrderList(listOrders);
    setItemList(listItems);
  }, []);

  function makeTable(dates) {
    let newTable = [];

    if (listOrders.length > 0 && listItems.length > 0) {
      dates.map((date, index) => {
        let dayLine = {};
        let daytotal = 0;
        let daytot0 = 0;
        let daytot21 = 0;
        let daytot12 = 0;
        let daytot6 = 0;
        if (listOrders) {
          let dayorders = listOrders.filter(
            (order) =>
              new Date(order.shipped).setHours(0, 0, 0, 0) ===
              date.setHours(0, 0, 0, 0)
          );
          dayorders.map((order, index) => {
            daytotal = daytotal + (order.totalprice + order.totalvat);

            order.items.map((orderitem, index) => {
              let item = listItems.filter((item) => item.id === orderitem)[0];
              if (item.cartitemvat === 0) {
                daytot0 = daytot0 + item.cartitemtotal * (1 + item.cartitemvat);
              }
              if (item.cartitemvat === 0.06) {
                daytot6 = daytot6 + item.cartitemtotal * (1 + item.cartitemvat);
              }
              if (item.cartitemvat === 0.12) {
                daytot12 =
                  daytot12 + item.cartitemtotal * (1 + item.cartitemvat);
              }
              if (item.cartitemvat === 0.21) {
                daytot21 =
                  daytot21 + item.cartitemtotal * (1 + item.cartitemvat);
              }
            });
          });
        }
        dayLine.date = date;
        dayLine.description = "online";
        dayLine.total = daytotal;
        dayLine.total0 = daytot0;
        dayLine.total6 = daytot6;
        dayLine.total12 = daytot12;
        dayLine.total21 = daytot21;
        newTable.push(dayLine);
      });
      setReceivables(newTable);
    }
  }

  return (
    <>
      {listItems.length > 0 &&
        listOrders.length > 0 &&
        receivables.length > 0 && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Omschrijving</th>
                  <th>Totaal</th>
                  <th>Totaal 0%</th>
                  <th>Totaal 6%</th>
                  <th>Totaal 12%</th>
                  <th>Totaal 21%</th>
                </tr>
              </thead>
              <tbody>
                {receivables &&
                  receivables.map((rec, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <DisplayDatefromPython date={rec.date} />
                        </td>
                        <td>Online-inkomsten</td>
                        <td>{rec.total.toFixed(2)}</td>
                        <td>{rec.total0.toFixed(2)}</td>
                        <td>{rec.total6.toFixed(2)}</td>
                        <td>{rec.total12.toFixed(2)}</td>
                        <td>{rec.total21.toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
    </>
  );
};

export default AccountListPerDay;
