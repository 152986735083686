import React from "react";
import GetDay from "./GetDay";
import GetTime from "./GetTime";

const ShowProductSetting = ({ prodset, setClickedEdit, setClickedDelete }) => {
  return (
    <tr>
      <th>{prodset.name}</th>
      <td>{prodset.leadtime && <>{prodset.leadtime} dagen</>}</td>
      <td>
        {!prodset.leadtime && prodset.orderdeadline && (
          <>
            <GetDay code={new Date(prodset.orderdeadline).getDay()} />
            {" before "} <GetTime date={prodset.orderdeadline} />
          </>
        )}
      </td>
      <td>
        {!prodset.leadtime && prodset.orderdelivery && (
          <>
            <GetDay code={new Date(prodset.orderdelivery).getDay()} />
            {" from "} <GetTime date={prodset.orderdelivery} />
          </>
        )}
      </td>
      <td>
        <button
          className="btn btn-success btn-sm"
          onClick={() => setClickedEdit(prodset.id)}
        >
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm ms-2"
          onClick={() => setClickedDelete(prodset.id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default ShowProductSetting;
