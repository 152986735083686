import React from "react";

const Unsubscribe = () => {
  return (
    <div>
      <p></p>Om uit te schrijven, gelieve een mail te sturen naar
      info@ubicopia.com
    </div>
  );
};

export default Unsubscribe;
