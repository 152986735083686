import { createContext, useState, useEffect, useContext } from "react";
import AuthContext from "./auth-context";

import { blankWebShop } from "../Data/data";

const ControlContext = createContext({
  webshoplist: null,
  webshop: null,
  setwebshop: () => {},
  getwebshoplist: () => {},

  workorderlist: null,

  workorder: null,
  setworkorder: () => {},

  orderupdate: null,
  setorderupdate: () => {},

  getfromtoorders: () => {},
  getfromtoitems: () => {},

  getunfinishedorderlist: () => {},
  getopenorderlist: () => {},
  getopenposorderlist: () => {},
  getopenshiporderlist: () => {},
  getunpaidorderlist: () => {},
  getshippedorderlist: () => {},
  getallorderlist: () => {},
  getfilteredorderlist: () => {},
  updateorderpaid: () => {},
  updateorderonhold: () => {},
  updateordercancel: () => {},
  updateorderready: () => {},
  declareforshipping: () => {},
  updateordershipped: () => {},
  createorder: () => {},
  updateorder: () => {},
  deleteorder: () => {},

  label: null,
  barcode: null,
  pnldata: null,
  shiporder: () => {},

  workitemlist: null,
  setworkitemlist: () => {},

  workitemlistcompressed: null,
  setworkitemlistcompressed: () => {},

  workbookinglist: null,
  setworkbookinglist: () => {},

  getallitems: () => {},
  getunfinisheditems: () => {},
  getunfinisheditemscompressed: () => {},
  getunfinishedbookings: () => {},

  language: null,
  setlanguage: () => {},
});

export function ControlProvider(props) {
  const authObject = useContext(AuthContext);

  const [webShopList, setWebShopList] = useState([]);
  const [webShop, setWebShop] = useState(blankWebShop);

  const [orderUpdate, setOrderUpdate] = useState(false);

  const [workOrderList, setWorkOrderList] = useState([]);
  const [workBookingList, setWorkBookingList] = useState([]);

  const [workOrder, setWorkOrder] = useState([]);
  const [workItemList, setWorkItemList] = useState([]);
  const [workItemListCompressed, setWorkItemListCompressed] = useState([]);

  const [PNLdata, setPNLdata] = useState([]);
  const [label, setLabel] = useState();
  const [barcode, setBarcode] = useState();
  const [language, setLangauge] = useState("NL");

  useEffect(() => {
    if (authObject.organisation) {
      getWebShopList(authObject.organisation.id);
    }
  }, [authObject.organisation]);

  let getWebShopList = async (orgid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/webshop/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orgid: orgid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWebShopList(data);
    } else {
      console.log("no shoplist");
    }
  };

  let getFromToOrders = async (webshopid, from, to) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/fromto/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          from: from,
          to: to,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getFromToItems = async (webshopid, from, to) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items/fromto/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          from: from,
          to: to,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkItemList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getFilteredOrderList = async (webshopid, filter) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/filtered/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          filter: filter,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getOpenOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/open/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          sortASC: sortASC,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getUnFinishedOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          sortASC: sortASC,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getOpenShipOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/shipopen/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          sortASC: sortASC,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getOpenPOSOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/posopen/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          sortASC: sortASC,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getUnpaidOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/unpaid/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          sortASC: sortASC,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getShippedOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/shipped/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          sortASC: sortASC,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getAllOrderList = async (webshopid, sortASC) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/orders/all/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        sortASC: sortASC,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkOrderList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let updateOrderPaid = async (orderid, status) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/paid/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
          status: status,
        }),
      }
    );
    if (resp.status === 200) {
      setOrderUpdate(!orderUpdate);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let updateOrderOnHold = async (orderid, status) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/onhold/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
          status: status,
        }),
      }
    );
    if (resp.status === 200) {
      setOrderUpdate(!orderUpdate);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let updateOrderCancel = async (orderid, status) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/cancel/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
          status: status,
        }),
      }
    );
    if (resp.status === 200) {
      setOrderUpdate(!orderUpdate);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let updateOrderReady = async (orderid, status) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/ready/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
          status: status,
        }),
      }
    );
    if (resp.status === 200) {
      setOrderUpdate(!orderUpdate);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let updateOrderShipped = async (orderid, status) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/shipped/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
          status: status,
        }),
      }
    );
    if (resp.status === 200) {
      setOrderUpdate(!orderUpdate);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let declareForShipping = async (orderid, barcode, label) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/declare/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
          barcode: barcode,
          label: label,
        }),
      }
    );
    if (resp.status === 200) {
      setOrderUpdate(!orderUpdate);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let createOrder = async () => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/create/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
      }
    );
    if (resp.status === 200) {
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let updateOrder = async (order) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/update/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(order),
      }
    );
    if (resp.status === 200) {
      // let data = await resp.json();
      // setWebShopList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let deleteOrder = async (orderid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/order/delete/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          orderid: orderid,
        }),
      }
    );
    if (resp.status === 200) {
      // let data = await resp.json();
      // setWebShopList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getAllItems = async (webshopid) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/items/all/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkItemList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getUnfinishedItems = async (webshopid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkItemList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getUnfinishedItemsCompressed = async (webshopid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      let ulist = [];
      data.map((item, index) => {
        let optionlist = [];
        if (
          ulist.filter(
            (ulitem) =>
              ulitem.product === item.product && ulitem.weight === item.weight
          ).length > 0
        ) {
          let index = ulist.findIndex(
            (ulitem) =>
              ulitem.product === item.product && ulitem.weight === item.weight
          );
          let existingitem = ulist[index];
          existingitem.quantity = existingitem.quantity + item.quantity;
          optionlist = [item.options];
          existingitem.options.push(optionlist);
          optionlist = [];
        } else {
          ulist.push(item);
          optionlist = [];
        }
      });
      setWorkItemListCompressed(ulist);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  let getUnfinishedBookings = async (webshopid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/bookings/unfinished/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setWorkBookingList(data);
    } else {
      console.log("something went wrong checking your details");
    }
  };

  const shipOrder = async (order, customer, pack, shopoptions) => {
    let url = "";
    let apikey = "";
    let orgaddid = authObject.organisation.corr_address;
    let orgadd = authObject.addresslist.filter((add) => add.id === orgaddid)[0];
    let organisation = authObject.organisation;
    let user = authObject.user;
    let d = new Date();
    let datetimestamp =
      d.getDate() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes() +
      ":" +
      d.getSeconds();

    if (shopoptions.en_postnl_sandbox) {
      url = "https://api-sandbox.postnl.nl/v1/shipment";
      apikey = authObject.organisation.api_pnl_sandbox;
    } else {
      url = "https://api.postnl.nl/v1/shipment";
      apikey = authObject.organisation.api_pnl_real;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: apikey,
      },
      body: JSON.stringify({
        Customer: {
          Address: {
            AddressType: "02",
            CompanyName: organisation.name,
            Street: orgadd.street,
            HouseNr: orgadd.hnr,
            HouseNrExt: orgadd.hnrext,
            Zipcode: orgadd.zip,
            City: orgadd.city,
            Countrycode: orgadd.country,
          },
          CollectionLocation: organisation.pnl_collectionlocation,
          CustomerCode: organisation.pnl_customercode,
          CustomerNumber: organisation.pnl_customernumber,
          ContactPerson: user.firstname + " " + user.name,
          Email: user.email,
          Name: user.phone,
        },
        Message: {
          MessageID: "1",
          MessageTimeStamp: datetimestamp,
          Printertype: "GraphicFile|PDF",
        },
        Shipments: [
          {
            Addresses: [
              {
                AddressType: "01",
                City: order.ship_city,
                Countrycode: order.ship_country,
                FirstName: order.ship_firstname,
                Name: order.ship_name,
                HouseNr: order.ship_hnr,
                HouseNrExt: order.ship_hnrext,
                Street: order.ship_street,
                Zipcode: order.ship_zip,
              },
            ],
            Contacts: [
              {
                ContactType: "01",
                Email: customer.email,
                SMSNr: customer.phone,
                TelNr: customer.phone,
              },
            ],
            Dimension: {
              Weight: pack.weight, // might need additional input when declaring shipment
              Length: pack.length,
              Width: pack.width,
              Height: pack.height,
            },
            ProductCodeDelivery: "4940",
            Reference: order.ref,
          },
        ],
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setPNLdata(resp);
        declareForShipping(
          order.id,
          resp.ResponseShipments[0].Barcode,
          resp.ResponseShipments[0].Labels[0].Content
        );
      });
  };

  let context = {
    webshoplist: webShopList,
    webshop: webShop,
    setwebshop: setWebShop,
    getwebshoplist: getWebShopList,

    workorderlist: workOrderList,
    workorder: workOrder,
    setworkorder: setWorkOrder,

    orderupdate: orderUpdate,
    setorderupdate: setOrderUpdate,

    getfromtoorders: getFromToOrders,
    getfromtoitems: getFromToItems,

    getunfinishedorderlist: getUnFinishedOrderList,
    getopenorderlist: getOpenOrderList,
    getopenposorderlist: getOpenPOSOrderList,
    getopenshiporderlist: getOpenShipOrderList,
    getunpaidorderlist: getUnpaidOrderList,
    getshippedorderlist: getShippedOrderList,
    getallorderlist: getAllOrderList,
    getfilteredorderlist: getFilteredOrderList,
    updateorderpaid: updateOrderPaid,
    updateorderonhold: updateOrderOnHold,
    updateordercancel: updateOrderCancel,
    updateorderready: updateOrderReady,
    updateordershipped: updateOrderShipped,
    declareforshipping: declareForShipping,
    createorder: createOrder,
    updateorder: updateOrder,
    deleteorder: deleteOrder,
    workitemlist: workItemList,
    setworkitemlist: setWorkItemList,
    workitemlistcompressed: workItemListCompressed,
    setworkitemlistcompressed: setWorkItemListCompressed,
    getallitems: getAllItems,
    getunfinisheditems: getUnfinishedItems,
    getunfinisheditemscompressed: getUnfinishedItemsCompressed,

    label: label,
    pnldata: PNLdata,
    barcode: barcode,
    shiporder: shipOrder,

    workbookinglist: workBookingList,
    setworkbookinglist: setWorkBookingList,

    getunfinishedbookings: getUnfinishedBookings,

    language: language,
    setlanguage: setLangauge,
  };

  return (
    <ControlContext.Provider value={context}>
      {props.children}
    </ControlContext.Provider>
  );
}

export default ControlContext;
