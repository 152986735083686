import React, { useState } from "react";
import EditOrg from "../Components/EditOrg";
import ShowOrg from "../Components/ShowOrg";

const UserOrgController = ({ organisation }) => {
  const [editOrg, setEditOrg] = useState(false);
  return (
    <div>
      {organisation && (
        <>
          {!editOrg && (
            <ShowOrg organisation={organisation} settoggler={setEditOrg} />
          )}
          {editOrg && (
            <EditOrg organisation={organisation} settoggler={setEditOrg} />
          )}
        </>
      )}
    </div>
  );
};

export default UserOrgController;
