import React, { useState, useEffect, useContext, Fragment } from "react";
import ShopContext from "../Store/shop-context";

import AddCat from "../Components/AddCat";
import EditCat from "../Components/EditCat";
import DeleteCat from "../Components/DeleteCat";
import ShowCat from "../Components/ShowCat";
import SubCategoryList from "../Components/SubCategoryList";

const CategoryList = () => {
  const shopObject = useContext(ShopContext);

  const [clickedAdd, setClickedAdd] = useState(null);
  const [clickedEdit, setClickedEdit] = useState(null);
  const [clickedDelete, setClickedDelete] = useState(null);

  const clickEditCat = (cat) => {
    setClickedEdit(cat.id);
  };

  const clickDeleteCat = (cat) => {
    shopObject.setcategory(cat);
    setClickedDelete(cat.id);
  };

  const clickAddCat = () => {
    setClickedAdd(true);
  };

  return (
    <div>
      <div className="scline">
        <div className="scsubtitle"> List of categories</div>
        <button
          className="btn btn-success btn-sm scaddbutton"
          onClick={(e) => clickAddCat()}
        >
          Add
        </button>
      </div>
      {clickedAdd && <AddCat toggler={clickedAdd} setToggler={setClickedAdd} />}
      {shopObject.categories.map((cat, index) => {
        return (
          <Fragment key={index}>
            {clickedEdit === cat.id && (
              <EditCat
                category={cat}
                toggler={clickedEdit}
                setToggler={setClickedEdit}
              />
            )}
            {clickedDelete === cat.id && (
              <DeleteCat
                category={cat}
                toggler={clickedDelete}
                setToggler={setClickedDelete}
              />
            )}
            {clickedEdit != cat.id && clickedDelete != cat.id && (
              <ShowCat
                cat={cat}
                clickEdit={clickEditCat}
                clickDelete={clickDeleteCat}
              />
            )}
            <SubCategoryList cat={cat} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default CategoryList;
