import React, { useState, useContext } from "react";
import { blankProductSettings } from "../Data/data";
import ControlContext from "../Store/control-context";
import Select from "react-select";
import DayTimeInput from "./DayTimeInput";

const AddProductSetting = ({ toggler, setToggler, reload, setReload }) => {
  const controlObject = useContext(ControlContext);
  const [prodSet, setProdSet] = useState(blankProductSettings);
  const [changed, setChanged] = useState(false);

  let createProductSettings = async (prodset) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/settings/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(prodset),
      }
    );
    if (resp.status === 200) {
      console.log("Productsettings created");
      setReload(!reload);
    } else {
      alert("something went wrong checking your details");
    }
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setProdSet({ ...prodSet, [name]: value });
  }

  const handleCreate = (e) => {
    prodSet["webshop"] = controlObject.webshop.id;
    if (prodSet.orderdeadline === "") {
      prodSet.orderdeadline = null;
    }
    if (prodSet.orderdelivery === "") {
      prodSet.orderdelivery = null;
    }
    createProductSettings(prodSet);
    setToggler(null);
  };

  const handleCancel = (e) => {
    setToggler(null);
  };

  return (
    <tr>
      <td>
        <input
          required
          className="form-control"
          placeholder="name"
          type="text"
          id="name"
          name="name"
          value={prodSet.name}
          onChange={handleChange}
        ></input>
      </td>
      <td>
        {!changed && (
          <input
            className="form-control"
            placeholder="leadtime"
            type="integer"
            id="leadtime"
            name="leadtime"
            value={prodSet.leadtime}
            onChange={handleChange}
          />
        )}
      </td>
      <td>
        {!prodSet.leadtime && (
          <>
            <DayTimeInput
              name="orderdeadline"
              prodSet={prodSet}
              setProdSet={setProdSet}
              setChanged={setChanged}
            />
          </>
        )}
      </td>
      <td>
        {!prodSet.leadtime && (
          <DayTimeInput
            name="orderdelivery"
            prodSet={prodSet}
            setProdSet={setProdSet}
            setChanged={setChanged}
          />
        )}
      </td>
      <td>
        <button
          className="btn btn-success btn-sm"
          onClick={() => {
            handleCreate();
          }}
        >
          Add
        </button>
        <button
          className="btn btn-secondary btn-sm ms-2"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default AddProductSetting;
