import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import ControlContext from "../Store/control-context";
import AccountListPerDay from "../Components/AccountListPerDay";
import { CSVLink } from "react-csv";

const AccountingPage = () => {
  const controlObject = useContext(ControlContext);

  const [fromDate, setFromDate] = useState("2022-10-01");
  const [toDate, setToDate] = useState("2022-10-31");

  const [orderList, setOrderList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [loopDates, setLoopDates] = useState([]);
  const [receivables, setReceivables] = useState([]);

  const [orderToggler, setOrderToggler] = useState(false);
  const [itemToggler, setItemToggler] = useState(false);

  let getFromToOrders = async (webshopid, from, to) => {
    from = new Date(from);
    to = new Date(to);
    var newto = new Date(to);
    newto.setDate(to.getDate() + 1);
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/orders/fromto/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          from: from,
          to: newto,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setOrderList(data);
      setOrderToggler(!orderToggler);
    } else {
      alert("something went wrong");
    }
  };

  let getFromToItems = async (webshopid, from, to) => {
    from = new Date(from);
    to = new Date(to);
    var newto = new Date(to);
    newto.setDate(to.getDate() + 2);
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/items/fromto/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          from: from,
          to: newto,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setItemList(data);
      setItemToggler(!itemToggler);
    } else {
      alert("something went wrong");
    }
  };

  function handleFromDate(e) {
    const value = e.target.value;
    setFromDate(value);
  }

  function handleToDate(e) {
    const value = e.target.value;
    setToDate(value);
  }

  function retrieveOrdersAndItems() {
    setOrderList([]);
    setItemList([]);
    getFromToOrders(controlObject.webshop.id, fromDate, toDate);
    getFromToItems(controlObject.webshop.id, fromDate, toDate);
    looper(fromDate, toDate);
  }

  function looper(fromDate, toDate) {
    let start = new Date(fromDate);
    let end = new Date(toDate);
    end = end.setHours(0);
    let loop = new Date(start);
    let dateArray = [start];
    while (loop <= end) {
      dateArray.push(loop);
      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
    setLoopDates(dateArray);
  }

  return (
    <div>
      <Helmet>
        <title>Ubicopia®-User-Accounting</title>
        <meta name="description" content="Accountingpage" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="acpage">
        <div className="actitle">Accounting</div>
        {controlObject.webshop.ref === "" && (
          <>You need to select a webshop from the list</>
        )}
        {controlObject.webshop.ref !== "" && (
          <>
            <div className="acsubtitle">Receivables</div>
            <div className="acline">
              <div className="acblock">
                From
                <input
                  required
                  className="acinputfield"
                  placeholder="date"
                  type="date"
                  id="fromdate"
                  name="fromdate"
                  value={fromDate}
                  onChange={handleFromDate}
                ></input>
              </div>
              <div className="acblock">
                To
                <input
                  required
                  className="acinputfield"
                  placeholder="date"
                  type="date"
                  id="fromdate"
                  name="fromdate"
                  value={toDate}
                  onChange={handleToDate}
                ></input>
              </div>
              <button
                className="btn btn-primary btn-sm acblock"
                onClick={() => retrieveOrdersAndItems()}
              >
                Run
              </button>
              <small>
                <CSVLink
                  data={receivables}
                  filename={"receivables.csv"}
                  className="btn btn-primary btn-sm"
                  target="_blank"
                >
                  Download CSV table
                </CSVLink>
              </small>
            </div>
            {loopDates && itemList.length > 0 && orderList.length > 0 && (
              <AccountListPerDay
                listOrders={orderList}
                listItems={itemList}
                loopDates={loopDates}
                receivables={receivables}
                setReceivables={setReceivables}
              />
            )}
            <div className=""></div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountingPage;
