import React, { useState, useContext, useEffect } from "react";
import ProductOption from "./ProductOption";

import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

import SOCheckbox from "./SOCheckbox";
import Select from "react-select";

import { productvariants } from "../Data/data";

const EditProduct = ({ product, toggler, setToggler, reset, setReset }) => {
  const shopObject = useContext(ShopContext);
  const controlObject = useContext(ControlContext);
  const [prod, setProd] = useState(product);
  const [subCats, setSubCats] = useState(
    shopObject.subcategories.filter((sc) => sc.category === product.category)
  );
  const [productSettings, setProductSettings] = useState(
    shopObject.productsettings
  );
  var [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [workingOptions, setWorkingOptions] = useState(false);

  const styles = {
    borderStyle: prod.active ? "solid" : "none",
  };

  useEffect(() => {
    options = [];
    if (shopObject.productsettings && shopObject.productsettings.length > 0) {
      shopObject.productsettings.map((psetting) => {
        let value = psetting.id.toString();
        let label = psetting.name;
        options.push({ ["value"]: value, ["label"]: label });
      });
      setOptions(options);
    }
  }, []);

  useEffect(() => {
    let list = [];
    if (product.settings && product.settings.length > 0) {
      product.settings.map((ps) => {
        let selectedsetting = options.filter(
          (op) => op.value === ps.toString()
        )[0];
        list.push(selectedsetting);
      });
      setSelectedOptions(list);
    }
  }, [product && options.length > 0]);

  useEffect(() => {
    setSubCats(
      shopObject.subcategories.filter(
        (sc) => sc.category === parseInt(prod.category)
      )
    );
  }, [prod.category]);

  function handler(e) {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "promotion") {
      value = !prod.promotion;
    }
    setProd({ ...prod, [name]: value });
  }

  function settingshandler(data) {
    setSelectedOptions(data);
  }

  function toggleHandler(name, option) {
    let value = !option;
    setProd({ ...prod, [name]: value });
  }

  const imageHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.files[0];
    setProd({ ...prod, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const newProduct = new FormData();
    newProduct.append("id", prod.id);
    newProduct.append("name", prod.name);
    newProduct.append("active", prod.active);
    newProduct.append("varianttype", prod.varianttype);
    newProduct.append("sku", prod.sku);
    if (prod.picture !== product.picture) {
      newProduct.append("picture", prod.picture);
    }
    if (prod.picturelarge1 !== product.picturelarge1) {
      newProduct.append("picturelarge1", prod.picturelarge1);
    }
    if (prod.picturelarge2 !== product.picturelarge2) {
      newProduct.append("picturelarge1", prod.picturelarge2);
    }
    if (prod.picturelarge3 !== product.picturelarge3) {
      newProduct.append("picturelarge1", prod.picturelarge3);
    }

    newProduct.append("description", prod.description);
    newProduct.append("fulldescription", prod.fulldescription);
    newProduct.append("price", prod.price);
    newProduct.append("per", prod.per);
    newProduct.append("vat", prod.vat);
    newProduct.append("promotion", prod.promotion);
    if (prod.startquantity === null) {
      prod.startquantity = 0;
    }
    if (prod.incrementalquantity === null) {
      prod.incrementalquantity = 0;
    }
    newProduct.append("startquantity", prod.startquantity);
    newProduct.append("incrementalquantity", prod.incrementalquantity);

    if (prod.stock !== null) {
      newProduct.append("stock", prod.stock);
    }
    newProduct.append("category", parseInt(prod.category));
    if (prod.subcategory === "" || prod.subcategory === null) {
      newProduct.append("subcategory", "");
    } else {
      newProduct.append("subcategory", parseInt(prod.subcategory));
    }

    newProduct.append("webshop", controlObject.webshop.id);

    // if (prod.settings === null) {
    //   prod.settings = "";
    // }
    // newProduct.append("settings", prod.settings);

    // ------ composing productsettings from selectedoptions ------
    prod.settings = [];
    if (selectedOptions.length > 0) {
      selectedOptions.map((seloption) => {
        newProduct.append("settings", seloption.value);
      });
    } else {
      // newProduct.append("settings", prod.settings);
    }

    console.log("old product :", product);
    console.log("new product", prod);
    shopObject.updateproductwimage(newProduct);
    setToggler(null);
    setReset(!reset);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  const updatePOS = (prod) => {
    const newProduct = new FormData();
    newProduct.append("id", prod.id);
    newProduct.append("position", prod.position);
    newProduct.append("webshop", controlObject.webshop.id);
    shopObject.updateproductwimage(newProduct);
  };

  return (
    <div>
      <div className="card-input" style={styles}>
        <div className="w-100 mx-auto ms-5 fs-5">
          <SOCheckbox
            title="active"
            name="active"
            value={prod.active}
            handler={toggleHandler}
          />
        </div>

        <div className="cardimagebox">
          <img
            src={product.picture}
            className="cardimage"
            alt={product.title}
          ></img>
        </div>
        <div className="card-body">
          <input
            className="card-file-input"
            placeholder="product picture"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            id="picture"
            name="picture"
            onChange={imageHandler}
          ></input>
          {prod.promotion && (
            <div className="mt-3 me-3">
              Promotion
              <input
                type="checkbox"
                id="checkpromo"
                name="promotion"
                value={prod.promotion}
                onChange={handler}
                checked
              ></input>
            </div>
          )}
          {!prod.promotion && (
            <div className="mt-3 mb-3">
              Promotion
              <input
                className="ms-2"
                type="checkbox"
                id="checkpromo"
                name="promotion"
                value={prod.promotion}
                onChange={handler}
              ></input>
            </div>
          )}
          <input
            className="card-title-input mb-3"
            placeholder="product name"
            type="text"
            id="name"
            name="name"
            value={prod.name}
            onChange={handler}
          ></input>
          <textarea
            className="card-description-input mb-3"
            placeholder="product description"
            type="text"
            id="description"
            name="description"
            value={prod.description}
            onChange={handler}
          ></textarea>
          <div className="row mt-1">
            <div className="col-4">SKU</div>
            <input
              className="card-sku-input col-7"
              placeholder="product SKU"
              type="text"
              id="sku"
              name="sku"
              value={prod.sku}
              onChange={handler}
            ></input>
          </div>
          <div className="row mt-1">
            <div className="col-4">
              <small>Category</small>
            </div>
            <select
              className="card-cat-input col-7"
              placeholder="category"
              type="text"
              required
              id="category"
              name="category"
              value={prod.category}
              onChange={handler}
            >
              {shopObject.categories &&
                shopObject.categories.map((cat, index) => {
                  return (
                    <option name="category" value={cat.id} key={index}>
                      {cat.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="row mt-1">
            <div className="col-4">
              <small>Subcategory</small>
            </div>
            <select
              className="card-cat-input col-7"
              placeholder="subcategory"
              type="text"
              required
              id="subcategory"
              name="subcategory"
              value={prod.subcategory}
              onChange={handler}
            >
              <option name="subcategory" value="">
                ------------
              </option>
              {subCats &&
                subCats.map((subCat, index) => {
                  return (
                    <option name="subcategory" value={subCat.id} key={index}>
                      {subCat.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="card-settings d-flex flex-row align-items-center mt-1">
            <div className="me-2">
              <small>Settings</small>
            </div>
            <Select
              className="card-settings-input"
              placeholder="settings"
              options={options}
              noOptionsMessage="geen"
              value={selectedOptions}
              onChange={settingshandler}
              isMulti
            />
          </div>
          <div className="row mt-1">
            <div className="col-4">
              <small>Variant</small>
            </div>
            <select
              className="card-cat-input col-7"
              type="text"
              required
              id="varianttype"
              name="varianttype"
              value={prod.varianttype}
              onChange={handler}
            >
              {productvariants &&
                productvariants.map((productvariant, index) => {
                  return (
                    <option
                      name="varianttype"
                      value={productvariant.code}
                      key={index}
                    >
                      {productvariant.name}
                    </option>
                  );
                })}
            </select>
          </div>
          {prod.varianttype === "standard" && (
            <>
              <div className="text-center fw-bold border-top my-3 border-bottom ">
                standaard ingave
              </div>
              <div className="row">
                <div className="col-6 ms-3">
                  Price{" "}
                  <strong>{(prod.price * (1 + prod.vat)).toFixed(2)}</strong>{" "}
                </div>
                <input
                  className="card-per-input col-4"
                  placeholder="per"
                  type="text"
                  id="per"
                  name="per"
                  value={prod.per}
                  onChange={handler}
                ></input>
              </div>
              <div className="row ms-3 mt-3">
                <div className="col-4">Price</div>
                <div className="col-2"> x</div> <div className="col-4">VAT</div>
              </div>
              <div className="row ms-3">
                <input
                  className="col-4"
                  placeholder="product price"
                  type="text"
                  id="price"
                  name="price"
                  value={prod.price}
                  onChange={handler}
                />
                <div className="col-2">x</div>
                <input
                  className="col-4"
                  placeholder="product vat"
                  type="text"
                  id="vat"
                  name="vat"
                  value={prod.vat}
                  onChange={handler}
                />
              </div>
            </>
          )}
          {prod.varianttype === "cuttoweight" && (
            <>
              <div className="text-center fw-bold border-top my-3 border-bottom ">
                Prijsingave voor versneden product
              </div>
              <div className="row">
                <div className="col-6 ms-3">
                  Price{" "}
                  <strong>{(prod.price * (1 + prod.vat)).toFixed(2)}</strong>{" "}
                </div>
                <input
                  className="card-per-input col-4"
                  placeholder="/kg"
                  type="text"
                  id="per"
                  name="per"
                  value={prod.per}
                  default="/kg"
                  onChange={handler}
                ></input>
              </div>
              <div className="row ms-3 mt-3">
                <div className="col-4">Price</div>
                <div className="col-2"> x</div> <div className="col-4">VAT</div>
              </div>
              <div className="row ms-3">
                <input
                  className="col-4"
                  placeholder="product price"
                  type="text"
                  id="price"
                  name="price"
                  value={prod.price}
                  onChange={handler}
                />
                <div className="col-2">x</div>
                <input
                  className="col-4"
                  placeholder="product vat"
                  type="text"
                  id="vat"
                  name="vat"
                  value={prod.vat}
                  onChange={handler}
                />
              </div>
              <div className="row ms-3 mt-3">
                <div className="col-4">
                  <div>Start- hoeveelheid</div>
                  <div>(gram)</div>
                </div>
                <div className="col-2">+/-</div>{" "}
                <div className="col-4">increment</div>
              </div>
              <div className="row ms-3">
                <input
                  className="col-4"
                  placeholder="starthoeveelheid"
                  type="text"
                  id="startquantity"
                  name="startquantity"
                  value={prod.startquantity}
                  onChange={handler}
                />
                <div className="col-2">+/-</div>
                <input
                  className="col-4"
                  placeholder="hoeveelheid stap"
                  type="text"
                  id="incrementalquantity"
                  name="incrementalquantity"
                  value={prod.incrementalquantity}
                  onChange={handler}
                />
              </div>
            </>
          )}
          <ProductOption
            productid={product.id}
            webshopid={controlObject.webshop.id}
            productOptions={options}
            setWorkingOptions={setWorkingOptions}
          />
          {!workingOptions && (
            <>
              <div className="scbuttongroup">
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={handleCancel}
                >
                  cancel
                </button>
                <button
                  className="btn btn-success btn-sm"
                  onClick={handleUpdate}
                >
                  save
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
