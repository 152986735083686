import React, { useState, useContext, useEffect, Fragment } from "react";
import ControlContext from "../Store/control-context";
import AuthContext from "../Store/auth-context";
import ShopContext from "../Store/shop-context";
import getNormDate from "../Functions/getNormDate";

const OrderDetail = ({ order, setToggler }) => {
  const controlObject = useContext(ControlContext);
  const authObject = useContext(AuthContext);
  const shopObject = useContext(ShopContext);

  const [orderItems, setOrderItems] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [oldOrder, setOldOrder] = useState();
  const [detOrder, setDetOrder] = useState(order);
  const [customer, setCustomer] = useState();
  const [shipment, setShipment] = useState();
  const [cancelOrder, setCancelOrder] = useState(false);
  const [pack, setPack] = useState({
    weight: "1000",
    length: "500",
    width: "500",
    height: "500",
  });
  const [showPackForm, setShowPackForm] = useState(false);

  let getOrderItems = async (webshopid, orderid) => {
    let url = process.env.REACT_APP_API_URL + "capi/order/items/";
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        orderid: orderid,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setOrderItems(data);
      getOrderOptions(webshopid, orderid);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  let getOrderOptions = async (webshopid, orderid) => {
    let url = process.env.REACT_APP_API_URL + "capi/order/options/";
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        orderid: orderid,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setOrderOptions(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  let getCustomerDetails = async (webshopid, customerid) => {
    let url = process.env.REACT_APP_API_URL + "capi/customer/details/";
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        userid: customerid,
      }),
    });
    if (resp.status === 200) {
      let data = await resp.json();
      setCustomer(data);
    } else {
      alert("something went wrong checking your details");
      console.log("resp :", resp);
    }
  };

  useEffect(() => {
    getOrderItems(controlObject.webshop.id, order.id);
    getCustomerDetails(controlObject.webshop.id, order.user);
  }, []);

  const declareForShipping = async () => {
    controlObject.shiporder(order, customer, pack, shopObject.shopoptions);
    order = { ...order, ["shiplabel"]: null };
    setToggler(null);
  };

  const printLabel = async () => {
    // <a href={detOrder.shiplabel} target="_blank"></a>
    window.open.location.href = detOrder.shiplabel;
  };

  const confirmPaid = () => {
    let today = new Date();
    order = { ...order, ["paid"]: today };
    setDetOrder(order);
    controlObject.updateorderpaid(order.id, true);
  };

  const unConfirmPaid = () => {
    order = { ...order, ["paid"]: null };
    setDetOrder(order);
    controlObject.updateorderpaid(order.id, false);
  };

  const confirmReady = () => {
    let today = new Date();
    order = { ...order, ["readyforship"]: today };
    setDetOrder(order);
    controlObject.updateorderready(order.id, true);
  };

  const unConfirmReady = () => {
    order = { ...order, ["readyforship"]: null };
    setDetOrder(order);
    controlObject.updateorderready(order.id, false);
  };

  const putOnHold = () => {
    order = { ...order, ["onhold"]: true };
    setDetOrder(order);
    controlObject.updateorderonhold(order.id, true);
  };

  const release = () => {
    order = { ...order, ["onhold"]: false };
    setDetOrder(order);
    controlObject.updateorderonhold(order.id, false);
  };

  const confirmShipped = () => {
    let today = new Date();
    order = { ...order, ["shipped"]: today };
    setDetOrder(order);
    controlObject.updateordershipped(order.id, true);
  };
  const unConfirmShipped = () => {
    order = { ...order, ["shipped"]: null };
    setDetOrder(order);
    controlObject.updateordershipped(order.id, false);
  };

  const cancelTheOrder = () => {
    order = { ...order, ["canceled"]: true };
    setDetOrder(order);
    controlObject.updateordercancel(order.id, true);
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setPack({ ...pack, [fieldname]: value });
  };

  return (
    <div className="itempopup p-3">
      {customer && (
        <div className="row fs-7 bg-dark text-white">
          <div className="col-2">Customer :</div>
          <div className="col-4">
            {customer.firstname} {customer.name}
          </div>
          <a href={`mailto:${customer.email}`} className="col-3">
            {customer.email}
          </a>
          <a href={`tel:${customer.phone}`} className="col-3">
            {customer.phone}
          </a>
        </div>
      )}
      <div className="itempopuprow">
        <div className="itempopupleft">
          {detOrder && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Orderdetails</th>
                  <th scope="col">Orderdate</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Total</th>
                  <th scope="col">Booked for</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{detOrder.ref}</td>
                  <td>{getNormDate(detOrder.ordered)}</td>
                  <td>
                    {detOrder.bill_firstname} {detOrder.bill_name}
                  </td>
                  <td>
                    {(detOrder.totalprice + detOrder.totalvat).toFixed(2)}
                  </td>
                  <td>{getNormDate(detOrder.bookedpickup)}</td>
                </tr>
                {detOrder.barcode && (
                  <tr>
                    <td>Barcode: {detOrder.barcode}</td>
                    <td>{detOrder.shiplabel && <>Label available</>}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="col-2" scope="col">
                  Name
                </th>
                <th className="col-1" scope="col">
                  SKU
                </th>
                <th className="col-3" scope="col">
                  Qty
                </th>
                <th className="col-3" scope="col">
                  Description
                </th>
                <th className="col-1" scope="col">
                  Price
                </th>
                <th className="col-1" scope="col">
                  SubTotal
                </th>
              </tr>
            </thead>
            <tbody>
              {orderItems &&
                orderItems.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <tr className="">
                        <td>{item.name}</td>
                        <td>{item.sku}</td>
                        <td>
                          {item.quantity}
                          {item.varianttype === "cuttoweight" ? (
                            <>x{item.weight} g</>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>{item.description.slice(0, 20)}</td>
                        <td>
                          {(
                            item.cartitemprice *
                            (1 + item.cartitemvat)
                          ).toFixed(2)}
                        </td>
                        <td>
                          {(
                            item.cartitemtotal *
                            (1 + item.cartitemvat)
                          ).toFixed(2)}
                        </td>
                      </tr>
                      {orderOptions && item.options.length > 0 && (
                        <>
                          <td colSpan="5">
                            <table className="table table-sm">
                              <tbody>
                                <tr>
                                  <td className="">Keuze-opties :</td>
                                  <td colSpan="4">
                                    {item.options.map((option, index) => {
                                      return (
                                        <Fragment key={index}>
                                          {index > 0 && <>{", "}</>}
                                          {orderOptions.filter(
                                            (orderoption) =>
                                              orderoption.id === option
                                          )[0] && (
                                            <>
                                              {
                                                orderOptions.filter(
                                                  (orderoption) =>
                                                    orderoption.id === option
                                                )[0].description
                                              }
                                            </>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </>
                      )}
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
          {detOrder.remarks && (
            <div className="w-100 mb-1">
              <div>
                <strong>Remarks</strong>
              </div>
              <div>{detOrder.remarks}</div>
            </div>
          )}
          {(detOrder.confirmation || detOrder.invoice) && (
            <>
              <div className="row mb-2 w-50">
                <div className="col">Documenten</div>
                <div className="col">
                  {detOrder.confirmation && (
                    <>
                      <a
                        href={detOrder.confirmation}
                        target="_blank"
                        className="link-primary"
                      >
                        Orderconfirmation .pdf
                      </a>
                    </>
                  )}
                </div>
                <div className="col">
                  {detOrder.invoice && (
                    <>
                      <a
                        href={detOrder.invoice}
                        target="_blank"
                        className="link-primary"
                      >
                        Invoice .pdf
                      </a>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="itempopupright">
          {detOrder && (
            <>
              {detOrder.paid &&
                (detOrder.paymethod === "T" ||
                  detOrder.paymethod === "O" ||
                  detOrder.paymethod === "P") && (
                  <button
                    className="greenbutton"
                    onClick={(e) => unConfirmPaid()}
                  >
                    Paid to Unpaid
                  </button>
                )}
              {detOrder.paymethod === null && (
                <button className="orangebutton bg-gray text-white" disabled>
                  No Paymethod
                </button>
              )}
              {!detOrder.paid &&
                (detOrder.paymethod === "T" || detOrder.paymethod === "O") && (
                  <button className="redbutton" onClick={(e) => confirmPaid()}>
                    Unpaid to Paid
                  </button>
                )}
              {!detOrder.paid && detOrder.paymethod === "P" && (
                <button className="orangebutton" onClick={(e) => confirmPaid()}>
                  Pickup payment to Paid
                </button>
              )}

              {detOrder.onhold && (
                <button className="redbutton" onClick={(e) => release()}>
                  On hold to Release
                </button>
              )}

              {!detOrder.onhold && (
                <button className="greenbutton" onClick={(e) => putOnHold()}>
                  Put on HOLD
                </button>
              )}

              {detOrder.readyforship && (
                <button
                  className="greenbutton"
                  onClick={(e) => unConfirmReady()}
                >
                  Ready to ship/Pickup
                </button>
              )}
              {!detOrder.readyforship && (
                <button className="redbutton" onClick={(e) => confirmReady()}>
                  Not Ready to ship
                </button>
              )}
              {!detOrder.shipped &&
                detOrder.shipmethod == "M" &&
                !detOrder.barcode && (
                  <button
                    className="redbutton"
                    onClick={(e) => setShowPackForm(true)}
                  >
                    Declare for shipping
                  </button>
                )}
              {showPackForm && (
                <>
                  <form onSubmit={() => declareForShipping()}>
                    <div className="form-group">
                      <label className="">weight</label>
                      <input
                        className=""
                        type="text"
                        placeholder="weight in grams"
                        name="weight"
                        value={pack.weight}
                        onChange={handler}
                      />
                    </div>
                    <div className="form-group">
                      <label className="">length mm longest</label>
                      <input
                        className=""
                        type="text"
                        placeholder="length in mm"
                        name="length"
                        value={pack.length}
                        onChange={handler}
                      />
                    </div>
                    <div className="form-group">
                      <label className="">width mm second longest</label>
                      <input
                        className=""
                        type="text"
                        placeholder="width in mm"
                        name="width"
                        value={pack.width}
                        onChange={handler}
                      />
                    </div>
                    <div className="form-group">
                      <label className="">height mm shortest</label>
                      <input
                        className=""
                        type="text"
                        placeholder="height in mm"
                        name="height"
                        value={pack.height}
                        onChange={handler}
                      />
                    </div>
                    <div className="row mt-2 mb-3">
                      <button
                        type="submit"
                        className="col-4 btn btn-success btn-sm ms-4"
                      >
                        Go
                      </button>
                      <button
                        type="button"
                        className="col-4 btn-btn-secondary btn-sm ms-2"
                        onClick={() => setShowPackForm(false)}
                      >
                        cancel
                      </button>
                    </div>
                  </form>
                </>
              )}

              {!detOrder.shipped &&
                detOrder.shipmethod == "M" &&
                detOrder.shiplabel && (
                  <a href={detOrder.shiplabel} target="_blank" rel="noreferrer">
                    <button className="redbutton">Print Label</button>
                  </a>
                )}

              {detOrder.shipped && (
                <button
                  className="greenbutton"
                  onClick={(e) => unConfirmShipped()}
                >
                  Shipped
                </button>
              )}
              {!detOrder.shipped && (
                <button className="redbutton" onClick={(e) => confirmShipped()}>
                  Not Shipped/Picked up
                </button>
              )}
              {cancelOrder === false && (
                <>
                  <button
                    className="redbutton mt-5"
                    onClick={(e) => setCancelOrder(true)}
                  >
                    Cancel Order
                  </button>
                </>
              )}
              {cancelOrder === true && (
                <>
                  <button
                    className="redbutton mt-5 border border-dark border-2"
                    onClick={(e) => cancelTheOrder()}
                  >
                    Confirm cancel order
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="itempopuprow">
        <button
          className="btn btn-secondary btn-sm "
          onClick={(e) => {
            setOrderItems([]);
            setToggler(null);
          }}
        >
          Back
        </button>
        <button
          className="btn btn-primary btn-sm"
          onClick={(e) => {
            setOrderItems([]);
            setToggler(null);
          }}
        >
          Save & Exit
        </button>
      </div>
    </div>
  );
};

export default OrderDetail;
