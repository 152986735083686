import React, { useContext, useState } from "react";
import ShopContext from "../Store/shop-context";

const AddSlot = ({ avail, setToggler }) => {
  const shopObject = useContext(ShopContext);
  const newSlot = {
    availability: avail.id,
    shop: avail.shop,
    slotfrom: "",
    slotto: "",
  };

  const [slot, setSlot] = useState(newSlot);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setSlot({ ...slot, [name]: value });
  }

  const handleCreate = (e) => {
    if (slot["maxbookings"] === "") {
      slot["maxbookings"] = null;
    }
    shopObject.createslots(slot);
    setToggler(null);
  };

  const handleCancel = (e) => {
    setToggler(null);
  };

  return (
    <div className="posslotline">
      <input
        required
        className=""
        placeholder="slotfrom"
        type="time"
        id="slotfrom"
        name="slotfrom"
        value={slot.slotfrom}
        onChange={handleChange}
      ></input>
      <input
        required
        className=""
        placeholder="slotfrom"
        type="time"
        id="slotto"
        name="slotto"
        value={slot.slotto}
        onChange={handleChange}
      ></input>
      <input
        required
        className=""
        placeholder="max bookings"
        type="integer"
        id="maxbookings"
        name="maxbookings"
        value={slot.maxbookings}
        onChange={handleChange}
      ></input>
      <button className="cancelbutton" onClick={(e) => handleCancel()}>
        cancel
      </button>
      <button className="editbutton" onClick={(e) => handleCreate()}>
        Add
      </button>
    </div>
  );
};

export default AddSlot;
