import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";

const EditSubCat = ({ subCat, cat, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);
  const [sCat, setsCat] = useState(subCat);

  function handler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setsCat({ ...sCat, [name]: value });
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("updated");
    shopObject.updatesubcategory(sCat);
    setToggler(null);
    shopObject.setreload(!shopObject.reload);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  return (
    <>
      {subCat && (
        <div className="row mt-2">
          <div className="col-1"></div>
          <div className="col-2">
            <input
              className=""
              placeholder="subcategory name"
              type="text"
              id="name"
              name="name"
              value={sCat.name}
              onChange={handler}
            />
          </div>
          <div className="col-3">
            <button
              className="border border-0 bg-transparent col-1 fs-4"
              onClick={handleUpdate}
            >
              <i className="bi bi-check"></i>
            </button>
            <button
              className="border border-0 bg-transparent col-1 fs-4"
              onClick={handleCancel}
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditSubCat;
