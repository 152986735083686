import React from "react";

const SOCheckbox = ({ title, name, value, handler }) => {
  return (
    <div className="row d-flex align-items-center">
      <div className="col-2">
        {value && (
          <>
            <input
              type="checkbox"
              name={name}
              onChange={() => handler(name, value)}
              checked
            ></input>
          </>
        )}
        {!value && (
          <>
            <input
              type="checkbox"
              id="checkpromo"
              name={name}
              onChange={() => handler(name, value)}
            ></input>
          </>
        )}
      </div>
      <div className="col-5">{title}</div>
    </div>
  );
};

export default SOCheckbox;
