import React from "react";

const CLine = ({ t, v }) => {
  return (
    <div className="cline">
      <div className="clinesegment">{t}</div>
      <div className="clinesegment">{v}</div>
    </div>
  );
};

export default CLine;
