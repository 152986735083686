import React from "react";
import GetCountry from "./GetCountry";

const SelectAddress = ({
  addressList,
  user,
  corr,
  inv,
  getaddresslist,
  getuserdetails,
  settoggler,
}) => {
  let updateOrgInvoice = async (org) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/organisation/update/invoice/",
      {
        method: "POST",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: org,
      }
    );
    if (resp.status === 200) {
      getaddresslist(user.id);
      getuserdetails(user.id);
      settoggler(false);
    } else {
      alert("something went wrong updating the organisation");
    }
  };

  let updateOrgCorr = async (org) => {
    console.log("org :", org);
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/organisation/update/corr/",
      {
        method: "POST",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: org,
      }
    );
    if (resp.status === 200) {
      getaddresslist(user.id);
      getuserdetails(user.id);
      settoggler(false);
    } else {
      alert("something went wrong updating the organisation");
    }
  };

  const switchAddress = (add) => {
    const newOrg = new FormData();
    if (corr === true) {
      newOrg.append("corr_address", add.id);
      updateOrgCorr(newOrg);
    }
    if (inv === true) {
      newOrg.append("invoice_address", add.id);
      updateOrgInvoice(newOrg);
    }
  };

  return (
    <div className="mt-3">
      {addressList.map((add, index) => {
        return (
          <button
            key={index}
            className="rounded-pill row w-100 mb-2 "
            onClick={(e) => switchAddress(add)}
          >
            <div className="col-3">{add.street}</div>
            <div className="col-1">{add.hnr}</div>
            <div className="col-1">{add.hnrext}</div>
            <div className="col-1">{add.zip}</div>
            <div className="col-2">{add.city}</div>
            <div className="col-1">
              <GetCountry code={add.country} />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default SelectAddress;
