import React from "react";
import getShipment from "../Functions/getShipment";
import dayDifference from "../Functions/dayDifference";
import getNormDate from "../Functions/getNormDate";
import NormDate from "./NormDate";
import OrderDetail from "./OrderDetail";

const OrderLine = ({ order, clickedDetail, orderDetail, setOrderDetail }) => {
  return (
    <div>
      <div className="ocdatarow" onClick={(e) => clickedDetail(order.id)}>
        <div className="occoll">
          {order.ref} {order.remarks ? " !" : ""}
        </div>
        <div className="occols">
          {order.shipmethod === "P" &&
            dayDifference(
              new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
              new Date(new Date().setHours(0, 0, 0, 0))
            ) > 2 &&
            dayDifference(
              new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
              new Date(new Date().setHours(0, 0, 0, 0))
            )}
          {order.shipmethod === "P" &&
            dayDifference(
              new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
              new Date(new Date().setHours(0, 0, 0, 0))
            ) <= 2 && (
              <span className="text-danger fw-bolder">
                {dayDifference(
                  new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
                  new Date(new Date().setHours(0, 0, 0, 0))
                )}
              </span>
            )}
          {order.shipmethod === "M" &&
            dayDifference(
              new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
              new Date(new Date().setHours(0, 0, 0, 0))
            ) > 2 &&
            dayDifference(
              new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
              new Date(new Date().setHours(0, 0, 0, 0))
            )}
          {order.shipmethod === "M" &&
            dayDifference(
              new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
              new Date(new Date().setHours(0, 0, 0, 0))
            ) <= 2 && (
              <span className="text-danger fw-bolder">
                {dayDifference(
                  new Date(new Date(order.bookedpickup).setHours(0, 0, 0, 0)),
                  new Date(new Date().setHours(0, 0, 0, 0))
                )}
              </span>
            )}
        </div>
        <div className="occolm">
          {(order.totalprice + order.totalvat).toFixed(2)}
        </div>
        <div className="occoll">{getShipment(order.shipmethod)}</div>
        <div className="occolm">
          {dayDifference(
            new Date(new Date().setHours(0, 0, 0, 0)),
            new Date(new Date(order.ordered).setHours(0, 0, 0, 0))
          )}
        </div>
        <div className="occoll">
          {order.shipmethod === "P" && (
            <>Pickup: {getNormDate(order.bookedpickup)}</>
          )}
          {order.shipmethod === "M" && (
            <> Arrival: {getNormDate(order.bookedpickup)}</>
          )}
        </div>
        <div className="occolm">{<NormDate olddate={order.ordered} />}</div>
      </div>
      {orderDetail === order.id && (
        <OrderDetail order={order} setToggler={setOrderDetail} />
      )}
    </div>
  );
};

export default OrderLine;
