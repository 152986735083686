import React, { useState, Fragment, useEffect } from "react";
import OrderLine from "./OrderLine";

const OrderList = ({ title, list, sortASC, setSortASC, reset, setReset }) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [showList, setShowList] = useState(list);

  // ------ updowntogglers for sorting
  const [pickupUpDown, setPickupUpDown] = useState(true);
  const [orderedUpDown, setOrderedUpDown] = useState(true);

  useEffect(() => {
    sortPickup(list);
    setShowList(list);
    console.log("resetted");
  }, [list]);

  const clickedDetail = (orderid) => {
    setOrderDetail(orderid);
  };

  const clickedSorter = () => {
    setSortASC(!sortASC);
    setReset(!reset);
  };

  useEffect(() => {
    // setReset(!reset);
  }, [orderDetail]);

  function sortPickup(list) {
    setPickupUpDown(!pickupUpDown);
    if (pickupUpDown) {
      list.sort(
        (a, b) =>
          new Date(a.bookedpickup).getTime() -
          new Date(b.bookedpickup).getTime()
      );
    }
    if (!pickupUpDown) {
      list.sort(
        (a, b) =>
          new Date(b.bookedpickup).getTime() -
          new Date(a.bookedpickup).getTime()
      );
    }
    setShowList(list);
  }

  function sortOrdered(list) {
    if (orderedUpDown) {
      list.sort(
        (a, b) => new Date(a.ordered).getTime() - new Date(b.ordered).getTime()
      );
    }
    if (!orderedUpDown) {
      list.sort(
        (a, b) => new Date(b.ordered).getTime() - new Date(a.ordered).getTime()
      );
    }
    setOrderedUpDown(!orderedUpDown);
    setShowList(list);
  }

  return (
    <>
      {/* ------------------ Orderlist by Order ------------------ */}
      {showList.length > 0 && (
        <div>
          <div className="ocsubtitle">{title}</div>
          <div className="octitlerow">
            <div className="occoll">
              Ref
              <i
                className="bi bi-arrow-down-up"
                onClick={(e) => sortOrdered(showList)}
              ></i>
            </div>
            <div className="occols">
              Days Left
              <i
                className="bi bi-arrow-down-up"
                onClick={(e) => sortPickup(showList)}
              ></i>
            </div>
            <div className="occolm">Value (inc.VAT)</div>
            <div className="occoll">Shipmethod</div>
            <div className="occolm">Days since order</div>
            <div className="occoll">
              Pickup/Arrival
              <i
                className="bi bi-arrow-down-up"
                onClick={(e) => sortPickup(showList)}
              ></i>
            </div>
            <div className="occolm">
              Orderdate{" "}
              <i
                className="bi bi-arrow-down-up"
                onClick={(e) => sortOrdered(showList)}
              ></i>
            </div>
            {/* <div className="occolm"></div> */}
          </div>
          {showList &&
            showList.map((order, index) => {
              return (
                <Fragment key={index}>
                  <OrderLine
                    order={order}
                    clickedDetail={clickedDetail}
                    orderDetail={orderDetail}
                    setOrderDetail={setOrderDetail}
                  />
                </Fragment>
              );
            })}
        </div>
      )}
    </>
  );
};

export default OrderList;
