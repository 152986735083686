import React from "react";
import CLine from "./CLine";
import GetCountry from "./GetCountry";

const ShowAddress = ({ Add }) => {
  return (
    <div>
      <CLine t="Street" v={Add.street} />
      <CLine t="Nr" v={Add.hnr} />
      <CLine t="Ext" v={Add.hnrext} />
      <CLine t="zip" v={Add.zip} />
      <CLine t="city" v={Add.city} />
      <CLine t="country" v={<GetCountry code={Add.country} />} />
    </div>
  );
};

export default ShowAddress;
