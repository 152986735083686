import React from "react";
import GetTitle from "../Components/GetTitle";
import getTitle from "../Functions/getTitle";
import { CSVLink, CSVDownload } from "react-csv";

const CustomerMailingTable = ({ cusList }) => {
  let csvData = [];

  cusList.map((cus, index) => {
    let element = {
      title: getTitle(cus.title),
      firstname: cus.firstname,
      name: cus.name,
      email: cus.email,
      subscribed: cus.subscribed,
    };
    csvData.push(element);
  });
  // const csvData = cusList;
  return (
    <>
      <div className="ccsubtitle">
        Compliant mailing list{" "}
        <small>
          <CSVLink
            data={csvData}
            filename={"customermaillist.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Download CSV table
          </CSVLink>
        </small>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Subscribed</th>
          </tr>
        </thead>
        <tbody>
          {cusList.map((cus, index) => (
            <tr key={index}>
              <th>
                <GetTitle code={cus.title} /> {" " + cus.firstname + " "}
                {cus.name}
              </th>
              <td>{cus.phone}</td>
              <td>{cus.email}</td>
              <td>{cus.subscribed ? "yes" : "no"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CustomerMailingTable;
