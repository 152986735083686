import React from "react";
import GetDay from "./GetDay";
import GetTime from "./GetTime";

const DeleteProductSetting = ({
  prodset,
  toggler,
  setToggler,
  reload,
  setReload,
}) => {
  let deleteProductSettings = async (prodset) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/settings/",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(prodset),
      }
    );
    if (resp.status === 200) {
      console.log("Productsettings deleted");
      setReload(!reload);
    } else {
      alert("something went wrong in updateProductwImage");
    }
  };

  const handleDelete = () => {
    deleteProductSettings(prodset);
    setToggler(null);
  };

  const handleCancel = () => {
    setToggler(null);
  };

  return (
    <tr>
      <th>{prodset.name}</th>
      <td>{prodset.leadtime && <>{prodset.leadtime} dagen</>}</td>
      <td>
        {prodset.orderdeadline && (
          <>
            <GetDay code={new Date(prodset.orderdeadline).getDay()} />
            {" before "} <GetTime date={prodset.orderdeadline} />
          </>
        )}
      </td>
      <td>
        {prodset.orderdelivery && (
          <>
            <GetDay code={new Date(prodset.orderdelivery).getDay()} />
            {" from "} <GetTime date={prodset.orderdelivery} />
          </>
        )}
      </td>
      <td>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDelete()}
        >
          Delete
        </button>
        <button
          className="btn btn-secondary btn-sm ms-2"
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default DeleteProductSetting;
