import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "../Store/auth-context";

const ResetPassword = () => {
  const authObject = useContext(AuthContext);
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({ email: "" });
  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    authObject.resetpassword(email);
    setRequestSent(true);
  };

  if (requestSent) {
    return <Navigate to="/login/" />;
  }

  return (
    <div className="resetpwpage w-75 mx-auto mt-5 mb-5">
      <Helmet>
        <title>Ubicopia® - Reset password</title>
        <meta
          name="description"
          content="Reset your password - for registered users only"
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div className="h1 rpwtitle w-75"> Reset Password</div>
      <label className="rpwlabel">Enter your email address</label>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="resetpwline mt-2">
          <input
            className="form-control"
            type="email"
            placeholder="email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="resetpwline">
          <button className="btn btn-primary resetpwbutton mt-2" type="submit">
            Reset Password
          </button>
        </div>
      </form>
      <p className="resetpwline">
        Don't have an account ? <Link to="/signup/">Signup</Link>
      </p>
    </div>
  );
};

export default ResetPassword; // connect to wrap Login page, as no mapStateToProps are used, and reset_password function is used
