import React, { useState, Fragment } from "react";
import CreateShop from "../Components/CreateShop";
import ShowShop from "../Components/ShowShop";
import EditShop from "../Components/EditShop";

const UserShopController = ({ organisation, getwebshoplist, webshoplist }) => {
  const [editShop, setEditShop] = useState(null);
  const [addShop, setAddshop] = useState(false);
  return (
    <div>
      <div className="userblock">
        <div className="row mb-2">
          <div className="usersubtitle col-10">Your webshop details</div>
          <button
            className="btn btn-success btn-sm col-2"
            onClick={(e) => setAddshop(true)}
          >
            Add a shop
          </button>
        </div>
        {addShop && (
          <CreateShop
            organisation={organisation}
            getshoplist={getwebshoplist}
            settoggler={setAddshop}
          />
        )}

        {!addShop &&
          webshoplist.map((webshop, id) => {
            return (
              <div className="mt-5 rounded-2 bg-light p-3" key={id}>
                {editShop !== webshop.id && (
                  <ShowShop shop={webshop} settoggler={setEditShop} />
                )}
                {editShop === webshop.id && (
                  <EditShop
                    shop={webshop}
                    organisation={organisation}
                    getshoplist={getwebshoplist}
                    settoggler={setEditShop}
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserShopController;
