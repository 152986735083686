import React from "react";

const readTime = (data) => {
  if (data !== "undefined") {
    var hours = new Date(data).getHours();
    var minutes = new Date(data).getMinutes();
    var time =
      ("0" + String(hours)).slice(-2) + ":" + ("0" + String(minutes)).slice(-2);
  } else {
    var time = "undefined";
  }
  return time;
};

export default readTime;
