import React, { useContext, useState } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

const AddUnav = ({ location, setToggler, unavailable }) => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);

  let fakeAv = {
    shop: controlObject.webshop.id,
    location: location.id,
    date: "",
    recurring: false,
    recurringtype: "",
    unavailable: unavailable,
    dateto: "",
  };

  const [av, setAv] = useState(fakeAv);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAv({ ...av, [name]: value });
  }

  const handleCreate = (e) => {
    if (av["recurringtype"] === "" || av["recurringtype"] === "N") {
      av["recurringtype"] = null;
      av["recurring"] = false;
    } else {
      av["recurring"] = true;
    }
    if (av["dateto"] == "") {
      av["dateto"] = null;
    }

    if (av["date"]) {
      shopObject.createavailas(av);
      setToggler(null);
    }
  };

  const handleCancel = (e) => {
    setToggler(null);
  };

  return (
    <div className="posavgroup">
      <div className="posavline">
        op datum -
        <input
          required
          className=""
          placeholder="date"
          type="date"
          id="date"
          name="date"
          value={av.date}
          onChange={handleChange}
        />
        <button className="cancelbutton" onClick={(e) => handleCancel()}>
          cancel
        </button>
        <button className="editbutton" onClick={(e) => handleCreate()}>
          Add
        </button>
      </div>
    </div>
  );
};

export default AddUnav;
