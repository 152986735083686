import React from "react";

import { ships } from "../Data/data";

const getShipment = (code) => {
  if (ships.filter((tit) => tit.code == code)) {
    let shipType = ships.filter((tit) => tit.code == code)[0].name;
    return shipType;
  }
};

export default getShipment;
