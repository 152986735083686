import React, { useState, useContext, Fragment } from "react";
import { useEffect } from "react";
import ShopContext from "../Store/shop-context";

const Catbuttons = () => {
  const shopObject = useContext(ShopContext);
  const [catList, setCatList] = useState();

  useEffect(() => {
    setCatList(shopObject.categories);
  }, [shopObject.categories]);

  useEffect(() => {
    setCatList(shopObject.categories);
  }, []);

  const clickHandler = (cat) => {
    shopObject.setcategory(cat);
    shopObject.setsubcategory(null);
  };

  const clickedLeft = (pos) => {
    if (pos > 0) {
      let cat = shopObject.categories[pos];
      let leftCat = shopObject.categories[pos - 1];
      cat.position = cat.position - 1;
      leftCat.position = leftCat.position + 1;
      shopObject.updatecategory(cat);
      shopObject.updatecategory(leftCat);
    }
  };

  const clickedRight = (pos) => {
    if (pos + 1 < shopObject.categories.length) {
      let cat = shopObject.categories[pos];
      let rightCat = shopObject.categories[pos + 1];
      cat.position = cat.position + 1;
      rightCat.position = rightCat.position - 1;
      shopObject.updatecategory(cat);
      shopObject.updatecategory(rightCat);
    }
  };

  return (
    <div>
      {catList && (
        <div className="catgroup">
          {catList.map((ct, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && (
                  <i
                    className="bi bi-caret-left text-white mt-3"
                    onClick={(e) => clickedLeft(ct.position)}
                  ></i>
                )}
                {shopObject.category === null && (
                  <button
                    className="catbutton"
                    onClick={(e) => clickHandler(ct)}
                  >
                    {ct.name}
                    {" - "} {ct.position}
                  </button>
                )}

                {shopObject.category !== null &&
                  ct.id !== shopObject.category.id && (
                    <button
                      className="catbutton"
                      onClick={(e) => clickHandler(ct)}
                    >
                      {ct.name}
                      {" - "} {ct.position}
                    </button>
                  )}

                {shopObject.category !== null &&
                  ct.id === shopObject.category.id && (
                    <button
                      className="catbutton"
                      onClick={(e) => clickHandler(ct)}
                    >
                      <strong>{ct.name}</strong>
                      {" - "} {ct.position}
                    </button>
                  )}
                {index < catList.length - 1 && (
                  <i
                    className="bi bi-caret-right text-white mt-3"
                    onClick={(e) => clickedRight(ct.position)}
                  ></i>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Catbuttons;
