import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

const DeleteProduct = ({ product, prodList, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);
  const controlObject = useContext(ControlContext);

  const handleDelete = (e) => {
    e.preventDefault();
    const delProduct = new FormData();
    delProduct.append("id", product.id);
    delProduct.append("webshop", controlObject.webshop.id);

    // let pos = product.position;
    // let length = prodList.length - 1;
    // if (pos + 1 < length) {
    //   prodList.map((prod, index) => {
    //     if (prod.position > pos) {
    //       prod.position = prod.position - 1;
    //     }
    //     updatePOS(prod);
    //   });
    // }
    shopObject.deleteproductwimage(delProduct);
    setToggler(null);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  // const updatePOS = (prod) => {
  //   const newProduct = new FormData();
  //   newProduct.append("id", prod.id);
  //   newProduct.append("position", prod.position);
  //   newProduct.append("webshop", controlObject.webshop.id);
  //   shopObject.updateproductwimage(newProduct);
  // };

  return (
    <div className="card w-30">
      <div className="cardimagebox">
        <img
          src={product.picture}
          className="cardimage"
          alt={product.title}
        ></img>
      </div>
      <div className="card-body">
        <h5 className="card-title">{product.name}</h5>
        <p className="card-description">{product.description}</p>
        <p className="card-text">
          Unit price{" "}
          <strong>{(product.price * (1 + product.vat)).toFixed(2)}</strong>
        </p>
        <p className="card-sku">SKU {product.sku}</p>
        <p className="card-text">
          Category{"  "}
          <strong>
            {shopObject.categories.filter(
              (cat) => cat.id == product.category
            )[0] &&
              shopObject.categories.filter(
                (cat) => cat.id == product.category
              )[0].name}
          </strong>
        </p>
        <div className="scbuttongroup">
          <button className="btn btn-secondary btn-sm" onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn btn-danger btn-sm" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProduct;
