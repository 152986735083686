import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import AuthContext from "../Store/auth-context";
import ControlContext from "../Store/control-context";
import CLine from "../Components/CLine";
import getTitle from "../Functions/getTitle";
import CreateInvCorrAddress from "../Components/CreateInvCorrAddress";
import CreateOrg from "../Components/CreateOrg";

const WelcomePage = () => {
  let authObject = useContext(AuthContext);
  let controlObject = useContext(ControlContext);

  const startPayment = () => {
    console.log("clicked");
  };

  return (
    <>
      <Helmet>
        <title>Ubicopia® - Welcome user</title>
        <meta name="description" content="Welcomepage for registered users" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {authObject.user && authObject.user.blocked && (
        <div className="text-white">
          <div className="fs-3">
            Momentarily manual release will be required.
          </div>
          <p>You will be contacted shortly</p>
        </div>
      )}
      {authObject.user && !authObject.user.blocked && (
        <>
          {authObject.user && authObject.organisation && (
            <div className="cpage">
              {authObject.user && (
                <>
                  <div className="ctitle">Welcome</div>
                  <div className="cblock">
                    <div className="csubtitle">Your user details</div>
                    <CLine t="title" v={getTitle(authObject.user.title)} />
                    <CLine t="First Name" v={authObject.user.firstname} />
                    <CLine t="Name" v={authObject.user.name} />
                    <CLine t="Phone" v={authObject.user.phone} />
                    <CLine t="Email" v={authObject.user.email} />
                  </div>
                </>
              )}
              {authObject.user.organisation && (
                <>
                  <div className="cblock">
                    <div className="csubtitle">Your organisation</div>
                    <CLine t="name" v={authObject.organisation.name} />
                    <CLine t="VAT" v={authObject.organisation.vat} />
                    <CLine t="Bank" v={authObject.organisation.bankiban} />
                    <CLine t="BIC" v={authObject.organisation.bankbic} />
                  </div>
                </>
              )}
              {!authObject.user.organisation && (
                <>
                  <CreateOrg user={authObject.user} />
                </>
              )}
              {/* {authObject.user.organisation &&
                (!authObject.user.organisation.smallogo ||
                  !authObject.user.organisation.smallogo) && (
                  <>
                    <UpdateOrgWithLogo user={authObject.user} />
                  </>
                )} */}
              {authObject.user.organisation &&
                !authObject.organisation.invoice_address && (
                  <>
                    <CreateInvCorrAddress
                      user={authObject.user}
                      organisation={authObject.organisation}
                    />
                  </>
                )}
              {authObject.user &&
                authObject.organisation &&
                authObject.organisation.invoice_address &&
                controlObject.webshoplist && (
                  <div className="cblock">
                    <div className="csubtitle">
                      Please proceed to select your webservice from the dropdown
                      !
                    </div>
                  </div>
                )}
              {/* <Payment /> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WelcomePage;
