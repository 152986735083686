import React, { useState, useContext, useEffect } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

import ShowAllergens from "./ShowAllergens";
import AddAllergens from "./AddAllergens";

import ShowVoedingsWaarden from "./ShowVoedingsWaarden";
import AddVoedingsWaarden from "./AddVoedingsWaarden";

const DetailProduct = ({ product, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);
  const controlObject = useContext(ControlContext);
  const [prod, setProd] = useState();
  const [origProd, setOrigProd] = useState();
  const [image, setImage] = useState();
  const [addAllergens, setAddAllergens] = useState(false);
  const [addVoedingsWaarden, setAddVoedingsWaarden] = useState(false);
  const [changed, setChanged] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    getProductDetail(product);
  }, []);

  useEffect(() => {
    getProductDetail(product);
  }, [update]);

  let getProductDetail = async (product) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/detail/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: controlObject.webshop.id,
          product: product,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setProd(data);
      setOrigProd(data);
      console.log("productdetail loaded :", data);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let getVoedingsWaarden = async (product) => {};

  function handler(e) {
    setChanged(true);
    const name = e.target.name;
    const value = e.target.value;
    setProd({ ...prod, [name]: value });
  }

  const imageHandler = (e) => {
    setChanged(true);
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.files[0];
    setProd({ ...prod, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const newProduct = new FormData();
    newProduct.append("id", prod.id);
    newProduct.append("name", prod.name);
    newProduct.append("sku", prod.sku);
    if (prod.picture !== origProd.picture) {
      newProduct.append("picture", prod.picture);
    }
    if (prod.picturelarge1 !== origProd.picturelarge1) {
      newProduct.append("picturelarge1", prod.picturelarge1);
    }
    if (prod.picturelarge2 !== origProd.picturelarge2) {
      newProduct.append("picturelarge2", prod.picturelarge2);
    }
    if (prod.picturelarge3 !== origProd.picturelarge3) {
      newProduct.append("picturelarge3", prod.picturelarge3);
    }
    newProduct.append("description", prod.description);
    newProduct.append("fulldescription", prod.fulldescription);
    newProduct.append("promotion", prod.promotion);
    newProduct.append("position", prod.position);
    if (prod.stock !== null) {
      newProduct.append("stock", prod.stock);
    }
    newProduct.append("category", parseInt(prod.category));
    newProduct.append("webshop", controlObject.webshop.id);
    if (prod.settings === null) {
      prod.settings = "";
    }
    shopObject.updateproductwimage(newProduct);
    setToggler(null);
    setChanged(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setChanged(false);
    setToggler(null);
  };

  return (
    <>
      {prod && (
        <>
          <div className="detailpage">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={handleCancel}
            >
              {"<"}--- back
            </button>
            <form className="dpboxdetails" onSubmit={(e) => handleUpdate(e)}>
              <div className="dpboxgroup">
                <div className="form-group mt-3">
                  <label>Title</label>
                  <input
                    className="form-control h1 fs-1"
                    placeholder="product name"
                    type="text"
                    name="name"
                    value={prod.name}
                    onChange={handler}
                    pattern="{1,100}$"
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="w-100">Icon</label>
                  <img
                    src={prod.picture}
                    className="dpicon"
                    alt={prod.title}
                  ></img>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    placeholder="product picture"
                    name="picture"
                    id="picture"
                    onChange={imageHandler}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="w-100">Large picture 1</label>
                  <img
                    src={prod.picturelarge1}
                    className="dpthumb"
                    alt={prod.title}
                    onClick={(e) => setImage(prod.picturelarge1)}
                  ></img>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    placeholder="product large picture 1"
                    id="picturelarge1"
                    name="picturelarge1"
                    onChange={imageHandler}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="w-100">Large picture 2</label>
                  <img
                    src={prod.picturelarge2}
                    className="dpthumb"
                    alt={prod.title}
                    onClick={(e) => setImage(prod.picturelarge2)}
                  ></img>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    placeholder="product large picture 2"
                    id="picturelarge2"
                    name="picturelarge2"
                    onChange={imageHandler}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="w-100">Large picture 3</label>
                  <img
                    src={prod.picturelarge3}
                    className="dpthumb"
                    alt={prod.title}
                    onClick={(e) => setImage(prod.picturelarge3)}
                  ></img>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/jpeg,image/png,image/gif"
                    placeholder="product large picture 3"
                    id="picturelarge3"
                    name="picturelarge3"
                    onChange={imageHandler}
                  />
                </div>

                <label>Real size view</label>
                <div className="h1">
                  <img src={image} className="dpbox" alt={prod.title}></img>
                </div>
              </div>

              <div className="dpdetails">
                <div className="form-group mt-3">
                  <label>Short description</label>
                  <input
                    className="form-control"
                    placeholder="product description"
                    type="text"
                    id="description"
                    name="description"
                    value={prod.description}
                    onChange={handler}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Full description</label>
                  <textarea
                    className="form-control dpdetailsfull"
                    placeholder="full product description"
                    type="text"
                    id="fulldescription"
                    name="fulldescription"
                    value={prod.fulldescription}
                    onChange={handler}
                  />
                </div>

                <div className="form-group mt-3">
                  <label>SKU</label>
                  <input
                    className="form-control"
                    placeholder="product SKU"
                    type="text"
                    id="sku"
                    name="sku"
                    value={prod.sku}
                    onChange={handler}
                    pattern="[a-zA-Z0-9]{0,12}$"
                  />
                </div>

                <div className="dpbuttongroup mt-5">
                  {changed === true && (
                    <>
                      <button
                        className="btn btn-success btn-sm dpbutton"
                        type="submit"
                      >
                        update
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>

            <div className="mt-5"></div>
            <hr></hr>
            {prod.allergens !== null && (
              <ShowAllergens
                product={prod}
                webshopid={controlObject.webshop.id}
                update={update}
                setUpdate={setUpdate}
              />
            )}
            <div className="mt-5"></div>
            {prod.allergens === null && (
              <div className="row mt-5">
                <div className="col"></div>
                <div className="col d-flex justify-content-end">
                  <button
                    className="btn btn-success btn-sm mt-3"
                    onClick={() => setAddAllergens(true)}
                  >
                    Add allergens
                  </button>
                </div>
              </div>
            )}
            {addAllergens && (
              <>
                <AddAllergens
                  product={prod}
                  webshopid={controlObject.webshop.id}
                  setToggler={setAddAllergens}
                  update={update}
                  setUpdate={setUpdate}
                />
              </>
            )}
            <hr></hr>
            {prod.voedingswaarden !== null && (
              <ShowVoedingsWaarden
                product={prod}
                webshopid={controlObject.webshop.id}
                update={update}
                setUpdate={setUpdate}
              />
            )}
            <div className="mt-5"></div>
            {prod.voedingswaarden === null && (
              <div className="row mt-5">
                <div className="col"></div>
                <div className="col d-flex justify-content-end">
                  <button
                    className="btn btn-success btn-sm mt-3"
                    onClick={() => setAddVoedingsWaarden(true)}
                  >
                    Add voedingswaarden
                  </button>
                </div>
              </div>
            )}
            {addVoedingsWaarden && (
              <>
                <AddVoedingsWaarden
                  product={prod}
                  webshopid={controlObject.webshop.id}
                  setToggler={setAddVoedingsWaarden}
                  update={update}
                  setUpdate={setUpdate}
                />
              </>
            )}
            <hr></hr>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={handleCancel}
            >
              {"<"}--- back
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default DetailProduct;
