import React from "react";
let days = ["Su", "Mo", "Tu", "we", "Th", "Fr", "Sa"];

const NormDate = ({ olddate }) => {
  let date = new Date(olddate);
  let year = date.getYear() - 100;
  let month = date.getMonth() + 1;
  let monthday = date.getDate();
  let weekday = days[date.getDay()];
  let normdate =
    String(weekday) +
    " " +
    String(monthday) +
    "/" +
    String(month) +
    "/" +
    String(year);
  return <>{normdate}</>;
};

export default NormDate;
