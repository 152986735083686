import React from "react";

const DeletePromoLetter = ({ setToggler, promoLetter }) => {
  let deletePromo = async (promo) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/promoletter/",
      {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: promo,
      }
    );
    if (resp.status === 200) {
      setToggler(null);
    } else {
      alert("something went wrong deleting your promoletter");
    }
  };

  const promoSubmit = (e) => {
    e.preventDefault();
    const promo = new FormData();
    promo.append("id", promoLetter.id);
    deletePromo(promo);
  };

  return (
    <div className="w-50">
      <form onSubmit={(e) => promoSubmit(e)}>
        <div className="mt-3 ms-3">
          You are about to delete the promoletter.
        </div>
        <div className="ms-3">Are you sure ?</div>
        <button
          className="btn btn-secondary mt-3"
          onClick={() => setToggler(null)}
          type="button"
        >
          Cancel
        </button>
        <button className="btn btn-danger mt-3 ms-5" type="submit">
          Delete
        </button>
      </form>
    </div>
  );
};

export default DeletePromoLetter;
