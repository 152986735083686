import React from "react";

const VWBox = ({ vw, name, value, handler }) => {
  return (
    <div className="row w-75 mx-auto">
      <div className="col-5">
        <label className="form-label">{vw} </label>
      </div>
      <div className="col-5">
        <input
          className="form-control"
          type="number"
          name={name}
          value={value}
          onChange={handler}
        />
      </div>
    </div>
  );
};

export default VWBox;
