import React, { useState } from "react";
import ShowUser from "../Components/ShowUser";
import EditUser from "../Components/EditUser";

const UserUserController = ({ user }) => {
  const [editUser, setEditUser] = useState(false);

  return (
    <div>
      {!editUser && <ShowUser user={user} settoggler={setEditUser} />}
      {editUser && <EditUser user={user} settoggler={setEditUser} />}
    </div>
  );
};

export default UserUserController;
