import React from "react";

const Company = () => {
  return (
    <div>
      <p>Ubicopia is een product van</p>
      <div>DRO Projects BV</div>
      <div>BTW : BE0631 698 840 </div>
      <div>Sint-Hubertusstraat 120, 3300 Oplinter (Tienen)</div>
      <div>info@ubicopia.com</div>
      <div>tel : +32 (0)16.903.806</div>
    </div>
  );
};

export default Company;
