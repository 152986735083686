import React, { useState, useEffect, useContext } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

import EditProduct from "../Components/EditProduct";
import AddProduct from "../Components/AddProduct";
import DeleteProduct from "../Components/DeleteProduct";
import DetailProduct from "../Components/DetailProduct";
import ProductCard from "../Components/ProductCard";
import Catbuttons from "../Components/Catbuttons";

const ProductList = () => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);
  const [clickedAdd, setClickedAdd] = useState(null);
  const [clickedDetail, setClickedDetail] = useState(false);
  const [clickedEdit, setClickedEdit] = useState(null);
  const [clickedDelete, setClickedDelete] = useState(null);
  // const [cat, setCat] = useState();
  const [prodList, setProdList] = useState([]);
  const [reset, setReset] = useState(false);

  // productlist laden per categorie
  useEffect(() => {
    if (shopObject.category !== null) {
      shopObject.getproductlist(
        controlObject.webshop.id,
        shopObject.category.id
      );
      shopObject.getproductoptions(
        controlObject.webshop.id,
        shopObject.category.id
      );
    }
    shopObject.getproductsettings();
  }, [controlObject.webshop, shopObject.category, reset]);

  // productlist sorteren per positie en filteren per subcateogryladen per categorie
  useEffect(() => {
    if (shopObject.subcategory === null) {
      setProdList(
        shopObject.products.sort(function (a, b) {
          return a.position - b.position;
        })
      );
    }
    if (shopObject.subcategory !== null) {
      setProdList(
        shopObject.products
          .filter((pr) => pr.subcategory === shopObject.subcategory.id)
          .sort(function (a, b) {
            return a.position - b.position;
          })
      );
    }
  }, [
    shopObject.produktlistchange,
    shopObject.products,
    shopObject.subcategory,
  ]);

  const clickedProductDetail = (product) => {
    shopObject.getproductdetail(product);
    setClickedDetail(product);
  };

  const clickEditProduct = (product) => {
    shopObject.getproductdetail(product);
    setClickedEdit(product.id);
  };

  const clickedDeleteProduct = (product) => {
    setClickedDelete(product.id);
  };

  const clickedAddProduct = () => {
    setClickedAdd(true);
  };

  const clickedFront = (pos) => {
    if (pos > 0) {
      let product = prodList[pos];
      prodList.map((prod, index) => {
        if (prod.position < pos) {
          prod.position = prod.position + 1;
        }
        updatePOS(prod);
      });
      product.position = 0;
      updatePOS(product);
      shopObject.getproductlist();
    }
  };

  const clickedLeft = (pos) => {
    if (pos > 0) {
      let product = prodList[pos];
      let leftproduct = prodList[pos - 1];
      product.position = product.position - 1;
      leftproduct.position = product.position + 1;
      updatePOS(product);
      updatePOS(leftproduct);
    }
  };

  const clickedRight = (pos) => {
    if (pos + 1 < prodList.length) {
      let product = prodList[pos];
      let rightproduct = prodList[pos + 1];
      product.position = product.position + 1;
      rightproduct.position = product.position - 1;
      updatePOS(product);
      updatePOS(rightproduct);
    }
  };
  const clickedEnd = (pos) => {
    if (pos + 1 < prodList.length) {
      let product = prodList[pos];
      prodList.map((prod, index) => {
        if (prod.position > pos) {
          prod.position = prod.position - 1;
        }
        updatePOS(prod);
      });
      product.position = prodList.length - 1;
      updatePOS(product);
      shopObject.getproductlist();
    }
  };

  const handleUpdate = (prod, index) => {
    const newProduct = new FormData();
    newProduct.append("id", prod.id);
    newProduct.append("position", index);
    newProduct.append("webshop", controlObject.webshop.id);
    shopObject.updateproductwimage(newProduct);
  };

  const updatePOS = (prod) => {
    const newProduct = new FormData();
    newProduct.append("id", prod.id);
    newProduct.append("position", prod.position);
    newProduct.append("category", prod.category);
    newProduct.append("webshop", controlObject.webshop.id);
    newProduct.append("active", prod.active);
    shopObject.updateproductwimage(newProduct);
  };

  return (
    <div>
      {clickedDetail && (
        <DetailProduct
          product={clickedDetail}
          toggler={clickedDetail}
          setToggler={setClickedDetail}
        />
      )}
      {clickedAdd && (
        <AddProduct toggler={clickedAdd} setToggler={setClickedAdd} />
      )}
      {shopObject.category != null && !clickedDetail && !clickedAdd && (
        <>
          <div className="scline">
            <div className="scsubtitle">List of products</div>
            {shopObject.categories.length !== 0 && (
              <button
                className="btn btn-success btn-sm scaddbutton"
                onClick={(e) => clickedAddProduct()}
              >
                Add
              </button>
            )}
            {shopObject.categories.length === 0 && (
              <div className="scaddbutton">Need to add a category first.</div>
            )}
          </div>
          <div className="cardgroup">
            {prodList &&
              prodList.map((product, index) => {
                return (
                  <div key={index}>
                    {clickedEdit === product.id && (
                      <EditProduct
                        product={product}
                        toggler={clickedEdit}
                        setToggler={setClickedEdit}
                        reset={reset}
                        setReset={setReset}
                      />
                    )}

                    {clickedDelete === product.id && (
                      <DeleteProduct
                        product={product}
                        prodList={prodList}
                        toggler={clickedDelete}
                        setToggler={setClickedDelete}
                      />
                    )}

                    {clickedEdit !== product.id &&
                      clickedDelete !== product.id && (
                        <>
                          {/* {handleUpdate(product, index)} */}
                          <ProductCard
                            product={product}
                            clickedProductDetail={clickedProductDetail}
                            clickEditProduct={clickEditProduct}
                            clickedDeleteProduct={clickedDeleteProduct}
                            clickedFront={clickedFront}
                            clickedLeft={clickedLeft}
                            clickedRight={clickedRight}
                            clickedEnd={clickedEnd}
                          />
                        </>
                      )}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;
