import React, { useState } from "react";
import VWBox from "./VWBox";

const AddVoedingsWaarden = ({
  product,
  webshopid,
  setToggler,
  update,
  setUpdate,
}) => {
  const [vw, setVw] = useState({
    energie: "x kJ - y kcal",
    vetten: 0,
    vetten_verzadigd: 0,
    vetten_verz_enkelv: 0,
    vetten_verz_meerv: 0,
    koolhydraten: 0,
    kool_suikers: 0,
    kool_polyolen: 0,
    kool_zetmeel: 0,
    vezels: 0,
    eiwitten: 0,
    zout: 0,
    vitamins: 0,
    remarks: "",
  });

  let addVW = async (product, vw) => {
    let resp = await fetch(process.env.REACT_APP_API_URL + "capi/product/vw/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + String(localStorage.getItem("access")),
      },
      body: JSON.stringify({
        webshopid: webshopid,
        product: product,
        voedingswaarden: vw,
      }),
    });
    if (resp.status === 200) {
      console.log("voedingswaarden added");
      setUpdate(!update);
      setToggler(false);
    } else {
      alert("something went wrong");
    }
  };

  function handler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setVw({ ...vw, [name]: value });
  }

  function createVW(e) {
    e.preventDefault();
    console.log("Creating:", vw);
    addVW(product, vw);
  }

  return (
    <form onSubmit={(e) => createVW(e)}>
      <div className="mt-3">
        <div className="fw-bold my-4">Voedingswaarden per 100 g/100 ml:</div>
        <div>
          <div className="row w-75 mx-auto">
            <div className="col-5">
              <label className="form-label">Energie (kJ/kcal)</label>
            </div>
            <div className="col-5">
              <input
                className="form-control"
                type="text"
                name="energie"
                value={vw.energie}
                onChange={handler}
              />
            </div>
          </div>
          <VWBox
            vw="Vetten (g)"
            name="vetten"
            value={vw.vetten}
            handler={handler}
          />
          <div className="ms-2">
            <VWBox
              vw="waarvan verzadigde vetzuren (g)"
              name="vetten_verzadigd"
              value={vw.vetten_verzadigd}
              handler={handler}
            />
            <div className="ms-2">
              <VWBox
                vw="waarvan enkelvoudig onverzadigde vetzuren (g)"
                name="vetten_verz_enkelv"
                value={vw.vetten_verz_enkelv}
                handler={handler}
              />
              <VWBox
                vw=" waarvan meervoudig onverzadigde vetzuren (g)"
                name="vetten_verz_meerv"
                value={vw.vetten_verz_meerv}
                handler={handler}
              />
            </div>
          </div>
          <VWBox
            vw="Koolhydraten (g)"
            name="koolhydraten"
            value={vw.koolhydraten}
            handler={handler}
          />
          <div className="ms-2">
            <VWBox
              vw="waarvan suikers (g)"
              name="kool_suikers"
              value={vw.kool_suikers}
              handler={handler}
            />
            <VWBox
              vw="waarvan polyolen (g)"
              name="kool_polyolen"
              value={vw.kool_polyolen}
              handler={handler}
            />
            <VWBox
              vw="waarvan zetmeel (g)"
              name="kool_zetmeel"
              value={vw.kool_zetmeel}
              handler={handler}
            />
          </div>
          <VWBox
            vw="Vezels (g)"
            name="vezels"
            value={vw.vezels}
            handler={handler}
          />
          <VWBox
            vw="Eiwitten (g)"
            name="eiwitten"
            value={vw.eiwitten}
            handler={handler}
          />
          <VWBox vw="zout (g)" name="zout" value={vw.zout} handler={handler} />
          <VWBox
            vw="Vitaminen en mineralen"
            name="vitamins"
            value={vw.vitamins}
            handler={handler}
          />
          <div className="row w-75 mx-auto">
            <div className="col-5">
              <label className="form-label">Opmerkingen :</label>
            </div>
            <div className="col-5">
              <input
                className="form-control"
                type="text"
                name="remarks"
                value={vw.remarks}
                onChange={handler}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col"></div>
        <div className="col d-flex justify-content-end">
          <button className="btn btn-success btn-sm" type="submit">
            Create
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-2"
            onClick={() => setToggler(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddVoedingsWaarden;
