import { selectdays } from "../Data/data";

const readDay = (data) => {
  if (data !== "undefined") {
    var dayinput = new Date(data).getDay();

    day = selectdays.filter((d) => d.value === String(dayinput))[0];
  } else {
    var day = "undefined";
  }
  return day;
};

export default readDay;
