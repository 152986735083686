import React, { useState, Fragment } from "react";
import EditAddress from "../Components/EditAddress";
import DeleteAddress from "../Components/DeleteAddress";
import ShowAddress from "../Components/ShowAddress";
import SelectAddress from "../Components/SelectAddress";
import CreateAddress from "../Components/CreateAddress";

const UserAddressController = ({
  user,
  organisation,
  getaddresslist,
  getuserdetails,
  addresslist,
  invAdd,
  corrAdd,
}) => {
  const [addAdd, setAddAdd] = useState(false);
  const [delAdd, setDelAdd] = useState(false);
  const [editAdd, setEditAdd] = useState(false);
  const [editInvAddress, setEditInvAddress] = useState(false);
  const [editCorrAddress, setEditCorrAddress] = useState(false);
  const [selInvAdd, setSelInvAdd] = useState(false);
  const [selCorAdd, setSelCorAdd] = useState(false);

  return (
    <div>
      <div className="userblock">
        <div className="row">
          <div className="usersubtitle col-10">Addresses</div>
        </div>
      </div>
      {invAdd && (
        <div className="userblock bg-light p-3 rounded-2">
          <div className="row">
            <div className="usersubtitle col-8">Invoice Address Details</div>
            {!editInvAddress && !selInvAdd && (
              <button
                className="btn btn-secondary btn-sm col-2"
                onClick={(e) => setSelInvAdd(true)}
              >
                select other
              </button>
            )}
          </div>
          {!selInvAdd && editInvAddress !== invAdd.id && (
            <ShowAddress Add={invAdd} settoggler={setEditInvAddress} />
          )}
          {!selInvAdd && editInvAddress === invAdd.id && (
            <EditAddress
              address={invAdd}
              user={user}
              getaddresslist={getaddresslist}
              settoggler={setEditInvAddress}
            />
          )}
          {selInvAdd && (
            <SelectAddress
              addressList={addresslist}
              user={user}
              corr={false}
              inv={true}
              getaddresslist={getaddresslist}
              getuserdetails={getuserdetails}
              settoggler={setSelInvAdd}
            />
          )}
          <div className="row">
            <div className="col-10"></div>
            {!selInvAdd && !editInvAddress && (
              <button
                className="btn btn-primary btn-sm col-1 ms-1"
                onClick={(e) => setEditInvAddress(invAdd.id)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}
      {corrAdd && invAdd && corrAdd.id === invAdd.id && (
        <div className="userblock">
          <div className="row">
            <div className="usersubtitle col-8">
              Correspondence Address Details - same
            </div>
            {!selCorAdd && (
              <button
                className="btn btn-secondary btn-sm col-2"
                onClick={(e) => setSelCorAdd(true)}
              >
                select other
              </button>
            )}

            {selCorAdd === true && (
              <SelectAddress
                addressList={addresslist}
                user={user}
                corr={true}
                inv={false}
                getaddresslist={getaddresslist}
                getuserdetails={getuserdetails}
                settoggler={setSelCorAdd}
              />
            )}
          </div>
        </div>
      )}
      {corrAdd && invAdd && corrAdd.id !== invAdd.id && (
        <div className="userblock bg-light rounded-2 p-3">
          <div className="row">
            <div className="usersubtitle col-8">
              Correspondence Address Details
            </div>
            {!selCorAdd && !editCorrAddress && (
              <button
                className="btn btn-secondary btn-sm col-2"
                onClick={(e) => setSelCorAdd(true)}
              >
                select other
              </button>
            )}
          </div>
          {!selCorAdd && !editCorrAddress && (
            <ShowAddress Add={corrAdd} settoggler={setEditCorrAddress} />
          )}
          {!selCorAdd && editCorrAddress && (
            <EditAddress
              address={corrAdd}
              user={user}
              getaddresslist={getaddresslist}
              settoggler={setEditCorrAddress}
            />
          )}
          {selCorAdd && (
            <SelectAddress
              addressList={addresslist}
              user={user}
              corr={true}
              inv={false}
              getaddresslist={getaddresslist}
              getuserdetails={getuserdetails}
              settoggler={setSelCorAdd}
            />
          )}
          <div className="row">
            <div className="col-10"></div>
            {!selCorAdd && !editCorrAddress && (
              <button
                className="btn btn-primary btn-sm col-1 ms-2"
                onClick={(e) => setEditCorrAddress(true)}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}
      <div className="userblock bg-light rounded-2 p-3">
        <div className="row">
          <div className="usersubtitle col-10">Other addresses</div>
          {!addAdd && (
            <button
              className="btn btn-success btn-sm col-1 ms-2"
              onClick={() => setAddAdd(true)}
            >
              Add
            </button>
          )}
        </div>

        {addAdd && (
          <CreateAddress
            user={user}
            organisation={organisation}
            getaddresslist={getaddresslist}
            settoggler={setAddAdd}
          />
        )}
        {!addAdd &&
          addresslist &&
          addresslist
            .filter((add) => add.id !== organisation.invoice_address)
            .filter((add) => add.id !== organisation.corr_address) && (
            <>
              {addresslist
                .filter((add) => add.id !== organisation.invoice_address)
                .filter((add) => add.id !== organisation.corr_address)
                .map((add, index) => {
                  return (
                    <div className="mt-3 bg-white p-3 rounded-2" key={index}>
                      {add.id !== editAdd && (
                        <ShowAddress Add={add} settoggler={setEditAdd} />
                      )}
                      {editAdd === add.id && (
                        <EditAddress
                          address={add}
                          user={user}
                          getaddresslist={getaddresslist}
                          settoggler={setEditAdd}
                        />
                      )}
                      {delAdd === add.id && (
                        <DeleteAddress
                          add={add}
                          user={user}
                          getaddresslist={getaddresslist}
                          settoggler={setDelAdd}
                        />
                      )}
                      <div className="row">
                        <div className="col-9"></div>
                        <button
                          className="btn btn-danger btn-sm col-1"
                          onClick={(e) => setDelAdd(add.id)}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-primary btn-sm col-1 ms-3"
                          onClick={(e) => setEditAdd(add.id)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
      </div>
    </div>
  );
};

export default UserAddressController;
