import React from "react";

const AllergenBox = ({ image, allergen, name, value, handler }) => {
  return (
    <div className="row w-100 d-flex align-items-center">
      <div className="col-1">
        {value && (
          <>
            <input
              type="checkbox"
              name={name}
              onChange={() => handler(name, value)}
              checked
            ></input>
          </>
        )}
        {!value && (
          <>
            <input
              type="checkbox"
              id="checkpromo"
              name={name}
              onChange={() => handler(name, value)}
            ></input>
          </>
        )}
      </div>
      <div className="col-2">
        <img src={image} width="25" />
      </div>
      <div className="col-8">{allergen}</div>
    </div>
  );
};

export default AllergenBox;
