import React, { useState } from "react";

const DeleteProductOption = ({
  option,
  setToggler,
  loadOptions,
  setLoadOptions,
  setWorkingOptions,
}) => {
  const [productOption, setProductOption] = useState(option);

  let deleteOption = async (prodOp) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/options/",
      {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: prodOp,
      }
    );
    if (resp.status === 200) {
      console.log("option created");
      setToggler(null);
      setLoadOptions(!loadOptions);
      setWorkingOptions(false);
    } else {
      alert("something went wrong deleting option");
    }
  };

  const optionSubmit = (e) => {
    e.preventDefault();
    const productOption = new FormData();
    productOption.append("id", option.id);
    deleteOption(productOption);
  };

  return (
    <div className="option-input">
      <form className="" onSubmit={(e) => optionSubmit(e)}>
        <div className="row">
          <label className="col-8">Description</label>
          <label className="col-4">Price</label>
        </div>
        <div className="row">
          <div className="col-6 ms-3">{productOption.description}</div>

          <div className="col-2">
            <strong>
              {(productOption.price * (1 + productOption.vat)).toFixed(2)}
            </strong>
          </div>
          <button
            className="border border-0 bg-transparent col-1 ms-1"
            type="Submit"
          >
            <i className="bi bi-trash"></i>
          </button>
          <button
            className="border border-0 bg-transparent col-1 ms-1"
            onClick={() => {
              setToggler(null);
              setWorkingOptions(false);
            }}
          >
            <i className="bi bi-x"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeleteProductOption;
