import React, { useContext, useState } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";

const EditUnav = ({ avail, setToggler }) => {
  const controlObject = useContext(ControlContext);
  const shopObject = useContext(ShopContext);

  const [av, setAv] = useState(avail);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAv({ ...av, [name]: value });
  }

  const handleUpdate = (e) => {
    if (av["recurringtype"] === "" || av["recurringtype"] === "N") {
      av["recurringtype"] = "";
      av["recurring"] = false;
    } else {
      av["recurring"] = true;
    }

    if (av["date"]) {
      shopObject.updateavailas(av);
      setToggler(null);
    }
  };

  const handleDelete = (e) => {
    shopObject.deleteavailas(avail);
    setToggler(null);
  };

  const handleCancel = (e) => {
    setToggler(null);
  };

  return (
    <div className="posavline">
      op datum -
      <input
        required
        className=""
        placeholder="date"
        type="date"
        id="date"
        name="date"
        value={av.date}
        onChange={handleChange}
      />
      <button className="cancelbutton" onClick={(e) => handleCancel()}>
        cancel
      </button>
      <button className="editbutton" onClick={(e) => handleUpdate()}>
        Update
      </button>
      <button className="delbutton" onClick={(e) => handleDelete()}>
        Delete
      </button>
    </div>
  );
};

export default EditUnav;
