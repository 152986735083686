import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";

const EditCat = ({ category, toggler, setToggler }) => {
  const shopObject = useContext(ShopContext);

  const [cat, setCat] = useState(category);

  function handler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setCat({ ...cat, [name]: value });
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("updated");
    shopObject.updatecategory(cat);
    setToggler(null);
    shopObject.setreload(!shopObject.reload);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setToggler(null);
  };

  return (
    <>
      {category && (
        <div className="scline">
          <input
            className="sccatname"
            placeholder="category name"
            type="text"
            id="name"
            name="name"
            value={cat.name}
            onChange={handler}
          ></input>
          <button className="btn btn-success btn-sm" onClick={handleUpdate}>
            Update
          </button>
          <button className="btn btn-secondary btn-sm " onClick={handleCancel}>
            cancel
          </button>
        </div>
      )}
    </>
  );
};

export default EditCat;
