import React, { useContext, useState, useEffect, Fragment } from "react";
import ShopContext from "../Store/shop-context";

const ProductCard = ({
  product,
  productoptions,
  clickedProductDetail,
  clickEditProduct,
  clickedDeleteProduct,
  clickedFront,
  clickedLeft,
  clickedRight,
  clickedEnd,
}) => {
  const [options, setOptions] = useState(productoptions);
  const shopObject = useContext(ShopContext);
  const styles = {
    borderStyle: product.active ? "solid" : "none",
  };

  useEffect(() => {
    setOptions(
      shopObject.productoptions
        ? shopObject.productoptions.filter((po) => po.product === product.id)
        : productoptions
    );
  }, [product]);

  return (
    <div className="card" style={styles}>
      <div className="mx-auto" style={{ fontSize: "8px" }}>
        {product.active ? <>active</> : <>not active</>}
      </div>
      <div className="card-position-box">
        {/* <i
          className="bi bi-caret-left-fill"
          onClick={(e) => clickedFront(product.position)}
        ></i> */}
        {shopObject.subcategory === null && (
          <>
            <i
              className="bi bi-caret-left"
              onClick={(e) => clickedLeft(product.position)}
            ></i>
            {product.position}
            <i
              className="bi bi-caret-right"
              onClick={(e) => clickedRight(product.position)}
            ></i>
          </>
        )}
        {shopObject.subcategory !== null && <div>{" - "}</div>}

        {/* <i
          className="bi bi-caret-right-fill"
          onClick={(e) => clickedEnd(product.position)}
        ></i> */}
      </div>
      <div
        className="cardimagebox mx-auto"
        onClick={(e) => clickedProductDetail(product)}
      >
        <img
          className="cardimage"
          src={product.picture}
          alt={product.title}
        ></img>
      </div>
      <div className="card-body m-0 p-1">
        <p className="card-promo">{product.promotion && <>PROMO</>}</p>
        <h5 className="card-title">{product.name}</h5>
        <p className="card-description text-truncate">{product.description}</p>
        <p className="card-sku">SKU {product.sku}</p>
        <p className="card-text">
          Category{" : "}
          {shopObject.categories.filter(
            (cat) => cat.id === product.category
          )[0] &&
            shopObject.categories.filter((cat) => cat.id == product.category)[0]
              .name}
        </p>
        <p className="card-text">
          SubCategory{" : "}
          {shopObject.subcategories.filter(
            (subCat) => subCat.id === product.subcategory
          ).length > 0 && (
            <>
              {
                shopObject.subcategories.filter(
                  (subCat) => subCat.id === product.subcategory
                )[0].name
              }
            </>
          )}
        </p>
        <p className="productcard-settings">
          Settings{"  :"}
          {product.settings && product.settings.length > 0 && (
            <>
              {product.settings.map((prset) => {
                return (
                  <>
                    <div className="ms-2">
                      {shopObject.productsettings
                        .filter((listset) => listset.id === prset)[0]
                        .name.slice(0, 20)}
                    </div>
                  </>
                );
              })}
            </>
          )}
          {/* {shopObject.productsettings.filter(
            (prs) => prs.id == product.settings
          )[0] &&
            shopObject.productsettings
              .filter((prs) => prs.id == product.settings)[0]
              .name.slice(0, 21)} */}
        </p>
        {product.varianttype === "standard" && (
          <>
            <p className="card-text">
              Price{" "}
              <strong>{(product.price * (1 + product.vat)).toFixed(2)}</strong>{" "}
              {product.per}
            </p>
            <p></p>
          </>
        )}
        {product.varianttype === "cuttoweight" && (
          <>
            <p className="card-text">
              Price{" "}
              <strong>{(product.price * (1 + product.vat)).toFixed(2)}</strong>{" "}
              {product.per} - {product.startquantity} -{" "}
              {product.incrementalquantity}
            </p>
          </>
        )}

        <div className="card-options">
          <p className="card-text">Options :</p>
          {options && (
            <>
              {options.map((option, index) => {
                return (
                  <Fragment key={index}>
                    <div className="row ms-3 mt-1 mb-2">
                      {option.description} -{" "}
                      {(option.price * (1 + option.vat)).toFixed(2)}
                    </div>
                  </Fragment>
                );
              })}
            </>
          )}
        </div>

        <div className="scbuttongroup">
          <button
            className="btn btn-success btn-sm"
            onClick={(e) => clickEditProduct(product)}
          >
            edit
          </button>
          <button
            className="btn btn-success btn-sm"
            onClick={(e) => clickedDeleteProduct(product)}
          >
            delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
