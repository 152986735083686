import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import { titles } from "../Data/data";
import GTC from "../Data/GTC";

import AuthContext from "../Store/auth-context";

const Signup = ({ signup, isAuthenticated }) => {
  const [accountCreated, setAccountCreated] = useState(false);
  const [showGTC, setShowGTC] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    title: "",
    firstname: "",
    name: "",
    phone: "",
    password: "",
    re_password: "",
  });

  const authObject = useContext(AuthContext);

  const { email, phone, title, firstname, name, password, re_password } =
    formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === re_password) {
      authObject.signup(
        email,
        phone,
        title,
        firstname,
        name,
        password,
        re_password
      );
      authObject.setactivating(true);
      setAccountCreated(true);
    }
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Ubicopia® - Register</title>
        <meta
          name="description"
          content="Registreer voor het gebruik van Ubicopia®"
        />
        <meta name="robots" content="index,nofollow" />
      </Helmet>
      <div className="container mt-5 mb-5 signuppage">
        {accountCreated && (
          <>
            <p className="fs-2">
              Bedankt om te registreren, je ontvangt een email om je account te
              bevestigen.
            </p>
            <p className="fs-2">
              Vervolgens log in in met je paswoord. Dan wordt je gevraagd je
              profiel te vervolledigen.
            </p>
          </>
        )}
        {!accountCreated && (
          <>
            <h1 className="sutitle">Registreer</h1>
            <p>Ik teken in op een account</p>
            <form onSubmit={(e) => onSubmit(e)}>
              <label className="sulabel">Email</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email*"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                />
              </div>
              <label className="sulabel">
                Telefoon (beginnende met +32 voor België)
              </label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="phone"
                  placeholder="Tel"
                  name="phone"
                  value={phone}
                  onInput={(e) => onChange(e)}
                  pattern="[\+]\d{2}[0-9]\d{7,9}"
                />
              </div>
              <div className="form-group mt-3">
                <select
                  placeholder="Title"
                  type="text"
                  required
                  id="title"
                  name="title"
                  value={title}
                  onInput={(e) => onChange(e)}
                >
                  <option name="title" value="">
                    - titel -
                  </option>
                  {titles &&
                    titles.map((tit, index) => {
                      return (
                        <option name="title" value={tit.code} key={index}>
                          {tit.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group mt-3">
                <label className="sulabel">Voornaam</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="First Name*"
                  name="firstname"
                  value={firstname}
                  onChange={(e) => onChange(e)}
                  pattern="[a-zA-Zéèçà\w/s^<>][a-zA-Zéèçà\w/s^<>]{1,255}$"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label className="sulabel">Achternaam</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name*"
                  name="name"
                  value={name}
                  onChange={(e) => onChange(e)}
                  pattern="[a-zA-Zéèçà\w/s^<>][a-zA-Zéèçà/s^<>]{1,255}$"
                  maxLength="255"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label className="sulabel">Paswoord</label>
                <div className="form-control p-0 d-flex justify-content-between">
                  <input
                    className="form-control"
                    type={passwordType}
                    placeholder="Paswoord*"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    pattern="(?=^.{8,255}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                    required
                  />
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={() => pwToggler()}
                  >
                    {passwordType === "password" ? (
                      <i className="bi bi-eye fs-5"></i>
                    ) : (
                      <i className="bi bi-eye-slash fs-5"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="form-group mt-3">
                <label className="sulabel">Nogmaals je paswoord</label>
                <div className="form-control p-0 d-flex justify-content-between">
                  <input
                    className="form-control"
                    type={passwordType}
                    placeholder="Herhaal paswoord*"
                    name="re_password"
                    value={re_password}
                    onChange={(e) => onChange(e)}
                    pattern={formData.password}
                    required
                  />
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={() => pwToggler()}
                  >
                    {passwordType === "password" ? (
                      <i className="bi bi-eye fs-5"></i>
                    ) : (
                      <i className="bi bi-eye-slash fs-5"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="form-group mt-3">
                <button className="btn btn-primary" type="submit">
                  Registreer
                </button>
                <p>
                  Door te registreren accepteer je de{" "}
                  <button
                    className="bg-black fs-5 text-primary"
                    onClick={() => setShowGTC(true)}
                  >
                    Algemene voorwaarden
                  </button>
                  {showGTC && (
                    <div>
                      <button
                        type="button"
                        className="btn-close text-white"
                        aria-label="Close"
                        onClick={() => setShowGTC(false)}
                      >
                        Close
                      </button>
                      <GTC />
                    </div>
                  )}
                </p>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default Signup;
