import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import AuthContext from "../Store/auth-context";
import ControlContext from "../Store/control-context";

const Header = () => {
  let authObject = useContext(AuthContext);
  let controlObject = useContext(ControlContext);
  var homeURL = process.env.REACT_APP_WEBSITE_URL;

  const logout_user = () => {
    authObject.logoutuser();
  };

  return (
    <>
      <div className="headersection mx-auto">
        <div className="container-fluid navbarDRO">
          <div className="">
            <a
              className="nav-link text-decoration-none text-white"
              href={homeURL}
            >
              Home
            </a>
          </div>
          {authObject.user ? (
            <>
              {!authObject.user.blocked && (
                <>
                  {controlObject.webshoplist && (
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        WebService :{controlObject.webshop.name}
                      </a>
                      <ul className="dropdown-menu navbarDROdropdown">
                        {controlObject.webshoplist.map((ws, index) => {
                          return (
                            <li key={index}>
                              <a
                                className="dropdown-item navbarDROdropdown"
                                href="#"
                                onClick={(e) => controlObject.setwebshop(ws)}
                              >
                                {ws.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  <div className="">
                    <Link className="nav-link" to="/ordercontroller/">
                      Orders
                    </Link>
                  </div>
                  <div className="">
                    <Link className="nav-link" to="/shopcontroller/">
                      Shop
                    </Link>
                  </div>
                  <div className="">
                    <Link className="nav-link" to="/customercontroller/">
                      Marketing
                    </Link>
                  </div>
                  <div className="">
                    <Link className="nav-link" to="/accountingpage/">
                      Accounting
                    </Link>
                  </div>
                </>
              )}

              <div className="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href=""
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  User
                </a>
                <ul className="dropdown-menu navbarDROdropdown">
                  <li>
                    <Link className="dropdown-item" to="/usercontroller/">
                      Your details
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="" onClick={logout_user}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
              <div className="navuser">
                <div className="nav-link">
                  <div>
                    <small>
                      Login as {authObject.user.firstname}{" "}
                      {authObject.user.name}
                    </small>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
