import React from "react";

const Privacy = () => {
  return (
    <div>
      <div>
        DRO Projects bv, gevestigd aan Sint-Hubertusstraat 120, 3300 Oplinter,
        België, is verantwoordelijk voor de verwerking van persoonsgegevens
        zoals weergegeven in deze privacyverklaring. Contactgegevens:
        https://www.droprojects.com https://www.ubicopia.com Sint-Hubertusstraat
        120, 3300 Oplinter, België +32485683511 Dominique Rongé is de
        Functionaris Gegevensbescherming van DRO Projects bvba Hij/zij is te
        bereiken via dominique@droprojects.com of domirong@gmail.com
      </div>
      <div>Persoonsgegevens die wij verwerken</div>
      <p>
        DRO Projects bv verwerkt uw persoonsgegevens doordat u gebruik maakt van
        onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt
        u een overzicht van de persoonsgegevens die wij verwerken: - Voor- en
        achternaam - Geslacht - Geboortedatum - Adresgegevens - Telefoonnummer -
        E-mailadres - IP-adres - Overige persoonsgegevens die u actief verstrekt
        bijvoorbeeld door een profiel op deze website aan te maken, in
        correspondentie en telefonisch - Locatiegegevens - Gegevens over uw
        activiteiten op onze website - Lijst met contactgegevens van de klant
        via een app - Bankrekeningnummer enkel indien nodig voor
        betalingsverkeer.
      </p>
      <div>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</div>
      <p>
        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen
        over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming
        hebben van ouders of voogd. We kunnen echter niet controleren of een
        bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn
        bij de online activiteiten van hun kinderen, om zo te voorkomen dat er
        gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
        Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke
        gegevens hebben verzameld over een minderjarige, neem dan contact met
        ons op via domirong@gmail.com, dan verwijderen wij onmiddellijk deze
        informatie.
      </p>
      <div>
        Met welk doel en op basis van welke grondslag wij persoonsgegevens
        verwerken
      </div>
      <p>
        DROProjects bv verwerkt uw persoonsgegevens voor de volgende doelen: -
        Het afhandelen van uw betaling - Verzenden van onze nieuwsbrief en/of
        reclamefolder - U te kunnen bellen of e-mailen indien dit nodig is om
        onze dienstverlening uit te kunnen voeren - U te informeren over
        wijzigingen van onze diensten en producten - U de mogelijkheid te bieden
        een account aan te maken - Om goederen en diensten bij u af te leveren -
        DRO Projects bvba analyseert uw gedrag op de website om daarmee de
        website te verbeteren en het aanbod van producten en diensten af te
        stemmen op uw voorkeuren. - DRO Projects bvba volgt uw surfgedrag over
        verschillende websites waarmee wij onze producten en diensten afstemmen
        op uw behoefte. - DRO Projects bvba verwerkt ook persoonsgegevens als
        wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig
        hebben voor onze belastingaangifte.
      </p>
      <div>Geautomatiseerde besluitvorming</div>
      <p>
        Momenteel is dit niet van toepassing.
        {/* DRO Projects bvba neemt #responsibility op basis van geautomatiseerde
        verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen
        hebben voor personen. Het gaat hier om besluiten die worden genomen door
        computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld
        een medewerker van DRO Projects bvba) tussen zit. DRO Projects bvba
        gebruikt de volgende computerprogramma's of -systemen: #use_explanation */}
      </p>
      <div>Hoe lang we persoonsgegevens bewaren</div>
      <p>
        DRO Projects bvba bewaart uw persoonsgegevens niet langer dan strikt
        nodig is om de doelen te realiseren waarvoor uw gegevens worden
        verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende
        (categorieën) van persoonsgegevens: 2 jaar - na stopzetten abonnement
        Legale documenten zoals facturen waarop uw gegevens zichtbaar zijn
        worden bewaard zolang als nodig is voor de belgische autoriteiten.
      </p>
      <div>Delen van persoonsgegevens met derden</div>
      <p>
        DRO Projects bvba verstrekt uitsluitend aan derden en alleen als dit
        nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen
        aan een wettelijke verplichting.{" "}
        <strong>Wij zijn geen firma die persoonsgegevens doorverkoopt.</strong>
      </p>

      <div>Cookies, of vergelijkbare technieken, die wij gebruiken</div>
      <p>
        DRO Projects bv gebruikt functionele, analytische en tracking cookies.
        Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
        website wordt opgeslagen in de browser van uw computer, tablet of
        smartphone. DRO Projects bvba gebruikt cookies met een puur technische
        functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en
        dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies
        worden ook gebruikt om de website goed te laten werken en deze te kunnen
        optimaliseren. Daarnaast plaatsen we cookies die uw surfgedrag bijhouden
        zodat we op maat gemaakte content en advertenties kunnen aanbieden. Bij
        uw eerste bezoek aan onze website hebben wij u al geïnformeerd over deze
        cookies en toestemming gevraagd voor het plaatsen ervan. U kunt zich
        afmelden voor cookies door uw internetbrowser zo in te stellen dat deze
        geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die
        eerder is opgeslagen via de instellingen van uw browser verwijderen. Op
        deze website worden ook cookies geplaatst door derden. Dit zijn
        bijvoorbeeld adverteerders en/of de sociale media-bedrijven. Hieronder
        een overzicht: Googly Analytics Naam: _utma Functie: Analytische cookie
        die websitebezoek meet Bewaartermijn: 2 jaar ]
      </p>
      <div>Gegevens inzien, aanpassen of verwijderen</div>
      <p>
        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
        verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming
        voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door DRO Projects bvba en heeft u het
        recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een
        verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in
        een computerbestand naar u of een ander, door u genoemde organisatie, te
        sturen. U kunt een verzoek tot inzage, correctie, verwijdering,
        gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking
        van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
        sturen naar domirong@gmail.com. Om er zeker van te zijn dat het verzoek
        tot inzage door u is gedaan, vragen wij u een kopie van uw
        identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw
        pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het
        paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter
        bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen
        vier weken, op uw verzoek. DRO Projects bvba wil u er tevens op wijzen
        dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale
        toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende
        link: https://www.gegevensbeschermingsautoriteit.be/burger
      </p>
      <div>Hoe wij persoonsgegevens beveiligen</div>
      <p>
        DRO Projects bvba neemt de bescherming van uw gegevens serieus en neemt
        passende maatregelen om misbruik, verlies, onbevoegde toegang,
        ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als
        u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
        aanwijzingen zijn van misbruik, neem dan contact op met onze
        klantenservice of via domirong@gmail.com . Om redenen van cybersecurity
        worden er geen details van de manier van beveiliging bekendgemaakt.
      </p>
    </div>
  );
};

export default Privacy;
