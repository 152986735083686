import React, { useState, useContext } from "react";
import ShopContext from "../Store/shop-context";
import ControlContext from "../Store/control-context";
import { blanklocaddress } from "../Data/data";
import { isocountries } from "../Data/data";

const AddLoc = ({ setToggler }) => {
  let fakeAddress = {
    name: "",
    street: "",
    hnr: "",
    hnrext: "",
    zip: "",
    city: "",
    country: "",
  };
  const shopObject = useContext(ShopContext);
  const controlObject = useContext(ControlContext);

  const [loc, setLoc] = useState(fakeAddress);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setLoc({ ...loc, [name]: value });
  }

  const handleCreate = (e) => {
    e.preventDefault();
    loc["shop"] = controlObject.webshop.id;
    shopObject.createlocs(loc);
    setToggler(false);
  };

  const handleCancel = (e) => {
    setToggler(false);
  };

  return (
    <form className="poslocgroup" onSubmit={handleCreate}>
      <div className="poslocline input-group row">
        <input
          className="form-control poslocname col-2"
          placeholder="name"
          type="text"
          required
          id="name"
          name="name"
          default="test"
          value={loc.name}
          onChange={handleChange}
        />
        <input
          className="form-control col-2"
          placeholder="street"
          type="text"
          required
          id="name"
          name="street"
          value={loc.street}
          onChange={handleChange}
        />
        <div className="col-2 d-flex flex-row g-0">
          <input
            className="form-control"
            placeholder="number"
            type="text"
            size="2"
            required
            id="hnr"
            name="hnr"
            value={loc.hnr}
            onChange={handleChange}
          />
          <input
            className="form-control"
            placeholder="ext"
            type="text"
            size="2"
            id="hnrext"
            name="hnrext"
            value={loc.hnrext}
            onChange={handleChange}
          />
          <input
            className="form-control"
            placeholder="zip"
            type="text"
            size="3"
            required
            id="zip"
            name="zip"
            value={loc.zip}
            onChange={handleChange}
          />
        </div>
        <input
          className="form-control col-1"
          placeholder="city"
          type="text"
          size="10"
          required
          id="city"
          name="city"
          value={loc.city}
          onChange={handleChange}
        />
        <select
          className="form-control col-1"
          placeholder="country"
          type="text"
          required
          id="country"
          name="country"
          value={loc.country}
          onChange={handleChange}
        >
          <option name="country" value="">
            --select country--
          </option>
          {isocountries &&
            isocountries.map((ic, index) => {
              return (
                <option name="country" value={ic.code} key={index}>
                  {ic.name}
                </option>
              );
            })}
        </select>
        <div className="col-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => handleCancel()}
          >
            cancel
          </button>
          <button
            type="submit"
            className="ms-1 btn btn-success"
            onClick={(e) => handleCreate()}
          >
            Add
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddLoc;
