import React from "react";

const PrintButton = () => {
  return (
    <button
      className="btn btn-primary btn-sm printbutton"
      onClick={() => {
        window.print();
      }}
    >
      Print Page
    </button>
  );
};

export default PrintButton;
