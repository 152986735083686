import React from "react";

const DeleteAddress = ({ add, user, getaddresslist, settoggler }) => {
  let delAdd = async (add) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/address/delete/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(add),
      }
    );
    if (resp.status === 200) {
      console.log("Address has been deleted");
      getaddresslist(user.id);
      settoggler(null);
    } else {
      alert("something went wrong deleting address");
    }
  };
  return (
    <div>
      <div>Are you sure ?</div>
      <div className="row">
        <button
          className="btn btn-secondary col-2"
          onClick={(e) => settoggler(null)}
        >
          Cancel
        </button>
        <button className="btn btn-danger col-2" onClick={(e) => delAdd(add)}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteAddress;
