import React, { useContext, useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import AuthContext from "../Store/auth-context";
import ControlContext from "../Store/control-context";

//Segments
import UserUserController from "../Segments/UserUserController";
import UserOrgController from "../Segments/UserOrgController";
import UserShopController from "../Segments/UserShopController";
import UserAddressController from "../Segments/UserAddressController";

import ShowAddress from "../Components/ShowAddress";

import EditAddress from "../Components/EditAddress";
import CreateAddress from "../Components/CreateAddress";
import DeleteAddress from "../Components/DeleteAddress";
import SelectAddress from "../Components/SelectAddress";
import CreateShop from "../Components/CreateShop";

const UserController = () => {
  let authObject = useContext(AuthContext);
  let controlObject = useContext(ControlContext);

  //main content
  const [shower, setShower] = useState(null);

  const [invAdd, setInvAdd] = useState();
  const [corrAdd, setCorrAdd] = useState();

  useEffect(() => {
    if (authObject.organisation) {
      authObject.getaddresslist(authObject.user.id);
    }
    if (authObject.addresslist && authObject.organisation.invoice_address) {
      setInvAdd(
        authObject.addresslist.filter(
          (add) => add.id === authObject.organisation.invoice_address
        )[0]
      );
      setCorrAdd(
        authObject.addresslist.filter(
          (add) => add.id === authObject.organisation.corr_address
        )[0]
      );
    }
    console.log("inv :", authObject.organisation.invoice_address);
    console.log("corr :", authObject.organisation.corr_address);
    console.log("adresslist :", authObject.addresslist);
  }, []);

  useEffect(() => {
    if (authObject.addresslist && authObject.organisation.invoice_address) {
      setInvAdd(
        authObject.addresslist.filter(
          (add) => add.id === authObject.organisation.invoice_address
        )[0]
      );
      setCorrAdd(
        authObject.addresslist.filter(
          (add) => add.id === authObject.organisation.corr_address
        )[0]
      );
    }
  }, [
    controlObject.webshoplist,
    authObject.organisation,
    authObject.addresslist,
  ]);

  return (
    <>
      <Helmet>
        <title>Ubicopia®-User Controller</title>
        <meta name="description" content="Control your data" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {authObject.user && (
        <div className="userpage">
          {authObject.organisation && (
            <>
              <div className="ctitle">Your Details</div>
              <button
                className="btn btn-secondary btn-sm ms-3"
                onClick={() => setShower("user")}
              >
                Userdetails
              </button>
              <button
                className="btn btn-secondary btn-sm ms-3"
                onClick={() => setShower("org")}
              >
                Organisation details
              </button>
              <button
                className="btn btn-secondary btn-sm ms-3"
                onClick={() => setShower("shop")}
              >
                Webshop details
              </button>
              <button
                className="btn btn-secondary btn-sm ms-3"
                onClick={() => setShower("address")}
              >
                Address details
              </button>
              {shower === "user" && (
                <UserUserController user={authObject.user} />
              )}
              {shower === "org" && (
                <UserOrgController organisation={authObject.organisation} />
              )}
              {shower === "shop" && (
                <>
                  <UserShopController
                    organisation={authObject.organisation}
                    getwebshoplist={controlObject.getwebshoplist}
                    webshoplist={controlObject.webshoplist}
                  />
                </>
              )}
              {shower === "address" && (
                <>
                  <UserAddressController
                    user={authObject.user}
                    organisation={authObject.organisation}
                    getaddresslist={authObject.getaddresslist}
                    getuserdetails={authObject.getuserdetails}
                    addresslist={authObject.addresslist}
                    invAdd={invAdd}
                    corrAdd={corrAdd}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UserController;
