import React, { useState, useEffect } from "react";
import AllergenBox from "./AllergenBox";

import { blankAllergens } from "../Data/data";

import eieren from "../Media/allergens/ei.png";
import gluten from "../Media/allergens/gluten.png";
import lupine from "../Media/allergens/lupine.png";
import melk from "../Media/allergens/melk.png";
import mosterd from "../Media/allergens/mosterd.png";
import pinda from "../Media/allergens/pinda.png";
import schaaldieren from "../Media/allergens/schaaldier.png";
import schaalvruchten from "../Media/allergens/schaalvrucht.png";
import selder from "../Media/allergens/selder.png";
import sesam from "../Media/allergens/sesam.png";
import soya from "../Media/allergens/soya.png";
import sulfiet from "../Media/allergens/sulfiet.png";
import vis from "../Media/allergens/vis.png";
import weekdier from "../Media/allergens/weekdier.png";

const ShowAllergens = ({ product, webshopid, update, setUpdate }) => {
  const [allergens, setAllergens] = useState(blankAllergens);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    getAllergens(product);
  }, [product]);

  let getAllergens = async (product) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/allergens/get/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          product: product,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setAllergens(data);
      console.log("data :", data);
      console.log("allergens loaded");
    } else {
      alert("something went wrong checking your details");
    }
  };

  let updateAllergen = async (product, allergens) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/allergens/",
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          product: product,
          allergens: allergens,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("allergens updated");
      setUpdate(!update);
      setChanged(false);
    } else {
      alert("something went wrong checking your details");
    }
  };

  let deleteAllergen = async (product, allergens) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/product/allergens/",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          webshopid: webshopid,
          product: product,
          allergens: allergens,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("allergens deleted");
      setUpdate(!update);
    } else {
      alert("something went wrong checking your details");
    }
  };

  function handler(name, option) {
    setChanged(true);
    let value = !option;
    // setUpdating(true);
    setAllergens({ ...allergens, [name]: value });
  }

  function texthandler(e) {
    setChanged(true);
    const name = e.target.name;
    const value = e.target.value;
    setAllergens({ ...allergens, [name]: value });
  }

  function upAllergen(e) {
    e.preventDefault();
    console.log("Updating:", allergens);
    updateAllergen(product, allergens);
  }

  function delAllergen(e) {
    e.preventDefault();
    deleteAllergen(product, allergens);
  }

  return (
    <form onSubmit={(e) => upAllergen(e)}>
      <div className="mt-3">
        Lijst met allergenen
        <div>
          <AllergenBox
            image={gluten}
            allergen="glutenbevattende granen"
            name="gluten"
            value={allergens.gluten}
            handler={handler}
          />
          <AllergenBox
            image={schaaldieren}
            allergen="schaaldieren"
            name="schaaldieren"
            value={allergens.schaaldieren}
            handler={handler}
          />
          <AllergenBox
            image={eieren}
            allergen="eieren"
            name="ei"
            value={allergens.ei}
            handler={handler}
          />
          <AllergenBox
            image={vis}
            allergen="vis"
            name="vis"
            value={allergens.vis}
            handler={handler}
          />
          <AllergenBox
            image={pinda}
            allergen="pinda's (aardnoten) "
            name="pinda"
            value={allergens.pinda}
            handler={handler}
          />
          <AllergenBox
            image={soya}
            allergen="soya"
            name="soja"
            value={allergens.soja}
            handler={handler}
          />
          <AllergenBox
            image={melk}
            allergen="melk en zuivelproducten"
            name="melk"
            value={allergens.melk}
            handler={handler}
          />
          <AllergenBox
            image={schaalvruchten}
            allergen="schaalvruchten"
            name="schaalvruchten"
            value={allergens.schaalvruchten}
            handler={handler}
          />
          <AllergenBox
            image={selder}
            allergen="selder"
            name="selder"
            value={allergens.selder}
            handler={handler}
          />
          <AllergenBox
            image={mosterd}
            allergen="mosterd"
            name="mosterd"
            value={allergens.mosterd}
            handler={handler}
          />
          <AllergenBox
            image={sesam}
            allergen="sesamzaad"
            name="sesam"
            value={allergens.sesam}
            handler={handler}
          />
          <AllergenBox
            image={sulfiet}
            allergen="sulfieten"
            name="sulfiet"
            value={allergens.sulfiet}
            handler={handler}
          />
          <AllergenBox
            image={lupine}
            allergen="lupine"
            name="lupine"
            value={allergens.lupine}
            handler={handler}
          />
          <AllergenBox
            image={weekdier}
            allergen="weekdieren ex. mosselen en oesters"
            name="week"
            value={allergens.week}
            handler={handler}
          />
          <div className="my-3">
            <label className="form-label">Opmerkingen :</label>
            <input
              className="form-control"
              type="text"
              name="remarks"
              value={allergens.remarks}
              onChange={texthandler}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col"></div>
        <div className="col d-flex justify-content-end">
          {changed === true && (
            <>
              <button className="btn btn-success btn-sm" type="submit">
                update
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-danger btn-sm ms-2"
            onClick={(e) => delAllergen(e)}
          >
            delete
          </button>
        </div>
      </div>
    </form>
  );
};

export default ShowAllergens;
