import React, { useState } from "react";
import CLine from "./CLine";

const ShowOrg = ({ organisation, settoggler }) => {
  const [showWL, setShowWL] = useState(false);
  const [showPNL, setShowPNL] = useState(false);
  if (organisation.generalconditions) {
    if (organisation.generalconditions.length > 20) {
      organisation.generalconditions =
        organisation.generalconditions.slice(0, 20) + "...";
    }
  }

  return (
    <div>
      {organisation && (
        <>
          <div className="userblock bg-light rounded-2 p-3">
            <div className="row">
              <div className="usersubtitle col-10">
                Your organisation details
              </div>
            </div>
            <CLine t="name" v={organisation.name} />
            <CLine t="brand" v={organisation.brand} />
            <div className="">Small logo (1:1)</div>
            <img
              className="img-thumbnail thumb11"
              src={organisation.logosmall}
            ></img>
            <div className="">Large logo (4:1)</div>
            <img
              className="img-thumbnail thumb41"
              src={organisation.logolarge}
            ></img>
            <CLine t="goods" v={organisation.goodsdescription} />
            <CLine t="general conditions" v={organisation.generalconditions} />
            <CLine t="VAT" v={organisation.vat} />
            <CLine t="Bank" v={organisation.bankiban} />
            <CLine t="BIC" v={organisation.bankbic} />

            <div className="mt-3">
              <button
                type="button"
                className="fw-bold mt-3"
                onClick={() => setShowWL(!showWL)}
              >
                show/hide Worldline - Account details
              </button>
            </div>
            {showWL && (
              <>
                <div className="mt-2">
                  Worldline E-commerce payments sandbox account
                </div>
                <div className="ms-3">
                  <CLine
                    t="Sandbox - Worldline customer ID"
                    v={organisation.sandbox_saferpaycustid}
                  />
                  <CLine
                    t="Sandbox - Worldline terminal ID"
                    v={organisation.sandbox_saferpayterminalid}
                  />
                  <CLine
                    t="Sandbox - Worldline BASE64 header"
                    v={organisation.sandbox_saferpayheader}
                  />
                </div>
                <div className="mt-2">
                  Worldline E-commerce payments real world account
                </div>
                <div className="ms-3">
                  <CLine
                    t="Worldline customer ID"
                    v={organisation.saferpaycustid}
                  />
                  <CLine
                    t="Worldline terminal ID"
                    v={organisation.saferpayterminalid}
                  />
                  <CLine
                    t="Worldline BASE64 header"
                    v={organisation.saferpayheader}
                  />
                </div>
              </>
            )}

            <div className="mt-3">
              <button
                type="button"
                className="fw-bold mt-3"
                onClick={() => setShowPNL(!showPNL)}
              >
                show/hide PostNL - Account details
              </button>
            </div>
            {showPNL && (
              <>
                <div className="mt-2">Post NL details</div>
                <div className="ms-3">
                  <CLine
                    t="PostNL sandbox API key"
                    v={organisation.api_pnl_sandbox}
                  />
                  <CLine
                    t="PostNL realworld API key"
                    v={organisation.api_pnl_real}
                  />
                  <CLine
                    t="PostNL collection location code"
                    v={organisation.pnl_collectionlocation}
                  />
                  <CLine
                    t="PostNL customer code"
                    v={organisation.pnl_customercode}
                  />
                  <CLine
                    t="PostNL customer number"
                    v={organisation.pnl_customernumber}
                  />
                </div>
              </>
            )}

            <div className="row">
              <div className="col-10"></div>
              <button
                className="btn btn-primary btn-sm col-2"
                onClick={(e) => settoggler(true)}
              >
                Edit
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShowOrg;
