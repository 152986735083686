import React, { useEffect } from "react";
import CLine from "./CLine";

const ShowShop = ({ shop, settoggler }) => {
  return (
    <div>
      <div className="usersubtitle col-10">{shop.brand}</div>

      <CLine t="brand" v={shop.brand} />
      <CLine t="currency" v={shop.currency} />
      <div>Small logo (1:1)</div>
      {shop.logosmall && (
        <img className="img-thumbnail thumb11" src={shop.logosmall}></img>
      )}
      {!shop.logosmall && <>None</>}
      <div>Large logo (4:1)</div>
      {shop.logolarge && (
        <img className="img-thumbnail thumb14" src={shop.logolarge}></img>
      )}
      {!shop.logolarge && <>None</>}
      <div className="row">
        <div className="col-10"></div>
        <button
          className="btn btn-primary btn-sm col-2"
          onClick={(e) => settoggler(shop.id)}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default ShowShop;
