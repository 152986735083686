import { createContext, useState, useEffect } from "react";

import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
  user: null,
  setuser: () => {},

  organisation: null,
  setorganisation: () => {},

  addresslist: null,
  getaddresslist: () => {},

  accesstoken: null,
  loginuser: () => {},
  logoutuser: () => {},
  signup: () => {},
  verifyaccount: () => {},
  activating: [],
  setactivating: () => {},
  getuserdetails: () => {},
  updateuserdetails: () => {},
  verified: [],
  setverified: () => {},

  resetpassword: () => {},
  resetpasswordconfirm: () => {},
});

export function AuthProvider(props) {
  let [accessToken, setAccessToken] = useState(() =>
    localStorage.getItem("access") ? localStorage.getItem("access") : null
  );
  let [refreshToken, setRefreshToken] = useState(() =>
    localStorage.getItem("refresh") ? localStorage.getItem("refresh") : null
  );

  let [user, setUser] = useState();
  let [organisation, setOrganisation] = useState();
  let [addressList, setAddressList] = useState();
  let [loading, setLoading] = useState(true);
  let [activating, setActivating] = useState();
  let [verified, setVerified] = useState(false);

  const navigate = useNavigate();

  // checked
  const signUp = async (
    email,
    phone,
    title,
    firstname,
    name,
    password,
    re_password
  ) => {
    var url = process.env.REACT_APP_API_URL + "capi/users/register/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        phone,
        title,
        firstname,
        name,
        password,
        re_password,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  // checked
  const verifyAccount = async (uid, token) => {
    var url = `${process.env.REACT_APP_API_URL}capi/users/verify/${uid}/${token}/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      // .then((resp) => resp.json())
      .then((resp) => {
        if (resp.ok) {
          setVerified(true);
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const resetPassword = async (email) => {
    var url = process.env.REACT_APP_API_URL + "capi/users/password-reset/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received:");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  //checked
  const resetPasswordConfirm = async (
    uid,
    token,
    new_password,
    re_new_password
  ) => {
    var url =
      process.env.REACT_APP_API_URL + "capi/users/password-reset-confirm/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, token, new_password, re_new_password }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  // checked
  const loginUser = async (email, password) => {
    var response = await fetch(
      process.env.REACT_APP_API_URL + "auth/jwt/create/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          usercode: "backend_" + email,
          password: password,
        }),
      }
    );
    let data = await response.json();

    if (response.status === 200) {
      setAccessToken(data.access);
      setRefreshToken(data.refresh);
      localStorage.setItem("access", data.access);
      localStorage.setItem("refresh", data.refresh);
      setActivating(false);
      navigate("/welcome/");
    }
  };

  //Checked
  const logoutUser = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    navigate("/");
  };

  //Checked
  const updateToken = async () => {
    const updateBody = { refresh: localStorage.getItem("refresh") };
    let response = await fetch(
      process.env.REACT_APP_API_URL + "auth/jwt/refresh/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateBody),
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      setAccessToken(data.access);
      localStorage.setItem("access", data.access);
    } else {
      if (activating === false) {
        logoutUser();
      }
    }
    if (loading) {
      setLoading(false);
    }
  };

  let getUserDetails = async (userid) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/userdetails/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          userid: userid,
        }),
      }
    );
    if (resp.status === 200) {
      let data = await resp.json();
      setUser(data);
      getOrganisationDetails(userid);
      getAddressList(userid);
    } else {
      if (activating === false) {
        logoutUser();
      }
    }
  };

  let updateUserDetails = async (user) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/users/update/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          user: user,
        }),
      }
    );
    if (resp.status === 200) {
      console.log("userdetails updated");
      setUser(user);
    } else {
      console.log();
    }
  };

  let getOrganisationDetails = async (userid) => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "capi/organisation/details/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          userid: userid,
        }),
      }
    );
    let data = await response.json();
    setOrganisation(data);
  };

  let getAddressList = async (userid) => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "capi/address/list/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify({
          userid: userid,
        }),
      }
    );
    let data = await response.json();
    setAddressList(data);
  };

  let context = {
    user: user,
    setuser: setUser,

    addresslist: addressList,
    getaddresslist: getAddressList,

    organisation: organisation,
    setorganisation: setOrganisation,

    accesstoken: accessToken,
    loginuser: loginUser,
    logoutuser: logoutUser,
    signup: signUp,
    verifyaccount: verifyAccount,
    activating: activating,
    setactivating: setActivating,
    getuserdetails: getUserDetails,
    updateuserdetails: updateUserDetails,
    verified: verified,
    setverified: setVerified,
    resetpassword: resetPassword,
    resetpasswordconfirm: resetPasswordConfirm,
  };

  // set accesstoken timing here
  useEffect(() => {
    console.log("ran auth-context Usefffect 1");
    if (loading) {
      updateToken();
    }
    let fourMinutes = 1000 * 60 * 30; // --> HERE
    let interval = setInterval(() => {
      if (refreshToken) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [accessToken, loading]);

  useEffect(() => {
    console.log("ran auth-context Usefffect 2");
    if (localStorage.getItem("access")) {
      let access = localStorage.getItem("access");
      getUserDetails(jwt_decode(access).user_id);
    }
  }, [accessToken]);

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
