import React, { useState, useContext, Fragment } from "react";
import { useEffect } from "react";
import ControlContext from "../Store/control-context";

import ShowProductSetting from "../Components/ShowProductSetting";
import AddProductSetting from "../Components/AddProductSetting";
import EditProductSetting from "../Components/EditProductSetting";
import DeleteProductSetting from "../Components/DeleteProductSetting";

const ProductSettings = () => {
  const [prodSetList, setProdSetList] = useState([]);
  const [reload, setReload] = useState(false);

  const [clickedAdd, setClickedAdd] = useState(false);
  const [clickedEdit, setClickedEdit] = useState(null);
  const [clickedDelete, setClickedDelete] = useState(null);

  const controlObject = useContext(ControlContext);

  useEffect(() => {
    let getProductSettings = async () => {
      let resp = await fetch(
        process.env.REACT_APP_API_URL + "capi/product/settings/list/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + String(localStorage.getItem("access")),
          },
          body: JSON.stringify({
            webshopid: controlObject.webshop.id,
          }),
        }
      );
      if (resp.status === 200) {
        let data = await resp.json();
        setProdSetList(data);
      } else {
        alert("something went wrong checking your shopoptions");
      }
    };

    getProductSettings();
  }, [reload, controlObject.webshop]);

  return (
    <div>
      <div className="scline">
        <div className="scsubtitle">ProductSettings</div>
        <button
          className="btn btn-success btn-sm scaddbutton"
          onClick={() => setClickedAdd(true)}
        >
          Add
        </button>
      </div>

      {prodSetList && (
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Leadtime</th>
              <th>Orderdate</th>
              <th>Deliverydate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clickedAdd && (
              <AddProductSetting
                toggler={clickedAdd}
                setToggler={setClickedAdd}
                reload={reload}
                setReload={setReload}
              />
            )}

            {prodSetList
              .sort((a, b) => b.id - a.id)
              .map((prodset, index) => {
                return (
                  <Fragment key={index}>
                    {clickedEdit !== prodset.id &&
                      clickedDelete !== prodset.id && (
                        <>
                          <ShowProductSetting
                            prodset={prodset}
                            setClickedEdit={setClickedEdit}
                            setClickedDelete={setClickedDelete}
                          />
                        </>
                      )}
                    {clickedEdit === prodset.id && (
                      <EditProductSetting
                        productSetting={prodset}
                        toggler={clickedEdit}
                        setToggler={setClickedEdit}
                        reload={reload}
                        setReload={setReload}
                      />
                    )}
                    {clickedDelete === prodset.id && (
                      <DeleteProductSetting
                        prodset={prodset}
                        toggler={clickedDelete}
                        setToggler={setClickedDelete}
                        reload={reload}
                        setReload={setReload}
                      />
                    )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProductSettings;
