import React, { Fragment, useContext, useState } from "react";
import ShopContext from "../Store/shop-context";

import AddLoc from "../Components/AddLoc";
import EditLoc from "../Components/EditLoc";

import AddAv from "../Components/AddAv";
import EditAv from "../Components/EditAv";
import AddUnav from "../Components/AddUnav";
import EditUnav from "../Components/EditUnav";

import AddSlot from "../Components/AddSlot";
import EditSlot from "../Components/EditSlot";

import DisplayDatefromPython from "../Components/DisplayDatefromPython";

const PosView = () => {
  const shopObject = useContext(ShopContext);

  const [clickedAddAv, setClickedAddAv] = useState(null);
  const [clickedAddUnAv, setClickedAddUnAv] = useState(null);
  const [clickedEditAv, setClickedEditAv] = useState(null);
  const [clickedDelAv, setClickedDelAv] = useState(null);
  const [clickedAddLoc, setClickedAddLoc] = useState(false);
  const [clickedEditLoc, setClickedEditLoc] = useState(null);
  const [clickedDelLoc, setClickedDelLoc] = useState(null);
  const [clickedAddSlot, setClickedAddSlot] = useState(false);
  const [clickedEditSlot, setClickedEditSlot] = useState(null);
  const [clickedDelSlot, setClickedDelSlot] = useState(null);

  // values : null, addav,editav,delav,   addloc,editloc,dellot, addslot,editslot,delslot

  const addAv = (loc) => {
    setClickedAddAv(loc);
  };
  const addUnAv = (loc) => {
    setClickedAddUnAv(loc);
  };
  const editAv = (av) => {
    setClickedEditAv(av);
  };
  const delAv = (av) => {
    setClickedDelAv(av);
  };

  const addLoc = () => {
    setClickedAddLoc(true);
  };
  const editLoc = (loc) => {
    setClickedEditLoc(loc);
  };
  const delLoc = (loc) => {
    setClickedDelLoc(loc);
  };

  const addSlot = (av) => {
    setClickedAddSlot(av);
  };
  const editSlot = (slot) => {
    setClickedEditSlot(slot);
  };
  const delSlot = (slot) => {
    setClickedDelSlot(slot);
  };

  return (
    <div>
      <div className="scsubtitle">Overview</div>
      <div className="">
        <div className="poslocline">
          <div className="postitle">Locaties</div>
          {clickedAddLoc === false && (
            <button className="addbutton" onClick={(e) => addLoc()}>
              +
            </button>
          )}
        </div>
        {clickedAddLoc && <AddLoc setToggler={setClickedAddLoc} />}

        {shopObject.locs.map((loc, index1) => {
          return (
            <Fragment key={index1}>
              <>
                <div className="poslocgroup">
                  {clickedEditLoc === loc && (
                    <EditLoc location={loc} setToggler={setClickedEditLoc} />
                  )}
                  {clickedEditLoc !== loc && (
                    <button
                      className="poslocbutton"
                      onClick={(e) => editLoc(loc)}
                    >
                      <div className="poslocline">
                        <div className="poslocname">{loc.name}</div>

                        <div className="">
                          {loc.street} {loc.hnr} {loc.hnrext},{loc.zip}{" "}
                          {loc.city}, {loc.country}
                        </div>
                      </div>
                    </button>
                  )}

                  <div className="posavline">
                    <div className="postitle">Beschikbaar</div>
                    {clickedAddAv !== loc && (
                      <button className="addbutton" onClick={(e) => addAv(loc)}>
                        +
                      </button>
                    )}
                  </div>
                  {clickedAddAv === loc && (
                    <AddAv
                      location={loc}
                      setToggler={setClickedAddAv}
                      unavailable={false}
                    />
                  )}
                  <div className="posavgroup">
                    {shopObject.availas
                      .filter(
                        (av) =>
                          av.location === loc.id && av.unavailable === false
                      )
                      .map((av, index2) => {
                        return (
                          <Fragment key={index2}>
                            {clickedEditAv === av && (
                              <EditAv
                                avail={av}
                                setToggler={setClickedEditAv}
                              />
                            )}

                            <>
                              <div>
                                {clickedEditAv !== av && (
                                  <button
                                    className="posavtruebutton"
                                    onClick={(e) => editAv(av)}
                                  >
                                    {!av.unavailable && (
                                      <>
                                        beschikbaar op{" "}
                                        <DisplayDatefromPython date={av.date} />
                                        {!av.recurring && <>, niet herhaald</>}
                                        {av.recurring && (
                                          <>
                                            , herhaald elke
                                            {av.recurringtype === "1" && (
                                              <>
                                                {" "}
                                                week, tot{" "}
                                                <DisplayDatefromPython
                                                  date={av.dateto}
                                                />
                                              </>
                                            )}
                                            {(av.recurringtype === "2" ||
                                              av.recurringtype === "3" ||
                                              av.recurringtype === "4") && (
                                              <>
                                                {" "}
                                                {av.recurringtype} weken, tot{" "}
                                                <DisplayDatefromPython
                                                  date={av.dateto}
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </button>
                                )}
                                <div className="posslotgroup">
                                  <div className="posslotline">
                                    <div className="postitle">Tijdslots</div>
                                    {clickedAddSlot !== av && (
                                      <button
                                        className="addbutton"
                                        onClick={(e) => addSlot(av)}
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                  {clickedAddSlot === av && (
                                    <AddSlot
                                      avail={av}
                                      setToggler={setClickedAddSlot}
                                    />
                                  )}
                                  {shopObject.slots
                                    .filter((sl) => sl.availability === av.id)
                                    .map((slot, index3) => {
                                      return (
                                        <Fragment key={index3}>
                                          {clickedEditSlot === slot && (
                                            <EditSlot
                                              oldslot={slot}
                                              setToggler={setClickedEditSlot}
                                            />
                                          )}
                                          {clickedEditSlot !== slot && (
                                            <button
                                              className="posslotbutton"
                                              onClick={(e) => editSlot(slot)}
                                            >
                                              <div>
                                                van: {slot.slotfrom.slice(0, 5)}{" "}
                                                tot: {slot.slotto.slice(0, 5)}{" "}
                                                {slot.maxbookings && (
                                                  <>
                                                    max: {slot.maxbookings}{" "}
                                                    bookings
                                                  </>
                                                )}
                                              </div>
                                            </button>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                </div>
                              </div>
                            </>
                          </Fragment>
                        );
                      })}
                  </div>

                  {shopObject.availas.filter(
                    (av) => av.location === loc.id && av.unavailable === true
                  ) && (
                    <>
                      <div className="posavline">
                        <div className="postitle">Niet beschikbaar</div>
                        {clickedAddUnAv !== loc && (
                          <button
                            className="addbutton"
                            onClick={(e) => addUnAv(loc)}
                          >
                            +
                          </button>
                        )}
                      </div>
                      {clickedAddUnAv === loc && (
                        <AddUnav
                          location={loc}
                          setToggler={setClickedAddUnAv}
                          unavailable={true}
                        />
                      )}
                    </>
                  )}
                  <div className="posavgroup">
                    {shopObject.availas
                      .filter(
                        (av) =>
                          av.location === loc.id && av.unavailable === true
                      )
                      .map((av, index2) => {
                        return (
                          <div className="mb-2" key={index2}>
                            {clickedEditAv === av && (
                              <EditUnav
                                avail={av}
                                setToggler={setClickedEditAv}
                              />
                            )}

                            <div>
                              {clickedEditAv !== av && (
                                <button
                                  className="posavfalsebutton mb-2"
                                  onClick={(e) => editAv(av)}
                                >
                                  {av.unavailable && (
                                    <>
                                      Niet beschikbaar op{" "}
                                      <DisplayDatefromPython date={av.date} />
                                    </>
                                  )}
                                </button>
                              )}
                              {/* <div className="posslotgroup">
                                  <div className="posslotline">
                                    <div className="postitle">Tijdslots</div>
                                    {clickedAddSlot !== av && (
                                      <button
                                        className="addbutton"
                                        onClick={(e) => addSlot(av)}
                                      >
                                        +
                                      </button>
                                    )}
                                  </div>
                                  {clickedAddSlot === av && (
                                    <AddSlot
                                      avail={av}
                                      setToggler={setClickedAddSlot}
                                    />
                                  )}
                                  {shopObject.slots
                                    .filter((sl) => sl.availability === av.id)
                                    .map((slot, index3) => {
                                      return (
                                        <Fragment key={index3}>
                                          {clickedEditSlot === slot && (
                                            <EditSlot
                                              oldslot={slot}
                                              setToggler={setClickedEditSlot}
                                            />
                                          )}
                                          {clickedEditSlot !== slot && (
                                            <button
                                              className="posslotbutton"
                                              onClick={(e) => editSlot(slot)}
                                            >
                                              van :{slot.slotfrom.slice(0, 5)}{" "}
                                              tot :{slot.slotto.slice(0, 5)}
                                            </button>
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                </div> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PosView;
