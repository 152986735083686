import React, { useState, useContext } from "react";

import AuthContext from "../Store/auth-context";

const CreateOrg = ({ user }) => {
  const [orgData, setOrgData] = useState({
    name: "",
    brand: "",
    goodsdescription: "",
    generalconditions: "",
    logosmall: "",
    logolarge: "",
    invoice_address: "",
    corr_address: "",
    vat: "",
    bankiban: "",
    bankbic: "",
    saferpayterminalid: "",
    saferpayheader: "",
    saferpaycustid: "",
  });

  const authObject = useContext(AuthContext);

  let createOrg = async (org) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/organisation/",
      {
        method: "POST",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: org,
      }
    );
    if (resp.status === 200) {
      console.log("Organisation has been created");
      authObject.getuserdetails(user.id);
    } else {
      alert("something went wrong checking your details");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setOrgData({ ...orgData, [fieldname]: value });
  };

  const imageHandler = (e) => {
    e.preventDefault();
    const fieldname = e.target.name;
    const value = e.target.files[0];
    setOrgData({ ...orgData, [fieldname]: value });
  };

  const orgSubmit = (e) => {
    e.preventDefault();
    const newOrg = new FormData();
    newOrg.append("name", orgData.name);
    newOrg.append("brand", orgData.brand);
    newOrg.append("goodsdescription", orgData.goodsdescription);
    newOrg.append("generalconditions", orgData.generalconditions);
    newOrg.append("logosmall", orgData.logosmall);
    newOrg.append("logolarge", orgData.logolarge);
    newOrg.append("vat", orgData.vat);
    newOrg.append("bankiban", orgData.bankiban);
    newOrg.append("bankbic", orgData.bankbic);
    newOrg.append("saferpayterminalid", orgData.saferpayterminalid);
    newOrg.append("saferpayheader", orgData.saferpayheader);
    newOrg.append("saferpaycustid", orgData.saferpaycustid);
    createOrg(newOrg);
  };

  return (
    <div className="w-50">
      <h1 className="">Please continue to setup your organisation</h1>
      <p>This will be required before we can continue</p>
      <form onSubmit={(e) => orgSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">Official name of organisation</label>
          <input
            className="form-control"
            type="text"
            placeholder="Name*"
            name="name"
            value={orgData.name}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{1,100}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">VAT number - BEXXXXXXXXXX (no spaces)</label>
          <input
            className="form-control"
            type="text"
            placeholder="VAT*"
            name="vat"
            value={orgData.vat}
            onChange={handler}
            pattern="[A-Z]{2}[0-9]{1,20}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Brand name of organisation (for emails, letters, ... to customers) -
            min lengte 6 digits
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Brand Name*"
            name="brand"
            value={orgData.brand}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{6,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Description of goods you want to trade via the Ubicopia channel
            (food, computers, clothing,....)
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Goods description*"
            name="goodsdescription"
            value={orgData.goodsdescription}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{2,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            Your general conditions - to show in your online store (you can
            refer to your website)
          </label>
          <textarea
            className="form-control"
            type="text"
            placeholder="General conditions*"
            name="generalconditions"
            value={orgData.generalconditions}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà\-\s^<>]{6,1000}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Logo - small - 100px x 100px</label>
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Logo Small"
            name="logosmall"
            id="logosmall"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Logo - wide - 400px x 100px</label>
          <input
            className="form-control"
            type="file"
            accept="image/jpeg,image/png,image/gif"
            placeholder="Logo large"
            name="logolarge"
            id="logolarge"
            onChange={imageHandler}
          />
        </div>
        <div className="form-group mt-3">
          <h3>For bank transfer payments</h3>
          <p>
            The option to pay by bank transfer can be made available to your
            customers. For this you will need to provide your bank details.
          </p>
          <p>If you do not wish to use this, these can be left blank.</p>
        </div>
        <div className="form-group mt-3">
          <label className="">Bank Iban - no spaces</label>
          <input
            className="form-control"
            type="text"
            placeholder="IBAN"
            name="bankiban"
            value={orgData.bankiban}
            onChange={handler}
            pattern="[A-Z]{2}\d{13,32}|(?=.{18,42}$)[A-Z]{2}\d{2}( )(\d{4}\1){2,7}\d{1,4}"
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Bank BIC (swift code)</label>
          <input
            className="form-control"
            type="text"
            placeholder="BIC"
            name="bankbic"
            value={orgData.bankbic}
            onChange={handler}
            pattern="[A-Z0-9]{7,9}"
          />
        </div>
        <div className="form-group mt-3">
          <h3>For online payments</h3>
          <p>
            Ubicopia uses the Woldline e-commerce solution. This only uses
            transaction costs and can be used with most online payment forms.
            Usually your account is activated within the hour.
          </p>
          <a
            className="link-primary"
            href="https://mypaymentsolution.be.worldline.com/nl/home/our-offer/E-commerce-solution/starter-pack.html"
            target="_blank"
            rel="noreferrer"
          >
            More information on Worldline e-commerce.
          </a>
          <p>
            In order to work with Worldline you will need to become a customer
            and signup, this can be done by the link below.
          </p>
          <p>
            <a
              className="link-primary"
              href="https://onboarding.six-payment-services.com/onboarding/register?productCategory=web_based&country=BE&language=nl&applicationContext=SALES_REVOLUTION&product=web_based_be_a&finishPage=https:%2F%2Fmypaymentsolution.be.worldline.com%2Fnl%2Fhome%2Four-offer%2FE-commerce-solution%2Fstarter-pack%2Fthanks-starter.html%3Futm_source%3Dwebsite"
              target="_blank"
              rel="noreferrer"
            >
              Signup with Worldline e-commerce.
            </a>
          </p>
          <p>
            Once signed up you will receive a customer ID, terminal ID and
            Header, please register these in the fields below.
          </p>
        </div>

        <div className="form-group mt-3">
          <label className="">
            WorldLine - e-commerce - Customer ID (for online payments)
          </label>

          <input
            className="form-control"
            type="text"
            placeholder="123456"
            name="saferpaycustid"
            value={orgData.saferpaycustid}
            onChange={handler}
            pattern="[0-9]{1,8}"
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            WorldLine - e-commerce - Terminal ID (for online payments)
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="12345678"
            name="saferpayterminalid"
            value={orgData.saferpayterminalid}
            onChange={handler}
            pattern="[0-9]{7,8}"
          />
        </div>
        <div className="form-group mt-3">
          <label className="">
            WorldLine - e-commerce - BASE64 header (starts with Basic xxxxxxxx)
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Basic xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            name="saferpayheader"
            value={orgData.saferpayheader}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà()\s\-^<>]{20,100}"
          />
        </div>
        <button className="btn btn-primary mt-3" type="submit">
          Bewaar
        </button>
      </form>
    </div>
  );
};

export default CreateOrg;
