import React, { useEffect, useState } from "react";
import Select from "react-select";
import readTime from "../Functions/readTime";
import readDay from "../Functions/readDay";
import { selectdays } from "../Data/data";

const DayTimeEdit = ({ data, name, prodSet, setProdSet }) => {
  const days = selectdays;
  var [day, setDay] = useState(readDay(data));
  var [time, setTime] = useState(readTime(data));

  useEffect(() => {
    var calcDate = new Date("2023-04-30");
    var dayvalue = parseInt(day.value);
    var returnDate = new Date(calcDate.setDate(calcDate.getDate() + dayvalue));
    var hours = time.slice(0, 2);
    var minutes = time.slice(-2);
    returnDate =
      returnDate.getFullYear() +
      "-" +
      ("0" + String(returnDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + String(returnDate.getDate())).slice(-2) +
      "T" +
      hours +
      ":" +
      minutes;
    if (returnDate !== "Invalid Date") {
      setProdSet({ ...prodSet, [name]: returnDate });
    } else {
      setProdSet({ ...prodSet, [name]: null });
    }
  }, [day, time]);

  function handleOrderDay(data) {
    day = data;
    setDay(data);
  }

  function handleOrderTime(e) {
    time = e.target.value;
    setTime(e.target.value);
  }

  return (
    <div>
      <div className="d-flex flex-row align-items-center justify-content-evenly">
        <Select
          options={days}
          placeholder="Select day"
          value={day}
          onChange={handleOrderDay}
        />
        <div>before</div>
        <input
          className="form-control w-50"
          placeholder="Deadline to order"
          type="time"
          id="orderdeadline"
          name={name}
          value={time}
          onChange={handleOrderTime}
        />
      </div>
    </div>
  );
};

export default DayTimeEdit;
