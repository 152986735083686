import React from "react";
import CLine from "./CLine";
import getTitle from "../Functions/getTitle";

const ShowUser = ({ user, settoggler }) => {
  return (
    <div>
      <div className="userblock bg-light p-3 rounded-2">
        <div className="row">
          <div className="usersubtitle col-10">Your user details</div>
        </div>
        <CLine t="title" v={getTitle(user.title)} />
        <CLine t="First Name" v={user.firstname} />
        <CLine t="Name" v={user.name} />
        <CLine t="Phone" v={user.phone} />
        <CLine t="Email" v={user.email} />
        <div className="row">
          <div className="col-10"></div>
          <button
            className="btn btn-primary btn-sm col-2"
            onClick={(e) => settoggler(true)}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowUser;
