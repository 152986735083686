import React, { useState } from "react";
import { isocountries } from "../Data/data";

const EditAddress = ({ address, user, getaddresslist, settoggler }) => {
  const [addData, setAddData] = useState(address);

  let updateAddress = async (add) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/address/update/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: JSON.stringify(add),
      }
    );
    if (resp.status === 200) {
      console.log("Address has been updated");
      getaddresslist(user.id);
      settoggler(null);
    } else {
      alert("something went wrong checking your details");
    }
  };

  const addChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddData({ ...addData, [name]: value });
  };

  const addSubmit = (e) => {
    e.preventDefault();
    updateAddress(addData);
  };

  return (
    <div>
      <form onSubmit={(e) => addSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">Street</label>
          <input
            className="form-control"
            placeholder="street"
            type="text"
            required
            id="street"
            name="street"
            value={addData.street}
            onInput={(e) => addChange(e)}
            pattern="[a-zA-Zéèçà()\s\-^<>]{1,200}$"
          ></input>
          <div className="form-group mt-3">
            <label className="">hnr</label>
            <input
              className="form-control"
              type="text"
              placeholder="hnr*"
              name="hnr"
              size="3"
              value={addData.hnr}
              onChange={(e) => addChange(e)}
              pattern="[0-9a-zA-Z]{1,10}$"
              required
            />
          </div>
          <div className="form-group mt-3">
            <label className="">Hnr ext</label>
            <input
              className="form-control"
              type="text"
              placeholder="hnrext"
              name="hnrext"
              value={addData.hnrext}
              onChange={(e) => addChange(e)}
              pattern="[0-9a-zA-Z]{1,10}$"
            />
          </div>
          <div className="form-group mt-3">
            <label className="">Zip</label>
            <input
              className="form-control"
              type="text"
              placeholder="zip"
              name="zip"
              value={addData.zip}
              onChange={(e) => addChange(e)}
              pattern="[0-9]{4,20}$"
              required
            />
            <label className="">City</label>
            <input
              className="form-control"
              placeholder="city"
              type="text"
              required
              id="city"
              name="city"
              value={addData.city}
              onInput={(e) => addChange(e)}
              pattern="[a-zA-Zéèçà()\s\-^<>]{1,100}$"
            ></input>
          </div>
          <div className="form-group mt-3">
            <div className="addressbox_line_left">Country</div>
            <select
              className="form-control"
              placeholder="country"
              type="text"
              required
              id="country"
              name="country"
              value={addData.country}
              onInput={(e) => addChange(e)}
            >
              <option name="country" value="">
                -- select a country --
              </option>
              {isocountries &&
                isocountries.map((ic, index) => {
                  return (
                    <option name="country" value={ic.code} key={index}>
                      {ic.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <button className="btn btn-primary mt-3" type="submit">
            save
          </button>
          <button
            className="btn btn-secondary mt-3 ms-3"
            onClick={(e) => settoggler(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAddress;
