import React, { useState, useEffect } from "react";
import Select from "react-select";

const EditPromoLetter = ({ setToggler, promoLetter, productList }) => {
  const [products, setProducts] = useState(productList);
  const [options, setOptions] = useState([]);
  const [promoData, setPromoData] = useState(promoLetter);
  const [selectedOptions, setSelectedOptions] = useState();

  useEffect(() => {
    if (products && products.length > 0 && options.length === 0) {
      products.map((pr, index) => {
        let val = pr.id.toString();
        let label = pr.name + pr.description;
        options.push({ ["value"]: pr.id.toString(), ["label"]: label });
      });
    }
    setOptions(options);
  }, [products]);

  useEffect(() => {
    let list = [];
    promoData.products.map((pd, index) => {
      let selectedoption = options.filter(
        (option) => option.value === pd.toString()
      )[0];
      list.push(selectedoption);
    });
    setSelectedOptions(list);
  }, [promoData && options]);

  let editPromo = async (promo) => {
    let resp = await fetch(
      process.env.REACT_APP_API_URL + "capi/marketing/promoletter/",
      {
        method: "PATCH",
        headers: {
          Authorization: "JWT " + String(localStorage.getItem("access")),
        },
        body: promo,
      }
    );
    if (resp.status === 200) {
      console.log("edit succeeded");
      setToggler(null);
    } else {
      alert("something went wrong editing your promoletter");
    }
  };

  const handler = (e) => {
    const fieldname = e.target.name;
    const value = e.target.value;
    setPromoData({ ...promoData, [fieldname]: value });
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const promoSubmit = (e) => {
    e.preventDefault();
    const promoLetter = new FormData();
    promoLetter.append("id", promoData.id);
    promoLetter.append("name", promoData.name);
    promoLetter.append("organisation", promoData.organisation);
    promoLetter.append("header", promoData.header);
    promoLetter.append("body", promoData.body);
    promoLetter.append("url", promoData.url);
    if (selectedOptions.length > 0) {
      selectedOptions.map((so, index) => {
        promoLetter.append("products", so.value);
      });
    }
    promoLetter.append("webshop", promoData.webshop);
    editPromo(promoLetter);
  };

  return (
    <div className="w-50">
      <form onSubmit={(e) => promoSubmit(e)}>
        <div className="form-group mt-3">
          <label className="">Name of Promoletter (for filing only)</label>
          <input
            className="form-control"
            type="text"
            name="name"
            value={promoData.name}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<div>]{1,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Title</label>
          <input
            className="form-control"
            type="text"
            name="header"
            value={promoData.header}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<div>]{1,200}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Body/Message</label>
          <textarea
            className="form-control"
            type="text"
            name="body"
            value={promoData.body}
            onChange={handler}
            pattern="[a-zA-Z0-9éèçà&\-\s^<div>]{1,1000}$"
            required
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Link</label>
          <input
            className="form-control"
            type="url"
            placeholder="Link"
            name="url"
            value={promoData.url}
            onChange={handler}
          />
        </div>
        <div className="form-group mt-3">
          <label className="">Choose the products you wish to promote</label>
          {options.length > 0 && (
            <>
              <Select
                options={options}
                placeholder="Select products you wish to promote"
                value={selectedOptions}
                onChange={handleSelect}
                isMulti
              />
            </>
          )}
        </div>
        <button
          className="btn btn-secondary mt-3"
          onClick={() => setToggler(null)}
          type="button"
        >
          Cancel
        </button>
        <button className="btn btn-success mt-3 ms-5" type="submit">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditPromoLetter;
